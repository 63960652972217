import firebase from "firebase";

import { PlRequestObj } from "../Components/Interface";

export default async function getToolsList(db: firebase.firestore.Firestore) {
	try {
		const docs = await db.collection("requestTypes").doc("public").get();
		const data = docs.data();
		if (data) {
			return data["requestTypes"] as PlRequestObj[];
		}

		return;
	} catch (error) {
		console.error("getToolsList: Error getting tools", error);
		return;
	}
}
