import React, {useEffect}  from 'react';
import {useHistory, RouteComponentProps} from "react-router-dom";
import {  makeStyles, Grid, 
	Container,} from '@material-ui/core';




import ButtonAppBar from "../Components/Menubar";


import * as firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import firebaseui from 'firebaseui';
// import automate from "./assets/automate.png"

// import airtable from "./assets/airtable-firebase.png"
// import shopify from "./assets/shopify-airtable.png"
// import zoom from "./assets/zoom-firebase.png"


const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        overflow: "scroll",
        fullWidth: "true",
        maxWidth: "sm",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        // fullWidth: "true",
        // maxWidth :'lg',
    },
    heroTitle: {
        color : "#3E4348",
        fontFamily: "Roboto, sans-serif",
        fontStyle : "normal",
        fontWeight: 500,
        fontSize: "60px"
    },

    heroSubtitle: {
        fontFamily: "Roboto, sans-serif",
        // font-style: normal;
        fontWeight: 500,
        fontSize: "24px",
        // line-height: 28px;
        // text-align: center;

        color: "#7F8893"
    },
    benefitsTitle: {
        color : "#3E4348",
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        fontSize: "40px"
    },

    benefitsSubtitle: {
        fontFamily: "Roboto, sans-serif",        
        fontWeight: 500,
        fontSize: "30px",
        color : "#3E4348",
    },
    benefitsText: {
        fontFamily: "Roboto, sans-serif",
        // font-style: normal;
        fontWeight: 500,
        fontSize: "20px",
        // line-height: 28px;
        // text-align: center;

        color: "#7F8893"
    },
    faqTitle: {
        color : "#3E4348",
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        fontSize: "24px"
    },
    faqText: {
        fontFamily: "Roboto, sans-serif",
        // font-style: normal;
        fontWeight: 500,
        fontSize: "24px",
        // line-height: 28px;
        // text-align: center;

        color: "#7F8893"
    },
    priceAmount : {
        color : "#3E4348",
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        fontSize: "30px"
    },
    priceText: {
        fontFamily: "Roboto, sans-serif",
        // font-style: normal;
        fontWeight: 500,
        fontSize: "18px",
        // line-height: 28px;
        // text-align: center;

        color: "#7F8893"
    },
}));



export default function Signin ( { match }: RouteComponentProps) {

    const auth = firebase.default.auth()
    const history = useHistory()

    // Initialize the FirebaseUI Widget using Firebase.
    // var ui = new firebaseui.auth.AuthUI(auth);

    useEffect(() => {
        // addCrispChat()
        // SignupForm()
        // console.log(`New landing page!!!`)
        // alert(`New landing page`)


		//TODO: Test for public data query
		// getUserDataTest()

        // handleSignInLink()

        console.log("Signin match", match)

        const r = firebase.default.auth().onAuthStateChanged(currentUser => {
            if (currentUser){
                console.log("Got user!!!")
                history.push("/home")
            }else{
                console.log("No user!!!")
            }
        });

        return () => {
            // Do unmounting stuff here
            r()

        };

	}, []);

    

    function FirebaseUISignin(){

        

        const uiConfig: firebaseui.auth.Config = {
          // Popup signin flow rather than redirect flow.
          signInFlow:  auth.isSignInWithEmailLink(window.location.href) ? 'redirect' : 'popup',
          
        //   'popup', //"redirect", //'popup',
          // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
          signInSuccessUrl: '/home',
          // signInSuccessUrl: successUrl ?? '/home',
          // We will display Google and Facebook as auth providers.
          signInOptions: [
            // {
            //   provider: fb.default.auth.EmailAuthProvider.PROVIDER_ID,
            //   requireDisplayName: true
            // },
            {
              provider: firebase.default.auth.EmailAuthProvider.PROVIDER_ID,
              signInMethod: firebase.default.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
            },
            firebase.default.auth.GoogleAuthProvider.PROVIDER_ID,
            
            
          ],
          callbacks: {
            signInSuccessWithAuthResult(result,url){
                console.log("Signin success!!")
            //   console.log(`Auth result: ${JSON.stringify(result)}`)
            //   alert(`Auth result: ${JSON.stringify(result)}`)
            //   history.push("/home")
              return true
            },
            signInFailure(error){
              console.log(`Firebase ui error: ${error.message}`)
              return Promise.resolve();
            }

            
          },
          
            // Terms of service url.
          tosUrl: 'https://www.notion.so/azizakgul/Terms-and-Conditions-of-use-f00f2fa5255e4450b08f120600bb5afc',
          // Privacy policy url.
          privacyPolicyUrl: 'https://www.notion.so/azizakgul/Privacy-ed28153658c1456f9698077a2e278a55'
          
        };
       
        return (
    
       
    
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
            
         
         
        )
    }

    return(
        <Container>
            <ButtonAppBar/>
            <Grid container spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                style={{ minHeight: '70vh' }}>
                <Grid item xs={12}>
                
                <FirebaseUISignin/>
                </Grid>
            </Grid>
        </Container>
    
    )




}