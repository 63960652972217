import React, { useState}  from 'react';

import { Button, Grid,  TextField
} from '@material-ui/core';

import { useMediaQuery } from '@mui/material';

import {useStyles} from "../../Components/useStyles"
import { Persona } from '../../Components/Interface';

export default function ComposeView({sendChat, persona, isTyping}:{sendChat:Function, persona? : Persona, isTyping:boolean}){

    const [composeText, setComposeText] = useState<string>("")

    const classes = useStyles();

    const largeScreen = useMediaQuery(`(min-width:600px)`)


    function handleButton(event: React.MouseEvent<HTMLElement>){
        console.log("send!")
        sendChat(composeText, persona)
        setComposeText("")

    }

    function handleTextfield(event : React.ChangeEvent<HTMLInputElement>){
        const value = event.currentTarget.value
        setComposeText(value)
    }

    return(
        
            <>
           

            <Grid id="composeView" container item xs={12}  spacing={1} alignItems="center" justifyContent="center" style={largeScreen ? {marginRight:"-95px"} : {marginRight:"0"}}>
                {/* <form  noValidate autoComplete="off" key={"compose"}>
                    <div> */}
                    <Grid item xs={11} sm={7}>

                        <TextField
                            id={"compose"}
                            key={"compose"}
                            // error={fieldErrorId === field.id}
                            // label="Title"
                            // defaultValue="Default Value"
                            // helperText={field.maxChar && `${field.maxChar - (plNewRequest.fields?.filter(f2=>{return (f2.id === field.id)})[0]?.value.length ?? 0)} Characters`}
                            variant="outlined"
                            onChange={handleTextfield}
                            placeholder={"Start typing..."}
                            fullWidth
                            maxRows={10}
                            multiline
                            FormHelperTextProps={{
                                className: classes.reqViewHelperText
                            }}

                            value={composeText}
                        />

                    </Grid>
                    <Grid item xs={1}>
                    <Button  className={classes.resultCopyButton} disabled={isTyping} onClick={handleButton} >Send</Button>
                    </Grid>
                    

            </Grid>

        </>
    )
}