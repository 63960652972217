import firebase from "firebase";
import { useEffect, useRef, useState } from "react";
import { useHistory, RouteComponentProps } from "react-router-dom";

import { Chat, Persona , ChatMessage} from "../../Components/Interface";
import ChatCell from "./ChatCell";
import { Grid, Container } from "@material-ui/core";

import getChats from "./getChats";
import getPersonas from "./getPersonas";

import Menubar from "../../Components/Menubar";
import SimpleBackdrop from "../../Components/BackDrop";
import EditTitleModal from "./EditTitleModal";
import DeleteChatModal from "./DeleteChatModal";


export default function Chats({ match }: RouteComponentProps) {


    const [user, setUser] = useState<firebase.User>();
	const [openBackdrop, setOpenBackdrop] = useState(false);
    const [chats, setChats] = useState<Chat[]>()
	const [selectedChat, setSelectedChat] = useState<Chat>()
	const [openEditTitleModal, setOpenEditTitleModal] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
    // const [personas, setPersonas] = useState<Persona[]>()
	const personas = useRef<Persona[]>()
	
	const history = useHistory();
	
    const auth = firebase.auth();
    const db = firebase.firestore();

    useEffect(() => {
		console.log("got chats")	
		firebase.auth().onAuthStateChanged((currentUser) => {
			if (!currentUser) {
				console.log("No user!!!");
				history.push("/");
                return
			}
			console.log("got user")	
            setUser(currentUser);

			Promise.all([getPersonas(currentUser.uid), getChats(currentUser.uid)]).then(([p,c])=>{	
						
				personas.current = p
				setChats(c)
				
			})	

		});	
	}, []);

    function buttonHandler(props){
		console.log("button handler", props["action"])
		const action = props["action"]
		const chat = props["chat"] as Chat
		if(action === "go"){
			console.log("going home!!")
			history.push({pathname : "/home", state : props["chat"]})
			return
		}

		if(action === "edit"){
			setSelectedChat(chat)
			setOpenEditTitleModal(true)
		}

		if(action === "delete"){
			setSelectedChat(chat)
			setOpenDeleteModal(true)
		}

    }

	function goTo(location: string) {
		if (location === "upgrade" && user) {
			// launchStripe()
			// setOpenUpgradeModal(true);
			return;
		}

		if (location === "editLanguage" && user) {
			// setOpenLanguageModal(true);
			console.log("Edit language");
		}
	}

	function setNewTitle(chat:Chat, title: string){

		const updatedChats = chats?.map(c=>{
			if(c.id !== chat.id) return c

			return {...c, title : title}
		})

		setChats(updatedChats)
	}

	function chatDeleted(chat:Chat){

		const filteredChats = chats?.filter(c=> c.id !== chat.id)
		setChats(filteredChats)

	}


	if(!chats) return null


	return (
<Container>
	<SimpleBackdrop openBackDrop={openBackdrop} />
	
	<EditTitleModal chat={selectedChat} setOpenBackdrop={setOpenBackdrop} openEditTitleModal={openEditTitleModal} setOpenEditTitleModal={setOpenEditTitleModal} setNewTitle={setNewTitle} />
	<DeleteChatModal chat={selectedChat} openDeleteModal={openDeleteModal} setOpenBackdrop={setOpenBackdrop} setOpenDeleteChatModal={setOpenDeleteModal}
	 chatDeleted={chatDeleted}/>
		<Menubar
				currentUser={user}
				// trialDays={trialDays}
				// activeAccount={activeAccount}
				goTo={goTo}
				location="chats"
				// language={user?.language?.from ?? "en"}
				// setChatObject={setChatObject}
				// chatObject={chatObject}
			/>

		<Grid container item  alignItems="flex-end" justifyContent="center" xs={12} spacing={1} style={{overflow:"auto"}}>
			
			<Grid item xs={12} sm={7}>
				{chats.map((c, i) => {

                    // if(personas && personas.current && c.personaId){
                    //     const persona = personas.current.filter(p => c.personaId === p.id)[0]

                        // return <ChatCell chat={c} index={i} buttonHandler={buttonHandler} persona={personas.current?.filter(p => c.personaId === p.id)[0]} />
                    // }
                    
					return <ChatCell chat={c} index={i} buttonHandler={buttonHandler} personas={personas.current} />
				})}
				
			  </Grid>
		</Grid>
		</Container>
	);
}
