import { useState, useRef } from "react";
import firebase from "firebase"
import { Typography, Button, Grid, 
	Container,
	Modal,
    Box,
    useMediaQuery ,
    FormControl, InputLabel, Select, MenuItem, TextField, ListSubheader

} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import isLargeScreen from '../../Helpers/isLargeScreen';

import { User } from "../../Components/Interface";
import {useStyles} from "../../Components/useStyles"



export default function UserNameModal({db, user, openUserNameModal, setOpenUserNameModal}:{db: firebase.firestore.Firestore, user?:User, openUserNameModal : boolean, setOpenUserNameModal : Function}){

    const [firstName, setFirstName] = useState(user?.firstName ?? "")
    const [lastName, setLastName] = useState(user?.lastName ?? "")
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)

    const largeScreen = isLargeScreen();
    const classes = useStyles();


    const handleClose = () => setOpenUserNameModal(false);

    const product = useRef("proMo")

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "700px",
        // width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function capitalizeFirstLetter(str) {
        return str.replace(/^\p{CWU}/u, char => char.toLocaleUpperCase(navigator.language));
    }

    async function handleSaveButton(event: React.MouseEvent<HTMLElement>){

        /**
         * - Check if names are filled and valid
         */

        if(firstName.trim() === ""){

            setFirstNameError(true)

            return
        }

        if(lastName.trim() === ""){

            setLastNameError(true)

            return
        }

        if(!user) return

        const firstNameEdited = capitalizeFirstLetter(firstName.trim().toLowerCase())
        const lastNameEdited = capitalizeFirstLetter(lastName.trim().toLowerCase())

        const d = {
            firstName: firstNameEdited,
            lastName : lastNameEdited,
            fullName : firstNameEdited + " " + lastNameEdited,
            intro : true
        }

        

        try {
            // setOpenBackdrop(true)
            await db.collection("users").doc(user.userId).update(d)
            setOpenUserNameModal(false)

        } catch (error) {
            console.error(`Error saving user name: ${error}`)
        }

        // setOpenBackdrop(false)



    }

    function handleNameTextField(event : React.ChangeEvent<HTMLInputElement>){

        const id    = event.currentTarget.id
        const value = event.currentTarget.value

        if(firstNameError === true || lastNameError === true){
            setFirstNameError(false)
            setLastNameError(false)
        }

        if(id === "firstName") setFirstName(value)
        if(id === "lastName") setLastName(value)
    }

    if(!user) return null
    

    return(

        <Modal
            open={openUserNameModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            
            
            <Container style={{textAlign:"center"}}>
            <Box sx={style}>

            <Grid container item style={{textAlign:"left"}}>
                            <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClose}>
                    {/* Open Menu */}
                    <CloseIcon style={{color:"#555A64"}} />
                </Button>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        
                        {
                                largeScreen ? 

                                <Typography className={classes.reqViewTitle} >
                                {
                                     `Welcome to Punchline 😃  \u00A0  Please add your name` 
                                }
                                
                            </Typography>

                            : 
                            <>
                            <Typography className={classes.reqViewTitle} >
                            
                                 Welcome to Punchline 😃 
                            
                            
                        </Typography>

                        <Typography className={classes.reqViewTitle} >
                            
                               Please add your name
                            
                            
                        </Typography>
                        </>
                                
                                
                            }

                    </Grid>

                    <Grid item xs={12} sm={6}>
                    <TextField
                                id="firstName"
                                key="firstName"
                                // label="Title"
                                // defaultValue="Default Value"
                               
                                variant="outlined"
                                onChange={handleNameTextField}
                                placeholder="First Name"
                                fullWidth
                        
                                value={firstName}
                                error={firstNameError}
                                helperText= {firstNameError && "First name can't be empty"}
                            />

                    </Grid>

                    <Grid item xs={12} sm={6}>
                    <TextField
                                id="lastName"
                                key="lastName"
                                // label="Title"
                                // defaultValue="Default Value"
                               
                                variant="outlined"
                                onChange={handleNameTextField}
                                placeholder="Last Name"
                                fullWidth
                        
                                value={lastName}
                                error={lastNameError}
                                helperText= {lastNameError && "Last name can't be empty"}
                            />

                    </Grid>





                <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                id="upgradePro"
                                // onClick={startButtonClicked}
                                style={{
                                    margin: "1.5rem",
                                    width: "17.5rem",
                                    height: "3rem",
                                    fontFamily: "Roboto, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "1.25rem",
                                    lineHeight: "1.25rem",
                                    backgroundColor: "#0062FF"
                                }}
                            
                                onClick={handleSaveButton}
                            
                            >
                            Save
                            </Button>
                </Grid>    




                </Grid>



                </Box>
            </Container>
           
            
            
        </Modal>
    )
}