// import React, { createContext } from 'react'
// import app from 'firebase/app'
import * as firebase from 'firebase';

// const FirebaseContext = createContext(null)
// export { FirebaseContext }

const config = {
    apiKey: "AIzaSyDZ8Skm3IAnePGEAo28hSyBbKIlyR6aZZk",
    authDomain: "auth.punchline.ai",
    // authDomain: "punchline-d7229.firebaseapp.com",
    projectId: "punchline-d7229",
    storageBucket: "punchline-d7229.appspot.com",
    messagingSenderId: "896856655503",
    appId: "1:896856655503:web:521199d5dc9abb1282d2fa",
    measurementId: "G-00D064DTCK"
}

// export default ({ children }) => {
//     if (!app.apps.length) {
//       app.initializeApp(config)
//     }
//     return (
//       <FirebaseContext.Provider value={ app }>
//         { children }
//       </FirebaseContext.Provider>
//     )
// }



export default !firebase.default.apps.length ? firebase.default.initializeApp(config) : firebase.default.app();