
import "./style.css"
import { Grid } from "@material-ui/core"


export default function TypingIndicator({isTyping}:{isTyping:boolean}){

    if(isTyping === false) return null

    return(
        <Grid container item alignItems="center" justifyContent="flex-end">
            <Grid item>
        <div className="typing">
            <div className="typing__dot"></div>
            <div className="typing__dot"></div>
            <div className="typing__dot"></div>
        </div>
        </Grid>
        </Grid>
    )

}