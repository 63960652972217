import { useState, useEffect, useRef } from "react"
import firebase from "firebase"
import { useHistory, RouteComponentProps, useParams } from "react-router-dom";

import { Card, CardContent, Typography, CardActions, Button, Grid, Container, Modal, Box } from "@material-ui/core"

import { useStyles } from "../Components/useStyles"

import {Persona, ChatMessage } from "../Components/Interface"
import SimpleBackdrop from "../Components/BackDrop"
import Menubar from "../Components/Menubar";
import NewPersona from "./Home/NewPersona";
import EditPersona from "./Home/EditPersona";
import { isReturn } from "lexical/LexicalUtils";
import CloseIcon from '@material-ui/icons/Close';




export default function PersonaView({ match }: RouteComponentProps){

    const [personas, setPersonas] = useState<Persona[]>()
    const [user, setUser] = useState<firebase.User>();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openPersonaModal, setOpenPersonaModal] = useState(false)
    const [openEditPersonaModal, setOpenEditPersonaModal] = useState(false)
    const [openDeletePersonaModal, setOpenDeletePersonaModal] = useState(false)

    const [editPersona, setEditPersona] = useState<Persona>()
    const [deletePersona, setDeletePersona] = useState<Persona>()

    const db = firebase.firestore();

    const classes = useStyles();

    useEffect(()=>{

        firebase.auth().onAuthStateChanged((currentUser) => {

			if (currentUser) {

                setUser(currentUser)

                getPersonas(currentUser.uid).then((p)=>{
                    if(p){
                        console.log("got personas")		
                        setPersonas(p)
                    } 
                })
				
			} 
        })

    },[])

    async function getPersonas(userId:string): Promise<Persona[] | undefined>{

        try {
            const r = await db.collection("persona").where("userId", "==", userId).get()
            if(r.empty){
                console.log("no personas!!")
                return
            } 
    
            return r.docs.map((d) =>{		
                
                return d.data() as Persona
            })
    
        } catch (error) {
            console.error("Error getting personas", error)
            return
        }		
    }

    function PersonaCell({persona, index, personaAction}:{persona: Persona, index:number, personaAction:Function}){

        const classes = useStyles()
            
        // let text = chatMessage.content.replace(/^_+|_+$/g, '').replace(/(\r\n|\r|\n){2}/g, '$1').replace(/(\r\n|\r|\n){3,}/g, '$1\n').replace(/\\\\/g, '\\').trim()
    
        function handleEdit(){
            console.log("Edit persona no", persona.id)
            personaAction({action:"edit", persona: persona})
        }
    
        function handleDelete(){
    
            console.log("Delete persona no", persona.id)
            personaAction({action:"delete", persona: persona})
    
        }
        
    
        return(
            <Grid item  xs={12}>
                <Card  variant="outlined" className={classes.resultCard}>
                    <CardContent>
                        <Typography variant="h5" className={classes.cardTitleText}>{(persona.name && persona.name.trim() !== "") && persona.name + " - "}  {persona.title}</Typography>
                        <Typography className={classes.cardContent}>{persona.content}</Typography>
        
                    </CardContent>
        
                    {/* Button */}
                    <Grid id={`${index}`} item xs={12} style={{width:""}}>
                        <CardActions>
                            <Button className={classes.resultCopyButton} onClick={handleEdit} >Edit</Button>
                            <Button className={classes.resultCopyButton} onClick={handleDelete} >Delete</Button>
                        </CardActions>
                    </Grid>
                
                </Card>
            </Grid>
    
        )
    }

    function personaAction(props){
        console.log("action props", props.persona)

        if(props.action === "edit"){
            setEditPersona(props.persona)
            // editPersona.current = props.persona
            setOpenEditPersonaModal(true)
            return
        }

        if(props.action === "delete"){
            setDeletePersona(props.persona)
            setOpenDeletePersonaModal(true)

        }

    }

    function NewPersonaButton({setOpenPersonaModal}:{setOpenPersonaModal: React.Dispatch<React.SetStateAction<boolean>>}){
		
		function handleButton(){
			setOpenPersonaModal(true)
		}

		return(
			<Button
				onClick={handleButton}
				color="primary"
				className={classes.newPersonaButton}
			>
				New Persona
			</Button>
		)
	}

    function newPersona(persona : Persona){
        const newPersonas = personas ? [persona, ...personas] : [persona]
        console.log("new personas", newPersonas)
        setPersonas(newPersonas)
    }

    function editedPersona(persona : Persona){
        if(!personas) return

        const newPersonas = personas.map(p =>{
            if(p.id !== persona.id) return p
            return persona
        }) 

        setPersonas(newPersonas)

    }

    function DeletePersonaModal({deletePersona}:{deletePersona?:Persona}){

        // const [delPersona, setDelPersona] = useState(persona)


       

        const style = {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: "700px",
            // width: 700,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };

        // useEffect(()=>{
        //     console.log("persona is", persona)
        //     if(persona){
        //         setTitle(persona.title)
        //         setContent(persona.content)
        //     }
        // },[persona])

    async function handleDeleteButton(event: React.MouseEvent<HTMLElement>){

        
        if(!deletePersona) return

        if(!user){
            setOpenDeletePersonaModal(false)
            return
        } 
        
        // setOpenBackdrop(true)
    
        const ref = db.collection("persona").doc(deletePersona.id)

        try {
            await ref.delete()
            
            const updatedPersonas = personas?.filter(p =>{
                return p.id !== deletePersona.id
            })

            if(updatedPersonas) setPersonas(updatedPersonas)
            console.log("persona deleted")
        } catch (error) {
            console.log("persona delete error", error)
        }

        // setOpenBackdrop(false)
        setOpenDeletePersonaModal(false)
    }

    function handleDeleteClose(){
        setOpenDeletePersonaModal(false)
    }

    return(

        <Modal
            open={openDeletePersonaModal}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            
            <Container style={{textAlign:"center"}}>
            <Box sx={style}>

                <Grid container item style={{textAlign:"left"}}>
                    <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleDeleteClose}>
                    {/* Open Menu */}
                    <CloseIcon style={{color:"#555A64"}} />
                </Button>
                </Grid>

                <Grid container justify="center" spacing={3}>

            <Grid item xs={12} justifyContent="center" >
                <Typography className={classes.reqViewTitle} >
                            
                           Delete this persona?
                       
                   </Typography>
            </Grid>

            <Grid item xs={12} justifyContent="center" >
                <Typography className={classes.resultText} >
                            
                           {deletePersona?.title}
                       
                   </Typography>
            </Grid>

            <Grid container item xs={12} spacing={4} justifyContent="center" alignItems="center">

               

               

                <Grid item>

                <Button onClick={handleDeleteButton}  className={classes.deleteButton}>
                        Delete
                </Button>

                </Grid>


               

            </Grid>

            </Grid>
            </Box>
            </Container>
           
            
            
        </Modal>
    )
    }


    return(
        <Container>
			<SimpleBackdrop openBackDrop={openBackdrop} />
            <NewPersona userId={user?.uid} db={db} openPersonaModal={openPersonaModal} setOpenPersonaModal={setOpenPersonaModal} newPersona={newPersona} />
            <DeletePersonaModal deletePersona={deletePersona} />
			<Menubar
				currentUser={user}
				// trialDays={trialDays}
				// activeAccount={activeAccount}
				// goTo={goTo}
				// location="chat"
				// language={user?.language?.from ?? "en"}
				// setChatObject={setChatObject}
			/>


            <EditPersona persona={editPersona} userId={user?.uid} db={db} openEditPersonaModal={openEditPersonaModal} setOpenEditPersonaModal={setOpenEditPersonaModal} editedPersona={editedPersona}  />

            <Grid container justifyContent="center">
                <Grid container item xs={12} justifyContent="flex-end"><NewPersonaButton setOpenPersonaModal={setOpenPersonaModal}/></Grid>
                {/* <Grid item> {personas && <PersonaCell persona={personas[0]} index={1} /> } </Grid> */}
                {personas && personas.map((p,i) =>{
                    // <p>{p.title}</p>
                    return(
                        <Grid item xs={12} sm={8}>
                        
                        <PersonaCell persona={p} index={i} personaAction={personaAction} />
                    </Grid>
                    )
                   
                })}
            </Grid>
        </Container>
    )
}





