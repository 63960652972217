import React, {useEffect, useState}  from 'react';
import {useHistory, RouteComponentProps} from "react-router-dom";

import {$getRoot, $getSelection} from 'lexical';


import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';

import {AutoFocusPlugin} from "@lexical/react/LexicalAutoFocusPlugin"
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin"
import MentionsPlugin from "./Mention/MentionsPlugin"
import theme from "./themes/theme"
import { MentionNode } from './Mention/MentionNode';

// const theme = {
//   // Theme styling goes here
//   ...
// }

// When the editor changes, you can get notified via the
// LexicalOnChangePlugin!
function onChange(editorState) {
  editorState.read(() => {
    // Read the contents of the EditorState here.
    const root = $getRoot();
    const selection = $getSelection();

    console.log(root, selection);
  });
}

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.

// function MyCustomAutoFocusPlugin() {
//     const [editor] = useLexicalComposerContext();
  
//     useEffect(() => {
//       // Focus the editor when the effect fires!
//       editor.focus();
//     }, [editor]);
  
//     return null;
//   }
  
//   // Catch any errors that occur during Lexical updates and log them
//   // or throw them as needed. If you don't throw them, Lexical will
//   // try to recover gracefully without losing user data.
//   function onError(error) {
//     console.error(error);
//   }

//   export default function Prompt({ match }: RouteComponentProps){
//     const initialConfig = {
//       namespace: 'MyEditor', 
//       theme : theme,
//       onError,
//       nodes: [MentionNode]
//     };
  
//     return (
//       <LexicalComposer initialConfig={initialConfig}>
//         {/* <PlainTextPlugin
//           contentEditable={<ContentEditable />}
//           placeholder={<div>Enter some text...</div>}
//           ErrorBoundary={LexicalErrorBoundary}
//         /> */}

// <RichTextPlugin
//   contentEditable={<ContentEditable />}
//   placeholder={<div>Enter some text...</div>}
//   ErrorBoundary={LexicalErrorBoundary}
// />
// <MentionsPlugin/>
//         <OnChangePlugin onChange={onChange} />
//         <HistoryPlugin />
//         <MyCustomAutoFocusPlugin />
//       </LexicalComposer>
//     );
//   }


  const editorConfig = {
    namespace: 'MyEditor', 
    theme: theme,
    onError(error) {
      throw error;
    },
    nodes: [MentionNode]
  };
  
  export default function Prompt({ match }: RouteComponentProps) {
    return (
      <LexicalComposer initialConfig={editorConfig}>
        <div className="editor-container">
          <PlainTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
  
          <MentionsPlugin />
        </div>
        <OnChangePlugin onChange={onChange} />
        <HistoryPlugin/>
      </LexicalComposer>
    );
  }
  
  function Placeholder() {
    return (
      <div className="editor-placeholder">
        Play around with the mentions plugin...
      </div>
    );
  }