import { Card, CardContent, Typography, CardActions, Button, Grid } from "@material-ui/core"
import { useState, useEffect } from "react"
import { Persona, Chat } from "../../Components/Interface"
import { useStyles } from "../../Components/useStyles"
import personaIcon from "../../assets/persona-icon.png"
import aiIcon from "../../assets/ai-icon.png"


export default function ChatCell({chat, personas, index, buttonHandler}:{chat: Chat, personas?:Persona[], index:number, buttonHandler:Function}){

    const classes = useStyles()

    const [persona, setPersona] = useState<Persona>()

    useEffect(() => {
		if(!personas || !chat.personaId) return
        setPersona(personas.filter(p => chat.personaId === p.id)[0])

	}, []);

    function handleButton(event: React.MouseEvent<HTMLElement>){
        const id = event.currentTarget.id

        buttonHandler({action : id, chat : chat})
    }
        

    function CardButton(){
        
        return(
            <Grid id={`${index}`} item xs={12} style={{width:""}}>
            <CardActions>
                <Button className={classes.resultCopyButton} id="go" onClick={handleButton} >Go to Chat</Button>
                <Button className={classes.resultCopyButton} id="edit" onClick={handleButton} >Edit</Button>
                <Button className={classes.resultCopyButton} id="delete" onClick={handleButton} >Delete</Button>
            </CardActions>
            </Grid>
        )
    }

    

    return(
        <Grid item id={`${index}`} xs={12}>
        <Card  variant="outlined" className={classes.resultCard}>
            <CardContent>
                <Typography className={classes.chatTitleText}>{chat.title}</Typography>

                

                {
                   persona &&
                   <Grid container item>
                   <Grid item xs={1} style={{maxWidth:"28px"}}><img src={personaIcon}/></Grid>
                   <Grid item xs={11}><Typography className={classes.chatPersonaText}>{persona.title}</Typography></Grid>
                   </Grid>
                }

                <Grid container item>
                   <Grid item xs={1} style={{maxWidth:"28px"}}><img src={aiIcon}/></Grid>
                   <Grid item xs={11}><Typography className={classes.resultText}>{chat.model}</Typography></Grid>
                   </Grid>

                

            </CardContent>

            <CardButton/>
          
        </Card>
        </Grid>

    )
}