import React, {useState, useRef, useEffect}  from 'react';
import firebase from "firebase"
import {  Switch, Typography, Button, Grid, 
	Container,
    Paper,
	Dialog, DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { User } from "../../Components/Interface"
import {useStyles} from "../../Components/useStyles"

import { PRICE_INFO } from "../../Helpers/priceInfo"
import launchStripe from './launchStripe';
import isLargeScreen from '../../Helpers/isLargeScreen';

export default  function UpgradeModal({db, user, openUpgradeModal, setOpenUpgradeModal, reason, setOpenBackdrop}:{db: firebase.firestore.Firestore , user? : User, openUpgradeModal : boolean , setOpenUpgradeModal:Function, reason? : string, setOpenBackdrop : Function}){

    const [yearPrice, setYearPrice] = useState(true)

    const proPricePerYear = PRICE_INFO.proYearPrice
    const proPricePerMo   = PRICE_INFO.proMonthPrice
    const basicPricePerYear = PRICE_INFO.basicYearPrice
    const basicPricePerMo   = PRICE_INFO.basicMonthPrice

    const prepaidPrice = PRICE_INFO.prepaidPrice

    const largeScreen = isLargeScreen();
    const classes = useStyles();

    const handleClose = () => setOpenUpgradeModal(false);

    const product = useRef("proMo")


    function handleUpgradeButton(event: React.MouseEvent<HTMLElement>){

        let p = yearPrice ? "proYr" : "proMo"

        if(!user) return

        console.log("upgrade id", event.currentTarget.id)

        if(event.currentTarget.id === "prepaid"){
            
            launchStripe(db, user, "prepaid")
            setOpenBackdrop(true)
            setOpenUpgradeModal(false)
            // setOpenBackdrop(false)
            return
        }

        if(event.currentTarget.id === "upgradePro"){
            
            launchStripe(db, user, p)
            setOpenBackdrop(true)
            setOpenUpgradeModal(false)
            // setOpenBackdrop(false)
            return
        }

        if(event.currentTarget.id === "upgradeBasic"){
            p = yearPrice ? "basicYr" : "basicMo"
            
            launchStripe(db, user, p)
            setOpenBackdrop(true)
            setOpenUpgradeModal(false)
            // setOpenBackdrop(false)
            return

        }

        

        
    }

    useEffect(()=>{
        console.log("upgrade gtp4 reason", reason)
    }, [])

    function PrepaidPackage(){
        return(
            <Grid item xs={12} md={4}>
            <Paper>
                <Grid container direction="column" justify="space-between" alignContent="center" style={{height:"350px"}}>

                    <Grid item>
                        <Typography variant="h2" component="h2" className={classes.benefitsSubtitle}>
                        Pay as you Go					
                        </Typography>

                        <Typography variant="body1"  className={classes.priceAmount}>
                        {`$${prepaidPrice}`}
                        </Typography>

                        <Typography variant="body1" className={classes.priceText}>
								1,000,000 Credits
                        </Typography>

                        <Typography variant="body1" className={classes.priceText}>
                        1,000,000 words GPT-3.5 content generation	
                        </Typography>
                        <Typography variant="body1" className={classes.priceText}>
                        or
                        </Typography>
                        <Typography variant="body1" className={classes.priceText}>
                        50,000 words GPT-4 content generation	
                        </Typography>
                        <Typography variant="body1" className={classes.priceText}>
                            5 Personas
                        </Typography>
                        <Typography variant="body1" className={classes.priceText}>
                            One time payment, no subscription
                        </Typography>

                    

                    </Grid>

                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            id="prepaid"
                            // onClick={startButtonClicked}
                            style={{
                                margin: "1.5rem",
                                // width: "17.5rem",
                                height: "3rem",
                                fontFamily: "Roboto, sans-serif",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "1.25rem",
                                lineHeight: "1.25rem",
                                backgroundColor: "#0062FF"
                            }}
                        
                            onClick={handleUpgradeButton}
                        
                        >
                        Upgrade Now
                        </Button>
                    </Grid>


                </Grid>
            
            
                    
            </Paper>
        </Grid>
        )
    }

    function BasicPackage(){
        return(
            <Grid item xs={12} md={4}>
            <Paper>
                <Grid container direction="column" justify="space-between" alignContent="center" style={{height:"350px"}}>

                    <Grid item>
                        <Typography variant="h2" component="h2" className={classes.benefitsSubtitle}>
                                Basic					
                        </Typography>

                        <Typography variant="body1"  className={classes.priceAmount}>
                            {yearPrice ? `$${basicPricePerYear}/mo` : `$${basicPricePerMo}/mo`}
                        </Typography>

                        <Typography variant="body1" className={classes.priceText}>
								2,000,000 Credits per month
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
								2,000,000 words GPT-3.5 content generation	
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
								or
								</Typography>
								<Typography variant="body1" className={classes.priceText}>
								100,000 words GPT-4 content generation	
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
									10 Personas
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
									Same-day email support
								</Typography>

                    

                    </Grid>

                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            id="upgradeBasic"
                            // onClick={startButtonClicked}
                            style={{
                                margin: "1.5rem",
                                // width: "17.5rem",
                                height: "3rem",
                                fontFamily: "Roboto, sans-serif",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "1.25rem",
                                lineHeight: "1.25rem",
                                backgroundColor: "#0062FF"
                            }}
                        
                            onClick={handleUpgradeButton}
                        
                        >
                        Upgrade Now
                        </Button>
                    </Grid>


                </Grid>
            
            
                    
            </Paper>
        </Grid>
        )
    }

    function ProPackage(){
        return(
            <Grid item xs={12} md={4}>
            <Paper>
                <Grid container direction="column" justify="space-between" alignContent="center" style={{height:"350px"}}>

                    <Grid item>
                        <Typography variant="h2" component="h2" className={classes.benefitsSubtitle}>
                                Pro						
                        </Typography>

                        <Typography variant="body1"  className={classes.priceAmount}>
                            {yearPrice ? `$${proPricePerYear}/mo` : `$${proPricePerMo}/mo`}
                        </Typography>

                        <Typography variant="body1" className={classes.priceText}>
                            4,000,000 Credits per month
                            </Typography>

                            <Typography variant="body1" className={classes.priceText}>
                            4,000,000 words GPT-3.5 content generation	
                            </Typography>
                            <Typography variant="body1" className={classes.priceText}>
                            or
                            </Typography>

                            <Typography variant="body1" className={classes.priceText}>
                            200,000 words GPT-4 content generation	
                            </Typography>

                            <Typography variant="body1" className={classes.priceText}>
                                Unlimited Personas
                            </Typography>

                            <Typography variant="body1" className={classes.priceText}>
                                Same-day email support
                            </Typography>

                    

                    </Grid>

                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            id="upgradePro"
                            // onClick={startButtonClicked}
                            style={{
                                margin: "1.5rem",
                                // width: "17.5rem",
                                height: "3rem",
                                fontFamily: "Roboto, sans-serif",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "1.25rem",
                                lineHeight: "1.25rem",
                                backgroundColor: "#0062FF"
                            }}
                        
                            onClick={handleUpgradeButton}
                        
                        >
                        Upgrade Now
                        </Button>
                    </Grid>


                </Grid>
            
            
                    
            </Paper>
        </Grid>
        )
    }

    return(

        <Dialog
            open={openUpgradeModal}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"lg"}              
        >
            <DialogContent>
            
            
            <Container style={{textAlign:"center"}}>
            {/* <Box sx={style} width={largeScreen ? "1100px" : "auto"}> */}

                <Grid container item style={{textAlign:"left"}}>
                            <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClose}>
                    {/* Open Menu */}
                    <CloseIcon style={{color:"#555A64"}} />
                </Button>
                </Grid>

                <Grid container justify="center" spacing={3}>

            <Grid item xs={12} justifyContent="center" >
                    <Typography id="modal-modal-title" variant="h5" component="h2" style={{color:"#2C3038"}}>
                        Upgrade to continue enjoying Punchline
                    </Typography>
            </Grid>

            {/* {
                
                (reason && reason === "gpt4-upgrade") &&
                <Grid item xs={12} justifyContent="center" >
                <Typography id="modal-modal-title" component="p" style={{color:"#2C3038"}}>
                    GPT-4 can only be used with pro accounts
                </Typography>
        </Grid>
            } */}

            <Grid container item xs={12} justifyContent="center" alignItems="center">

                <Grid item xs={5}  style={largeScreen ? {textAlign:"right"} : {textAlign:"center"}}>
                    <Typography variant="body1"  className={yearPrice ? classes.priceTermText : classes.priceTermTextBlack}>
                    Billed Monthly					
                    </Typography>

                </Grid>

                <Grid item xs={2} md={1} >

                    <Switch
                    className={classes.switch}
                    // style={{color:"#28A745", backgroundColor:"#E9F6EC"}}
                    checked={yearPrice}
                    onChange={()=>{setYearPrice(!yearPrice)}}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />

                </Grid>

                <Grid item xs={5}  style={largeScreen ? {textAlign:"left"} : {textAlign:"center"}}>
                    <Typography variant="body1"  className={yearPrice ? classes.priceTermTextBlack : classes.priceTermText}>
                        Billed Annualy					
                        </Typography>

                </Grid>
                </Grid>


           {/* {
            (!reason) && <BasicPackage/>
           } */}

            <PrepaidPackage/>
            <BasicPackage/>
            <ProPackage/>

           




            </Grid>
            {/* </Box> */}
            </Container>
           
            
            </DialogContent>
        </Dialog>
    )


}