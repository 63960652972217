import { useState, useEffect } from "react"
import firebase from "firebase";
import { Modal, Container, Box, Grid, Button, Typography, TextField, makeStyles } from "@material-ui/core";
import { Chat } from "../../Components/Interface";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },

    accountViewTitle : {
        fontFamily: "SF Pro Display, Roboto, sans-serif",
		color: "#2C3038",
		fontSize: "1.875rem",
		fontWeight: 900,
		fontStyle: "normal",
    },

    accountViewSubTitle : {
        fontFamily: "SF Pro Display, Roboto, sans-serif",
		color: "#2C3038",
		fontSize: "1.25rem",
		fontWeight: 600,
		fontStyle: "normal",
    },

    accountViewText : {
        fontFamily: "SF Pro Display, Roboto, sans-serif",
		color: "#6E6D7A",
		fontSize: "1.25rem",
		fontWeight: 600,
		fontStyle: "normal",
    },

    

    reqViewTitle: {
        fontFamily: "SF Pro Display, Roboto, sans-serif",
		color: "#2C3038",
		fontSize: "1.125rem",
		fontWeight: 600,
		fontStyle: "normal",
    },

    chatTitle: {
        fontFamily: "SF Pro Display, Roboto, sans-serif",
		color: "#2C3038",
		fontSize: "1.5rem",
		fontWeight: 500,
		fontStyle: "normal",
    },

    reqViewButton: {
        width: "220px",
        height: "36px",
        // left: 85.5px;
        // top: 0px;

        /* Blue */

        background: "white",
        color: "#0062FF",
        borderRadius: "5px",
        marginTop:"20px",
        borderColor:"#0062FF",
        '&:hover': {
            // backgroundColor: '#fff',
            color: 'white',
            background: "#0062FF",
        }
    },

    reqViewHelperText: {
        textAlign: "end",
        marginRight: "0px"
    },

    resultCard : {
        marginBottom : "20px"
    },

    resultText : {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        // font-style: normal;
        // font-weight: normal;
        fontSize: "20px",
        // line-height: 23px;
        // display: flex;
        // align-items: center;

        /* Dark Gray */

        color: "#6E6D7A",
        whiteSpace : "pre-line"
    },



    resultCopyButton : {

        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#92929D",
        backgroundColor: "#F3F3F4",
        fontWeight: 500,
        // justifyContent: 'center'
        // marginLeft: 'auto',


    },

    emptyStateText : {
        fontFamily: "Roboto, sans-serif",
        fontSize: "30px",
        color: "#6E6D7A",
        alignItems : "center",
        textAlign : "center",
        fontWeight: 300,
    }





});

export default function DeleteChatModal({chat, setOpenBackdrop, openDeleteModal, setOpenDeleteChatModal, chatDeleted}:{chat?:Chat, setOpenBackdrop: React.Dispatch<React.SetStateAction<boolean>>, openDeleteModal:boolean, setOpenDeleteChatModal:React.Dispatch<React.SetStateAction<boolean>>, chatDeleted : Function}){


    const classes = useStyles();
    const db = firebase.firestore()

    // console.log("chat in delete: ", chat)

    // useEffect(()=>{
    //     if(chat) setTitle(chat.title)
	// }, [chat])
    
    if(!chat){
        // console.log("no chat", chat)
        return null
    } 

    const handleClose = () => setOpenDeleteChatModal(false);


    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "700px",
        // width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    async function handleButton(event: React.MouseEvent<HTMLElement>){
        if(!chat) return

        setOpenBackdrop(true)

        console.log("deleting chat id: ", chat.id)

		try {

            await db.collection("chat").doc(chat.id).delete()

            chatDeleted(chat)
            setOpenDeleteChatModal(false)
            
		} catch (error) {
			console.error("delete Chat error", error)
		}

        setOpenBackdrop(false)
           
    }

    return(
        <Modal
            open={openDeleteModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            
            
            <Container style={{textAlign:"center"}}>
            <Box sx={style}>

            <Grid container item style={{textAlign:"left"}}>
                            <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClose}>
                    {/* Open Menu */}
                    <CloseIcon style={{color:"#555A64"}} />
                </Button>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.reqViewTitle} >
                                
                        Delete this chat?
                        
                        
                    </Typography>
                        
                       

                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.chatTitle} >
                                
                        {chat.title}
                        
                        
                    </Typography>
                        
                       

                    </Grid>

                    


                <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                id="upgradePro"
                                // onClick={startButtonClicked}
                                style={{
                                    margin: "1.5rem",
                                    width: "17.5rem",
                                    height: "3rem",
                                    fontFamily: "Roboto, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "1.25rem",
                                    lineHeight: "1.25rem",
                                    backgroundColor: "#FC5A5A"
                                }}
                            
                                onClick={handleButton}
                            
                            >
                            Delete
                            </Button>
                </Grid>    




                </Grid>



                </Box>
            </Container>
           
            
            
        </Modal>
    )
}