import { useState } from "react";
import firebase from "firebase"
import { Card, CardContent, Typography, Button, Grid, 
	Container,
	Modal,
    Box,
    FormControl, InputLabel, Select, MenuItem, TextField

} from '@material-ui/core';

import flag_us from "../../assets/flags/us.png"
import flag_tr from "../../assets/flags/tr.png"
import flag_it from "../../assets/flags/it.png"
import flag_fr from "../../assets/flags/fr.png"
import flag_es from "../../assets/flags/es.png"
import flag_de from "../../assets/flags/de.png"

import { User } from "../../Components/Interface";
import CloseIcon from '@material-ui/icons/Close';
import {useStyles} from "../../Components/useStyles"



export default function LanguageModal({user, openLanguageModal, setUser, setOpenLanguageModal, setAlertBarObject, setOpenBackdrop}:{user? :User, openLanguageModal :boolean, setUser :Function, setOpenLanguageModal : Function, setAlertBarObject : Function, setOpenBackdrop:Function}){

    const [fromLanguage, setFromLanguage]   = useState(user?.language?.from ?? "en")
    const [toLanguage, setToLanguage]       = useState(user?.language?.to ?? "en")

    const classes = useStyles();

    const handleClose = () => setOpenLanguageModal(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "700px",
        // width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    async function handleSaveButton(event: React.MouseEvent<HTMLElement>){

        if(!user){
            setOpenLanguageModal(false)
            return
        } 
        
        setOpenBackdrop(true)
        setOpenLanguageModal(false)

        const languageFunc = firebase
        .functions()
        .httpsCallable("editLanguage");
        const d = {
            language: {
                from : fromLanguage,
                to : toLanguage
            }            
        };

        try {
            const result = await languageFunc(d)

            if(result.data.done === true){

                setUser({...user,  language: {
                    from : fromLanguage,
                    to : toLanguage
                }   })
            }

            if(result.data.done === false && result.data.error){

                setAlertBarObject({open:true, type:"error", alertText: "Can't update the language"})
                console.error("Error saving language",result.data.error )
            }

            

        } catch (error) {
            setAlertBarObject({open:true, type:"error", alertText: "Can't update the language"})
            console.error("Error saving language", error )
            
        }

        
        setOpenBackdrop(false)
    }

    function FlagImg({country}:{country:string}){
        switch (country) {
            case "en": return(<img style={{width:"25px"}} src={flag_us}  alt="English"/>)
            case "tr": return(<img style={{width:"25px"}} src={flag_tr}  alt="Turkish"/>)
            case "es": return(<img style={{width:"25px"}} src={flag_es}  alt="Spanish"/>)
            case "fr": return(<img style={{width:"25px"}} src={flag_fr}  alt="French"/>)
            case "it": return(<img style={{width:"25px"}} src={flag_it}  alt="Italian"/>)
            case "de": return(<img style={{width:"25px"}} src={flag_de}  alt="German"/>)
                
                
        
            default:return(<img style={{width:"25px"}} src={flag_us}  alt="English"/>)
                
        }

    }

    function handleLanguageChange(event :  React.ChangeEvent<{ name?: string | undefined; value: unknown; }>){

        const name = event.target.name
        const value = event.target.value as string

        if(name === "input-language"){
            setFromLanguage(value)
            console.log("from language", value)

        }

        if(name === "output-language"){
            setToLanguage(value)
            console.log("to language", value)
            
        }
    }


    return(

        <Modal
            open={openLanguageModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            
            <Container style={{textAlign:"center"}}>
            <Box sx={style}>

                <Grid container item style={{textAlign:"left"}}>
                    <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClose}>
                    {/* Open Menu */}
                    <CloseIcon style={{color:"#555A64"}} />
                </Button>
                </Grid>

                <Grid container justify="center" spacing={3}>

            <Grid item xs={12} justify="center" >
                <Typography className={classes.reqViewTitle} >
                            
                           Edit Language Settings
                       
                   </Typography>
            </Grid>

            <Grid container item xs={12} spacing={4} justifyContent="center" alignItems="center">

                <Grid item xs={12} sm={6} >

                <FormControl fullWidth>
                <InputLabel id="input-language">Input Language</InputLabel>
                <Select
                labelId="input-language"
                id="input-language"
                name="input-language"
                value={fromLanguage}
                label="Input Language"
                onChange={handleLanguageChange}
                >
                 <MenuItem value={"en"}>
                    <FlagImg country="en"/>
                &nbsp; English
                </MenuItem>
                <MenuItem value={"fr"}> <img style={{width:"25px"}} src={flag_fr}  alt="French"/>
                &nbsp; French</MenuItem>
                <MenuItem value={"de"}> <img style={{width:"25px"}} src={flag_de}  alt="German"/>
        &nbsp; German</MenuItem>
                <MenuItem value={"it"}> <img style={{width:"25px"}} src={flag_it}  alt="Italian"/>
                &nbsp; Italian</MenuItem>
                <MenuItem value={"es"}> <img style={{width:"25px"}} src={flag_es}  alt="Spanish"/>
                &nbsp; Spanish</MenuItem>
                <MenuItem value={"tr"}> <img style={{width:"25px"}} src={flag_tr}  alt="Turkish"/>
                &nbsp; Turkish</MenuItem>
                </Select>
            </FormControl>
                 

                </Grid>

                <Grid item xs={12} sm={6} >

                <FormControl fullWidth>
                <InputLabel id="output-language">Output Language</InputLabel>
                <Select
                labelId="output-language"
                id="output-language"
                name="output-language"
                value={toLanguage}
                label="Output Language"
                onChange={handleLanguageChange}
                >
                    <MenuItem value={"en"}>
                    <img style={{width:"25px"}} src={flag_us}  alt="English"/>
                &nbsp; English
                </MenuItem>
                <MenuItem value={"fr"}> <img style={{width:"25px"}} src={flag_fr}  alt="French"/>
                &nbsp; French</MenuItem>
                <MenuItem value={"de"}> <img style={{width:"25px"}} src={flag_de}  alt="German"/>
        &nbsp; German</MenuItem>
                <MenuItem value={"it"}> <img style={{width:"25px"}} src={flag_it}  alt="Italian"/>
                &nbsp; Italian</MenuItem>
                <MenuItem value={"es"}> <img style={{width:"25px"}} src={flag_es}  alt="Spanish"/>
                &nbsp; Spanish</MenuItem>
                <MenuItem value={"tr"}> <img style={{width:"25px"}} src={flag_tr}  alt="Turkish"/>
                &nbsp; Turkish</MenuItem>
                </Select>
            </FormControl>
                 

                </Grid>

                <Grid item>

                <Button onClick={handleSaveButton} color="primary" className={classes.reqViewButton}>
                        Save
                </Button>

                </Grid>


               

            </Grid>

            </Grid>
            </Box>
            </Container>
           
            
            
        </Modal>
    )


}