// import * as fb from 'firebase';
import firebase from 'firebase'


// export interface PlRequest {

//     type   : PlRequestType
//     // object : SongLyrics | ProductDescription
//     songLyrics?         : SongLyrics
//     productDescription? : ProductDescription

// }

// export interface SongLyrics {
//         description : string
//         lyrics?     : string
//         mood?       : string
//         genre?      : string
// }

// export interface ProductDescription {
//     title?      : string
//     description : string
// }

// export interface ProductDescriptionObject {
//     title?          : string
//     description?    : string
// }

export interface User {
    userId : string
    status : "active" | "free" | "trial" | "deactive"

    email  : string

    intro  : boolean

    firstName? : string
    lastName? : string
    fullName? : string

    language? : {
        from : string
        to : string
    }

    account : {
        paymentActive    : boolean
        stripeCustomerId? : string    
        
        product   : "basic" | "pro" | "business" | "free" | "trial" | "free_comp"  
        productId : string
        price     : number
        interval  : "monthly" | "annual"
        currency  : "usd" | "eur" | "gbp" | "try"
        subStart  : firebase.firestore.Timestamp 
        trialEnd  : firebase.firestore.Timestamp 
        discount? : string        
    }

    

}

export enum PlRequestType {
    songLyrics          = "songLyrics",
    productDescription  = "productDescription",
    facebookAd          = "facebookAd",
    salesPas            = "salesPas",
    salesFab            = "salesFab",
    salesAida           = "salesAida",
    emailWelcome        = "emailWelcome",
    emailCancel         = "emailCancel",
    emailFollow         = "emailFollow",
    emailThank          = "emailThank",
    emailConfirm        = "emailConfirm",
    emailSubjectLine    = "emailSubjectLine",
    blogParagraph       = "blogParagraph",
    blogTitles          = "blogTitles",
    blogKeywords        = "blogKeywords",
    blogIntro           = "blogIntro",
    blogIdeas           = "blogIdeas",
    blogConclusion      = "blogConclusion",
    songLyricsLine      = "songLyricsLine",
    relatedWords        = "relatedWords",
    metaphor            = "metaphor",
    musicianBio         = "musicianBio",
    songLyricsKids      = "songLyricsKids",
    lullabyLyrics       = "lullabyLyrics",
    songBridge          = "songBridge",
    songChorus          = "songChorus",
    bandName            = "bandName",
    objectWriting       = "objectWriting",
    songOutline         = "songOutline",
    songTitle           = "songTitle",
    blogOutline         = "blogOutline",



}

export enum PlRequestTypeMain {
    music           = "music",
    product         = "product",
    socialMedia     = "socialMedia",
    startup         = "startup",
    website         = "website",
    sales           = "sales",
    blog            = "blog",
    email           = "email",
    writing         = "writing"
    
}



export interface SavedContent {
    userId : string   
    timestamp : firebase.firestore.Timestamp
    type : PlRequestType
    result : string
    promptSettings : {
        engine : OpenAiEngine
        temp : number
    }
}

export enum OpenAiEngine {
    davinci = "davinci",
    davinci_instruct_beta = "davinci_instruct_beta"
} 

export enum FieldId {
    title = "title",
    description = "description",
    concept = "concept",
    lyrics = "lyrics"
}

export interface PlRequestObj {
    typeMain : PlRequestTypeMain
    type     : PlRequestType,
    name     : string
    fields   : PlRequestObjField[]
    popular? : boolean
    live?    : boolean
    index    : number
}

export interface PlCategory {
    category : PlRequestTypeMain,
    types    : PlRequestObj[]
}

export interface PlRequestObjField {
    type            : "text" | "textfield" | "select"
    id              : string
    title           : string                    
    optional        : boolean
    placeholder?    : string
    maxChar?        : number    
    defaultValue?   : string
    helpText?       : string
    selectOptions?  : {
        value   : string
        id      : string
    }[]

}

export interface PlSavedRequestObj {
    userId      : string      
    requestId   : string
    typeMain    : PlRequestTypeMain
    type        : PlRequestType,
    fields    : {
        id      : string,
        value   : string
    }[],
    examples?   : string[]

    results      : string[]

    timestamp   : firebase.firestore.Timestamp

}

export interface PlResultObj {

    // userId      : string      
    // requestId   : string
    // typeMain    : PlRequestTypeMain
    // type        : PlRequestType,
    // fields    : {
    //     id      : string,
    //     value   : string
    // }[],
    // examples?   : string[]

    results      : PlResult[]

    // timestamp   : firebase.firestore.Timestamp

    
}

export interface PlResult {

    result      : string
    requestId   : string
    resultId?   : string
    saved?      : boolean

}

export interface PlSavedResultObj {

    userId      : string
    resultId    : string  
    requestId   : string
    typeMain    : PlRequestTypeMain
    type        : PlRequestType,
    fields    : {
        id      : string,
        value   : string
    }[],
    examples?   : string[]

    result      : string

    timestamp   : firebase.firestore.Timestamp

}

export interface PlNewRequest {
    type     : PlRequestType,
    fields?  : {
        id      : string,
        value   : string
    }[],
    examples? : string[]
}

export interface PlRequestServObj extends PlRequestObj  {
   
    //API


    
}

export interface OpenAiRequestss {
    type : PlRequestType
    engine: "davinci" | "curie" | "davinci-instruct-beta" | "curie-instruct-beta" | string;
    prompt?: string;
    maxTokens?: number;
    temperature?: number;
    topP?: number;
    n?: number;
    stream?: boolean;
    logprobs?: number;
    echo?: boolean;
    stop?: string | string[];
    presencePenalty?: number;
    frequencyPenalty?: number;
    bestOf?: number;
    user?: string;
    logitBias?: { [ token: string ]: number }
}

export interface OpenAiRequest {
    type                : PlRequestType
    engine              : "davinci" | "curie" | "davinci-instruct-beta" | "curie-instruct-beta" ;
    prompt              : string; // add |PROMPT1| 
    promptInputs        : {id:string, key:string}[]
    maxTokens           : number;
    temperature         : number;
    topP                : number;    
    stop                : string | string[];
    presencePenalty     : number;
    frequencyPenalty    : number;
}


// === CHAT ===`

export interface Chat {
    id          : string
    userId      : string
    title       : string
    created     : firebase.firestore.Timestamp 
    updated     : firebase.firestore.Timestamp 
    model       : string
    messages    : ChatMessage[]
    usage       : {prompt_tokens : number, completion_tokens : number, total_tokens : number}
    personaId?  : string
    context?    : string
}

export interface ChatObject {
    id?         : string
    model       : string
    messages    : ChatMessage[]
    personaId?  : string
}

export interface ChatMessage {
    role        : "system" | "user" | "assistant" | string
    content     : string
    // timestamp   : firebase.firestore.Timestamp 
}   

export interface ChatResponse {

    created : number
    model   : string
    usage : {prompt_tokens : number, completion_tokens : number, total_tokens : number}
    choices : {
        message : 
        {
            role : "system" | "user" | "assistant" | string,
            content : string
        },

        finish_reason : string
    
    }
}


// 'id': 'chatcmpl-6p9XYPYSTTRi0xEviKjjilqrWU2Ve',
//  'object': 'chat.completion',
//  'created': 1677649420,
//  'model': 'gpt-3.5-turbo',
//  'usage': {'prompt_tokens': 56, 'completion_tokens': 31, 'total_tokens': 87},
//  'choices': [
//    {
//     'message': {
//       'role': 'assistant',
//       'content': 'The 2020 World Series was played in Arlington, Texas at the Globe Life Field, which was the new home stadium for the Texas Rangers.'},
//     'finish_reason': 'stop',
//     'index': 0
//    }
//   ]

export interface UserPersonas {
    userId  : string
    persona : {
        title   : string
        content : string
    }[]
}

export interface Persona {
    id      : string
    userId  : string
    title   : string
    content : string
    created : firebase.firestore.Timestamp 
    updated : firebase.firestore.Timestamp 
    name?   : string
    img?    : string
    context? : string
}


export interface PlNewChatRequest {
    type     : PlRequestType,
    model    : string
    fields?  : {
        id      : string,
        value   : string
    }[],
    examples? : string[]
}

