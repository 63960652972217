import firebase from "../Components/Firebase";

 export default async function getCustomClaimRole():Promise<string|undefined> {

    const auth = firebase.auth()

    if(!auth.currentUser) return

    await auth.currentUser.getIdToken(true);
    const decodedToken = await auth.currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
}