import React, {useEffect, useState}  from 'react';
import {
	createMuiTheme,
	responsiveFontSizes,
	ThemeProvider,
} from "@material-ui/core/styles";
import {	
	Backdrop,
    CircularProgress,
    makeStyles
	
} from "@material-ui/core";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
	root: {
		width: "100%",
		flexGrow: 1,
		//   maxWidth: 500,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
});

export default function SimpleBackdrop({openBackDrop}:{openBackDrop:boolean}) {
   
    const [open, setOpen] = useState(false);

    const classes = useStyles();

    useEffect(() => {

        if(openBackDrop === open) return
        setOpen(openBackDrop)
	});

    return (
        <div>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}