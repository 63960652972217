import { User } from "../Components/Interface";

export default function shouldShowUserIntro(user: User) {
	if (
		(typeof user.intro === "undefined" || user.intro === false) &&
		(!user.firstName ||
			user.firstName.trim() === "" ||
			!user.lastName ||
			user.lastName.trim() === "")
	)
		return true;

	return false;
}
