import firebase from "firebase"

import { User } from "../../Components/Interface"
import { PRICE_INFO } from "../../Helpers/priceInfo"

export default async function launchStripe(db: firebase.firestore.Firestore , user : User , product: string):Promise<{error:string} | undefined>{

    const proPriceIdPerMo = PRICE_INFO.proMonthId  //$49 per mo
    const proPriceIdPerYr = PRICE_INFO.proYearId  // $39 per mo 
    const basicPriceIdPerMo = PRICE_INFO.basicMonthId  //$19 per mo 
    const basicPriceIdPerYr = PRICE_INFO.basicYearId  // $15 per mo 
    const prepaidPriceId = PRICE_INFO.prepaidId


    if(!user) return

    console.log("launching stripe")
    // setOpenUpgradeModal(false);

    // setOpenBackdrop(true)

    let priceId = proPriceIdPerMo
    if(product === "basicMo") priceId = basicPriceIdPerMo
    if(product === "basicYr") priceId = basicPriceIdPerYr
    if(product === "proMo") priceId = proPriceIdPerMo
    if(product === "proYr") priceId = proPriceIdPerYr
    if(product === "prepaid") priceId = prepaidPriceId
    // if(product === "musicYr") priceId = "price_1KURZMBUhWPsJD1y2U3DGLp1"
    // if(product === "musicMo") priceId = "price_1KURZMBUhWPsJD1yuoHLWvjy"

    try {

        const docRef = await db.collection('users').doc(user.userId).collection('checkout_sessions').add({
            price: priceId,
            // price: "price_1JY6LnBUhWPsJD1yjJl3pW49",
            success_url: "https://punchline.ai/home",
            cancel_url: window.location.origin,
            allow_promotion_codes: true,
            customer_email : user.email,
            client_reference_id : user.userId
        })
    
        console.log("stripe doc added")
    
        // Wait for the CheckoutSession to get attached by the extension
        const unsubscribe = docRef.onSnapshot((snap) => {
            const { error, url } = snap.data() as {error?:Error, url? : string};
    
            if (error) {
                // Show an error to your customer and 
                // inspect your Cloud Function logs in the Firebase console.
                
                // alert(`An error occured: ${error.message}`);
    
                // setAlertBarObject({open:true, type:"error", alertText:error.message})
                console.error("Stripe error", error.message)
                unsubscribe()
                return {error : error.message}
                // setOpenBackdrop(false)
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                console.log(`Got stripe url: ${JSON.stringify(url)}`)
                window.location.assign(url);
                unsubscribe()
            }
    
            
        });
        
    } catch (error) {
        console.error("Launch stripe error", error)
    }

    
}