import { useEffect, useRef, useState } from "react";
import { PlResult } from "../../../Components/Interface";
import ResultCell from "./ResultCell";
import { Grid } from "@material-ui/core";

import { ChatMessage } from "../../../Components/Interface";

import TypingIndicator from "../TypingIndicator";

export default function ResultsViewWithResults({
	chatMessages,isTyping
}: {
	chatMessages: ChatMessage[], isTyping:boolean
}) {
	
	const didMountRef = useRef(false)
	
	useEffect(() => {
		if(!chatMessages) return
	
		if (didMountRef.current) {
			document.getElementById("dummy")?.scrollIntoView({ behavior: "smooth" });
			console.log("Going to dummy div")
		  } else didMountRef.current = true
		
	}, [chatMessages])

	if(!chatMessages) return null

	return (
		<Grid container item  alignItems="flex-end" justifyContent="center" xs={12} spacing={1} style={{overflow:"auto", height:"80vh"}}>
			
			<Grid item xs={12} sm={7}>
				{chatMessages.map((r, i) => {
					return <ResultCell chatMessage={r} index={i} />
				})}

				<TypingIndicator isTyping={isTyping}/>

				<Grid item id={`${chatMessages.length + 1}`}>
					<div  id="dummy" />
				</Grid>
			  </Grid>
		</Grid>
	);
}
