import { User } from "../Components/Interface";

export default function isUserAccountOnTrial(user: User) {
	const now = Math.floor(Date.now() / 1000);

	if (
		user.account &&
		user.account.trialEnd &&
		user.account.trialEnd.seconds > now
	) {
		// Set remaining trial days
		// console.log(`user is on trial`)

		const days = Math.ceil((user.account.trialEnd.seconds - now) / 86400); // 86400 : day seconds

		return { active: true, trialDays: days };
	}

	return { active: false };
}
