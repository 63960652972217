import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },

    reqViewTitle: {
        fontFamily: "SF Pro Display, Roboto, sans-serif",
		color: "#2C3038",
		fontSize: "1.125rem",
		fontWeight: 600,
		fontStyle: "normal",
    },
    reqViewSubTitle: {
        fontFamily: "SF Pro Display, Roboto, sans-serif",
		color: "#2C3038",
		fontSize: "1.125rem",
		fontWeight: 500,
		fontStyle: "normal",
    },

    reqViewButton: {
        width: "220px",
        height: "36px",
        // left: 85.5px;
        // top: 0px;

        /* Blue */

        background: "#0062FF",
        color: "white",
        borderRadius: "5px",
        marginTop:"20px",
        '&:hover': {
            // backgroundColor: '#fff',
            color: '#0062FF',
        }
    },

    newChatButton: {
        width: "120px",
        height: "36px",
        // left: 85.5px;
        // top: 0px;

        /* Blue */

        background: "white",
        color: "#0062FF",
        borderRadius: "5px",
        borderColor: "#0062FF",
        marginTop:"20px",
        '&:hover': {
            // backgroundColor: '#fff',
            color: '#0062FF',
        }
    },

    feedbackButton: {
        width: "220px",
        height: "36px",
        textTransform: "none",
        // left: 85.5px;
        // top: 0px;

        /* Blue */

        background: "white",
        color: "#6E6D7A",
        // borderRadius: "5px",
        marginTop:"20px",
        '&:hover': {
            // backgroundColor: '#fff',
            // color: '#0062FF',
        }
    },

    reqViewHelperText: {
        textAlign: "end",
        marginRight: "0px"
    },

    resultCard : {
        marginBottom : "20px"
    },

    resultCardUser : {
        marginBottom : "20px",
        backgroundColor : "#FAFAFB",
        borderWidth : "0px"
    },

    cardTitleText : {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        // font-style: normal;
        // font-weight: normal;
        fontSize: "20px",
        // line-height: 23px;
        // display: flex;
        // align-items: center;

        /* Dark Gray */

        color: "#3E4348",
        whiteSpace : "pre-line"
    },
    cardContent : {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        // font-style: normal;
        // font-weight: normal;
        fontSize: "16px",
        // line-height: 23px;
        // display: flex;
        // align-items: center;

        /* Dark Gray */

        color: "#6E6D7A",
        whiteSpace : "pre-line"
    },

    resultText : {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        // font-style: normal;
        // font-weight: normal;
        fontSize: "16px",
        // line-height: 23px;
        // display: flex;
        // align-items: center;

        /* Dark Gray */

        color: "#6E6D7A",
        whiteSpace : "pre-line"
    },

    chatTitleText : {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        // font-style: normal;
        // font-weight: normal;
        fontSize: "20px",
        // line-height: 23px;
        // display: flex;
        // align-items: center;

        /* Dark Gray */

        color: "#6E6D7A",
        whiteSpace : "pre-line"
    },

    chatPersonaText : {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 600,
        // font-style: normal;
        // font-weight: normal;
        fontSize: "14px",
        // line-height: 23px;
        // display: flex;
        // align-items: center;

        /* Dark Gray */

        color: "#6E6D7A",
        whiteSpace : "pre-line"
    },



    resultCopyButton : {

        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#92929D",
        backgroundColor: "#F3F3F4",
        fontWeight: 500,
        // justifyContent: 'center'
        marginLeft: 'auto',


    },

    emptyStateText : {
        fontFamily: "Roboto, sans-serif",
        fontSize: "30px",
        color: "#6E6D7A",
        alignItems : "center",
        textAlign : "center",
        fontWeight: 300,
    },
    heroSubtitle: {
        fontFamily: "Roboto, sans-serif",
        // font-style: normal;
        fontWeight: 500,
        fontSize: "24px",
        // line-height: 28px;
        // text-align: center;

        color: "#7F8893"
    },
    priceAmount : {
        color : "#3E4348",
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        fontSize: "30px"
    },
    priceText: {
        fontFamily: "Roboto, sans-serif",
        // font-style: normal;
        fontWeight: 500,
        fontSize: "18px",
        // line-height: 28px;
        // text-align: center;

        color: "#7F8893"
    },
    benefitsSubtitle: {
        fontFamily: "Roboto, sans-serif",        
        fontWeight: 500,
        fontSize: "30px",
        color : "#3E4348",
    },

    switch : { '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#DAE5DD",
        // backgroundColor: "#28A745",
        '&:hover': {
          backgroundColor: "#28A745",
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#28A745",
        color: "#28A745",
        
      },
    },
    priceTermText: {
        fontFamily: "Roboto, sans-serif",
        // font-style: normal;
        fontWeight: 500,
        fontSize: "18px",
        // line-height: 28px;
        // text-align: center;

        color: "#7F8893"
    },
    priceTermTextBlack: {
        fontFamily: "Roboto, sans-serif",
        // font-style: normal;
        fontWeight: 500,
        fontSize: "18px",
        // line-height: 28px;
        // text-align: center;

        color: "#3E4348"
    },
    benefitsTitle: {
		color: "#3E4348",
		fontFamily: "Roboto, sans-serif",
		fontWeight: 500,
		fontSize: "40px",
	},
    faqTitle: {
		color: "#3E4348",
		fontFamily: "Roboto, sans-serif",
		fontWeight: 500,
		fontSize: "24px",
	},
	faqText: {
		fontFamily: "Roboto, sans-serif",
		// font-style: normal;
		fontWeight: 500,
		fontSize: "24px",
		// line-height: 28px;
		// text-align: center;

		color: "#7F8893",
	},

    newPersonaButton: {
        width: "120px",
        height: "36px",
        // left: 85.5px;
        // top: 0px;
    
        /* Blue */
    
        background: "white",
        color: "#0062FF",
        borderRadius: "5px",
        // marginTop:"20px",
        '&:hover': {
            // backgroundColor: '#fff',
            color: '#0062FF',
        }

    },

    deleteButton: {
        width: "220px",
        height: "36px",
        // left: 85.5px;
        // top: 0px;

        /* Blue */

        background: "red",
        color: "white",
        borderRadius: "5px",
        marginTop:"20px",
        '&:hover': {
            // backgroundColor: '#fff',
            color: 'red',
        }
    },
    upgradeButton : {
        background: "white",
        color: "#28A745",
      },






});