import { Card, CardContent, Typography, CardActions, Button, Grid } from "@material-ui/core"

import { PlResult } from "../../../Components/Interface"
import { useStyles } from "../../../Components/useStyles"

import { ChatMessage } from "../../../Components/Interface"

export default function ResultCell({chatMessage, index}:{chatMessage: ChatMessage, index:number}){

    const classes = useStyles()
        
    let text = chatMessage.content.replace(/^_+|_+$/g, '').replace(/(\r\n|\r|\n){2}/g, '$1').replace(/(\r\n|\r|\n){3,}/g, '$1\n').replace(/\\\\/g, '\\').trim()


    function CardButton(){
        if(chatMessage.role === "assistant"){
            return(
                <Grid id={`${index}`} item xs={12} style={{width:""}}>
                <CardActions>
                    <Button className={classes.resultCopyButton} onClick={() => {navigator.clipboard.writeText(text)}} >Copy</Button>
                </CardActions>
                </Grid>
            )
        }

        return null
    }

    // if(chatMessage.role === "dummy"){

    //     return(
    //         <Grid item id={`${index}`}>
    //         <div  id="dummy" />
    //         </Grid>
    //     )
        
    // }

    return(
        <Grid item id={`${index}`} xs={12}>
        <Card  variant="outlined" className={chatMessage.role === "assistant" ? classes.resultCard : classes.resultCardUser}>
            <CardContent>
                <Typography className={classes.resultText}>{text}</Typography>

            </CardContent>

            <CardButton/>
          
        </Card>
        </Grid>

    )
}