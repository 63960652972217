import { Chat, ChatObject } from "../../Components/Interface";

import firebase from "firebase";


export default async function getChats(userId:string):Promise<Chat[]|undefined>{

    const db = firebase.firestore();

    try {
        const result = await db.collection("chat").where("userId", "==", userId).get()
        
        if(result.empty) return
        
        const chats = result.docs.map(r=>{

            return r.data() as Chat
        }) 

        chats.sort((a, b)=> (a.updated.seconds - b.updated.seconds))

        return chats

    } catch (error) {
        console.error("Error getting chats", error)
        return
    }
}