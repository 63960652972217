import { User } from "../Components/Interface";
import firebase from "../Components/Firebase";

export default async function getUser(userId: string) {
	const db = firebase.firestore();

	try {
		const userDoc = await db.collection("users").doc(userId).get();
		const user = userDoc.data() as User;
		return user;
	} catch (error) {
		console.error(`getUser: Error getting user doc ${error}`);
		return;
	}
}
