import { PlResult } from "../Interface";
import ResultCell from "./ResultCell";

export default function ResultsViewWithResults({
	resultsList,
}: {
	resultsList: PlResult[];
}) {
	const newResults = resultsList.filter((r) => {
		return r.result.trim() !== "";
	});

	return (
		<>
			{newResults.map((r) => {
				return <ResultCell result={r} />;
			})}
		</>
	);
}
