import React from 'react';
import ReactDOM from 'react-dom';
import {  BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import './index.css';
import App from './App';
// import Landing from "./views/Landing"
import Landing from "./views/Landing/index"
import reportWebVitals from './reportWebVitals';

import Home from "./views/Home"
import Signin from "./views/Signin"
import Account from "./views/Account"

import Prompt from "./views/NewPrompt/promptTest"

import { IntlProvider } from "react-intl";
// import messages_tr from "./translations/tr.json"
import messages_en from "./translations/en.json"
import HomeChat from './views/Home/HomeChat';
import PersonaView from './views/PersonaView';
import Chats from './views/Chats';


const messages = {
  // "tr" : messages_tr,
  "en" : messages_en
}

//TODO Change when new languages are added
// const language = navigator.language.split(/[-_]/)[0] == "tr" ? "tr" : "en"
const language = "en"


ReactDOM.render(

  <IntlProvider locale={language} messages={messages[language]}>
    <Router>
        <Switch>

        <Route
            path="/home/"
            exact
            render={props => <HomeChat {...props} />}
            
          />

          <Route
            path="/chats/"
            exact
            render={props => <Chats {...props} />}
            
          />

          <Route
            path="/home/:status"
            exact
            render={props => <Home {...props} />}
            
          />

          

          <Route
            path="/signin/"
            exact
            render={props => <Signin {...props} />}
            
          />

        <Route
            path="/account/"
            exact
            render={props => <Account {...props} />}
            
          />

        <Route
            path="/prompt/"
            exact
            render={props => <Prompt {...props} />}
            
          />

        <Route
            path="/personas/"
            exact
            render={props => <PersonaView {...props} />}
            
          />

          <Route path="/" exact render={props => <Landing {...props} />} />

          {/* <Redirect to="/" /> */}
        </Switch>
    </Router>
  </IntlProvider>,
  document.getElementById('root')





  // <React.StrictMode>
  //   <Landing />
  // </React.StrictMode>,
  // document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
