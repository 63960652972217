import React, {useEffect, useState}  from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';



const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
}));


export interface AlertBarObject {
    open         : boolean 
    type?        : "warning" | "success" | "error" | "info"
    alertText?   : string   
}

/**
 * Defaults to warning
 * 
 * @param  open : boolean  
 * @param type? : "warning" | "success" | "error" | "info"
 * @param alertText? : string
 * @returns 
 */
export  function AlertBar({alertBarObject, alertBarDone}:{alertBarObject:AlertBarObject, alertBarDone:Function }) {

    
    const [open, setOpen] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        // if(alertBarObject.open === false)  return
        
        setOpen(alertBarObject.open)
	});

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function handleClose (event?: React.SyntheticEvent, reason?: string){
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
        alertBarDone()
    };

    if(!alertBarObject.alertText || !alertBarObject.type) return null

    return (
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertBarObject.type}>
                {alertBarObject.alertText}
            </Alert>
        </Snackbar>
    );



}