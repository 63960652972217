import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useStyles } from "../../Components/useStyles";

export default function Faq() {
    const classes = useStyles()
    
    const faq = [
        {
            q: "How does Punchline create this content?",
            a: "Punchline uses its own machine learning along with an AI model called GPT-3, and combines these two technologies. ",
        },
        // {
        //     q : "Is the content from Punchline original?",
        //     a : "Punchline generates original content that passes plagiarism tests with 99%. So, it doesn't repeat itself; you can use the content for publication. "
        // },
        {
            q: "How are credits used?",
            a: "1 word generated with GPT-3.5 costs 1 credit. 1 word generated with GPT-4 costs 20 credits. ",
        },
        {
            q: "Can I cancel anytime?",
            a: "Yes, go to your Account and click Manage Billing to update your subscription.",
        },
        {
            q: "Can I use Punchline for languages other than English?",
            a: "Yes, you can also use Punchline in French, German, Italian, Spanish and Turkish. We will add other languages in the near future.",
        },
    ];

    return (
        <Grid
            container
            justifyContent="center"
            style={{ marginTop: "50px", marginBottom: "100px" }}
        >
            <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
                <Typography
                    variant="h2"
                    component="h2"
                    className={classes.benefitsTitle}
                >
                    FAQ
                </Typography>
            </Grid>

            <Grid item xs={12} sm={8}>
                {faq.map((f) => {
                    return (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ textAlign: "start" }}
                            >
                                <Typography className={classes.faqTitle}>{f.q}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ textAlign: "start" }}>
                                <Typography>{f.a}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Grid>
        </Grid>
    );
}