import { Grid, Typography } from "@material-ui/core";

import { useStyles } from "../../../Components/useStyles";
import emptyState from "../../../assets/empty_state.png";

export default function ResultsViewEmptyState() {
	const classes = useStyles();

	return (
		<Grid container justify="center">
			<Grid
				container
				item
				xs={12}
				sm={4}
				justify="center"
				alignContent="center"
			>
				<img
					src={emptyState}
					style={{ marginTop: "20px", marginBottom: "20px" }}
					// style={{ width: "75%", height: "auto" }}
					// onClick={() => handleClose()}
				/>
			</Grid>
			<Grid>
				<Typography className={classes.emptyStateText}>
					Select an AI model and a Persona to chat with
				</Typography>
			</Grid>
		</Grid>
	);
}
