import {User} from "../Components/Interface"

import isOpenaiTestUser from "./isOpenaiTestUser"
import getCustomClaimRole from "./getCustomClaimRole"

export default async function isUserAccountActive(user:User){

    if(user.status === "free" || user.status === "active"){
        // console.log(`user is free`)
        return true
    } 
    
    if (isOpenaiTestUser(user.email) === true) return true
    
    const role = await getCustomClaimRole()

    if(role && (role === "premium" || role === "basic")){
        // console.log(`user is premium`)
        return true
    } 

    return false

}