import { Grid } from "@material-ui/core"
import { PlResult } from "../Interface"
import ResultsViewEmptyState from "./ResultsViewEmptyState"
import ResultsViewWithResults from "./ResultsViewWithResults"


export default function ResultsView({resultsList}:{resultsList?:PlResult[]}){

    return(
        <Grid style={{marginTop:"20px"}} xs={12} sm={7}>
            {
                (!resultsList || resultsList.length === 0) 
                ? <ResultsViewEmptyState/>    
                : <ResultsViewWithResults resultsList={resultsList}/>
            }
            
        </Grid>
    )
}