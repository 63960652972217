import { useState, useEffect } from "react"
import firebase from "firebase"
import { Grid, Typography, Select, FormControl, MenuItem } from "@material-ui/core"

import { Persona } from "../../Components/Interface"
import { useStyles } from "../../Components/useStyles"

import NewPersona from "./NewPersona"

export default function PersonaView({userId, selectedPersona, setSelectedPersona,  db,}:{userId?:string, selectedPersona? : Persona, setSelectedPersona : Function, db : firebase.firestore.Firestore}){

    const assistantPersona : Persona = {
        id      : "1",
        userId  : "1",
        title   : "General Assistant",
        content : "",
		created : firebase.firestore.Timestamp.now(),
		updated : firebase.firestore.Timestamp.now()
    }
    
    const [personas, setPersonas] = useState<Persona[]>([assistantPersona])

    const [openPersonaModal, setOpenPersonaModal] = useState(false)

    

    useEffect(() => {
        if(userId){
            getPersonas(userId).then(p =>{
                if(p){
                    setPersonas([assistantPersona, ...p])
                }else{
                    setDefaultPersonas(userId)
                }

            })
        }

        
		
	}, [userId]);

    // const assistantPersona : Persona = {
    //     id      : "1",
    //     userId  : "1",
    //     title   : "General Assistant",
    //     content : ""
    // }

    const classes = useStyles()

    function handleSelect(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
        ) {
            
            const value = event.target.value as string;
            const name = event.target.name;

            // if(value === "1"){
            //     setSelectedPersona({})
            // }

            if(value === "100"){
                console.log("create new persona")
                
                const assistant = personas[0]
                // setSelectedPersona(assistant)
                setOpenPersonaModal(true)
                return
            }

            const p = personas.filter(per =>{
                return per.id === value
            })[0]

            setSelectedPersona(p)

    }

    async function getPersonas(userId:string): Promise<Persona[] | undefined>{

        try {
            const r = await db.collection("persona").where("userId", "==", userId).get()
            if(r.empty){
                console.log("no personas!!")
                return
            } 

            return r.docs.map((d) =>{				
                return d.data() as Persona
            })

        } catch (error) {
            console.error("Error getting personas", error)
            return
        }		
    }

    function newPersona(newPersona : Persona){
        const updatedPersonas = [...personas, newPersona]
        setPersonas(updatedPersonas)
    }

    async function setDefaultPersonas(userId : string){

        const now = firebase.firestore.Timestamp.now()

        let defaultPersonas : Persona[] = [
            {
                id      : db.collection("persona").doc().id,
                userId  : userId,
                title   : "Digital Marketing Manager",
                content : "You are a great digital marketing manager with supreme command of written communication. You want my brand to be very successful. You guide me through step by step process to generate content that creates high user engagement",
                created : now,
                updated : now
            },
            {
                id      : db.collection("persona").doc().id,
                userId  : userId,
                title   : "Musician",
                content : `You are a great song writer. You want to help me write catchy songs. You guide me through a step-by-step process to write a great song. When writing lyrics you follow the following rules:
                            - You use create metaphors
                            - You avoid cliches
                            - You use perfect rhymes infrequently
                            - You use simple words
                            - Your lyrics paint a picture of the emotional situation
                            - You try to show with your lyrics rather than tell the listener`,
                created : now,
                updated : now
            },
            {
                id      : db.collection("persona").doc().id,
                userId  : userId,
                title   : "Life Coach",
                content : "You're an effective altruist life coach. You're wise, ask thought-provoking questions, problem-solving focused, warm, humorous, and are a rationalist. You care about helping me achieve my goals. You want me to do the most good and also be very happy. You ask me about what I want help figuring out or what problem I'd like help solving, then guide me through a rational, step-by-step process to figure out the best, most rational actions I can take to achieve my goals. You don't waste time and get straight to the point",
                created : now,
                updated : now
            },
        ]

        let batch = db.batch();


        for (const p of defaultPersonas) {

            batch.set(db.collection("persona").doc(p.id), p)
            
        }


        try {


           await batch.commit()
           console.log("Default personas are added")

           setPersonas([assistantPersona, ...defaultPersonas])


        } catch (error) {
            console.error("Error getting default personas", error)
            return
        }

    }

    

    return(
        
        <Grid container item xs={12} md={8} >

            <NewPersona userId={userId} db={db} openPersonaModal={openPersonaModal} setOpenPersonaModal={setOpenPersonaModal} newPersona={newPersona} />

                <Grid item xs={12}>
                    <Typography className={classes.reqViewTitle}>
                        Persona
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth={true}>
                        {/* <InputLabel id="demo-simple-select-outlined-label"> </InputLabel> */}
                        <Select
                            style={{ color: "#28A745" }}
                            name="category"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectedPersona?.id}
                            onChange={handleSelect}
                            // label="Select a tool"
                        >
                             {/* <MenuItem value={"1"} ><Typography style={{color:"red"}}>General Assistant</Typography></MenuItem> */}

                            {
                                personas.map(p=>{
                                    return(
                                        <MenuItem value={p.id} >{p.name ? p.name + " - " + p.title : p.title}</MenuItem>
                                    )
                                })
                            }

                                <MenuItem value={"100"} ><Typography style={{color:"#0062FF"}}>Create New Persona</Typography></MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
                
    )
}