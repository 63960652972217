import React, {useEffect, useState} from 'react';
import firebase from "../Components/Firebase"
// import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Menu, MenuItem, Toolbar, Typography, IconButton, Button, Modal, Backdrop, Fade, DialogTitle, Dialog, DialogContent, useMediaQuery, Grid} from '@material-ui/core';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';


// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';

// import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../assets/appBarLogo.png'
import flag_us from "../assets/flags/us.png"
import flag_tr from "../assets/flags/tr.png"
import flag_it from "../assets/flags/it.png"
import flag_fr from "../assets/flags/fr.png"
import flag_es from "../assets/flags/es.png"
import flag_de from "../assets/flags/de.png"
import '../App.css';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

// import firebase from "./Firebase"
// import firebaseui from 'firebaseui'
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as fb from 'firebase';

import { useHistory, Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from "react-intl";


// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';


// import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
// import CssBaseline from '@mui/material/CssBaseline';
// import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@material-ui/icons//Menu';
// import ChevronLeftIcon from '@material-ui/icons//ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons//ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';

import { ChatObject } from './Interface';


// import firebase from 'firebase';

import MenuIcon from '@material-ui/icons/Menu';

// import IconButton from '@mui/material/IconButton';
// import InboxIcon from '@material-ui/icons/MoveToInbox';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
      color:"#FC5A5A",
      fontFamily: 'Montserrat, sans-serif',
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "24px",
    lineHeight: "29px",
    // display: flex;
    // align-items: center;
    // text-align: center;
  },
  upgradeButton : {
    background: "white",
    color: "#28A745",
  },
  newChatButton: {
    width: "120px",
    height: "36px",
    // left: 85.5px;
    // top: 0px;

    /* Blue */

    background: "white",
    color: "#0062FF",
    borderRadius: "5px",
    // marginTop:"20px",
    '&:hover': {
        // backgroundColor: '#fff',
        color: '#0062FF',
    }
},
 
}));



// export default function Menubar({openSignin, closeSignIn, successUrl}:{openSignin?:boolean, closeSignIn?:Function, successUrl?:string}) {

// export default function Menubar({currentUser, goTo}:{currentUser? : firebase.default.User, goTo?:Function}) {

export default function ButtonAppBar({currentUser, trialDays, activeAccount, goTo, openSignin, closeSignIn, successUrl, location, language, handleDrawerToggle, chatObject, setChatObject}:{currentUser? : firebase.default.User, trialDays?: number, activeAccount?: boolean, goTo?:Function, openSignin?:Function, closeSignIn?:Function, successUrl?:string, location?:string, language?:string, handleDrawerToggle? : Function, chatObject? : ChatObject, setChatObject?: React.Dispatch<React.SetStateAction<ChatObject | undefined>>}) {

  const drawerWidth = 240;

  const classes = useStyles();

  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const isLargeScreen = useMediaQuery(`(min-width:600px)`)

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));


  const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null);

  // const [loggedIn, setLoggedIn] = useState(false)

  const [user, setUser] = useState<firebase.default.User | undefined>(currentUser)

  const [firebaseAuthOpen, setFirebaseAuthOpen] = useState(false);

  let auth : firebase.default.auth.Auth = firebase.auth()
  
  const history = useHistory()
  const intl = useIntl();

  

  const userItems = ["home", "personas", "chats", "account", "help", "feedback", "signout"]
  const nonUseritems = ["help", "signin"]
  const items = user ? userItems : nonUseritems

  useEffect(() => {
    console.log("location", location)
    if(currentUser) setUser(currentUser)
    if(!user){
      checkUser()
    }
  }, []);


  function checkUser(){
    firebase.auth().onAuthStateChanged(currentUser => {
      if (currentUser) setUser(currentUser)
    });
  }

  async function signout (){
    // console.log("sign out now")
    
    // const r = await auth.signOut()

    

    auth.app.auth().signOut().then(()=>{
      // console.log("first signout ok")
      // setUser(undefined)
      // setAnchorEl(null);
      // history.push("/")
    }).catch((error) => {
      // An error happened.
      console.error("first signout error", error)
    });

    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      // console.log("signout ok")
      setUser(undefined)
      setAnchorEl(null);
      history.push("/")
    }).catch((error) => {
      // An error happened.
      console.error("signout error", error)
    });
    
  }

  function handleMenuClick(event : React.MouseEvent<HTMLLIElement, MouseEvent>){
    const id = event.currentTarget.id

    setAnchorEl(null);

    // setAnchorEl(event.currentTarget)

    //Save the current chat
    summarize(chatObject)
    
    
    if(id === "home"){
      history.push("/home")
      return
    }

      if(id === "personas"){
      history.push("/personas")
      return
    }

    if(id === "chats"){
      history.push("/chats")
      return
    }

    if(id === "account"){
      history.push("/account")
      return
    }

    if(id === "help"){
      setAnchorEl(null);
    
      window.location.href = 'https://www.notion.so/azizakgul/Punchline-ai-Help-8b93a5b5ba5c425f913b3439b1337346?pvs=4';

      return
    }

    if(id === "feedback"){
      setAnchorEl(null);
    
      window.location.href = 'https://forms.gle/sWjKVHuM5rUGsr2F6'

      return
    }

    if(id === "signout"){
      signout()
      return
    }

    if(id === "signin"){
      signin()
      return
    }

    


  }

  function handleClick(event: React.MouseEvent<HTMLElement>){ 
    const id = event.currentTarget.id
 
    if( id === "menu"){
      setAnchorEl(event.currentTarget);
      return
    }
    
   

    if(id === "help"){
      setAnchorEl(null);
      // window.open("https://www.giglink.io/api/help", "_blank") 
      window.open("https://www.notion.so/azizakgul/Help-00207cb622874492a15d425ee00a8113", "_blank") 
      
    }

    if(id === "settings"){
      setAnchorEl(null);
 
      // window.open("https://www.giglink.io/api/help", "_blank") 
    }

    if(id === "upgrade" && goTo){

        goTo("upgrade")
    }

    if(!id){
      //handleClose
      setAnchorEl(null);
      return
    }
    
  };

  function signin(){   
    history.push("/signin")
    setAnchorEl(null);
  };

  function NewChatButton({setChatObject}:{setChatObject?: React.Dispatch<React.SetStateAction<ChatObject | undefined>>}){
		
    if(!user || !setChatObject || !location || location !== "home") return null

		async function handleNewChat(){
      let obj = chatObject
      if(setChatObject) setChatObject(undefined)
      await summarize(obj)
			
		}

		return(
			<Button
				onClick={handleNewChat}
				color="primary"
				className={classes.newChatButton}

			>
				New Chat
			</Button>
		)
	}

  function TrialDaysButton(){

    if(!isLargeScreen) return null

    if((activeAccount === false || (trialDays && trialDays > 0))){
      return(
        <Button id="upgrade" className={classes.upgradeButton} onClick={handleClick}>Trial Days: {trialDays ?? 0}</Button>
      )
    }

      return null
  }

  function LanguageButton(){

    if(user && location && location === "home"){
      return(
        <IconButton aria-label="English" onClick={() => {setAnchorEl(null); if(goTo)goTo("editLanguage")}} >
        <Flag/>        
      </IconButton>
      )
    }

    return null

    function Flag(){
      if(!currentUser) return null
  
      if(!language){
        return(<img style={{width:"25px"}} src={flag_us}  alt="English"/>)
      }
  
      switch (language) {
        case "en":
          return(<img style={{width:"25px"}} src={flag_us}  alt="English"/>)
  
        case "tr":
          return(<img style={{width:"25px"}} src={flag_tr}  alt="Turkish"/>)
  
        case "de":
          return(<img style={{width:"25px"}} src={flag_de}  alt="German"/>)
        case "fr":
          return(<img style={{width:"25px"}} src={flag_fr}  alt="French"/>)
        case "it":
          return(<img style={{width:"25px"}} src={flag_it}  alt="Italian"/>)
  
        case "es":
          return(<img style={{width:"25px"}} src={flag_es}  alt="Spanish"/>)
          
        default:
          return(<img style={{width:"25px"}} src={flag_us}  alt="English"/>)
         
      }
  
  
    }
  }

  async function summarize(chatObject?:ChatObject){

    if(!chatObject) return

		const summarizeFunc = firebase.functions().httpsCallable("summarize");

		try {
			const result = await summarizeFunc(chatObject)
      // console.log("Summarize result", result)
		} catch (error) {
			// console.log("Summarize error", error)
		}
	}

  function MenuButton(){
    return(
      <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
      {/* Open Menu */}
      <AccountCircleOutlinedIcon style={{color:"#555A64"}} />
    </Button>
    )
  }

  function MenuView(){

    const userItems = ["home", "personas", "account", "help", "feedback", "signout"]
    const nonUseritems = ["help", "signin"]
    const items = user ? userItems : nonUseritems

    return(
      <>
      
      <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
      {/* Open Menu */}
      <AccountCircleOutlinedIcon style={{color:"#555A64"}} />
    </Button>
      

      <Menu
      id="close_menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClick}
      
     
      // getContentAnchorEl={null}
    >
       {
            items.map(i =>{
              return(
                <MenuItem id={i} onClick={handleMenuClick}
                >{intl.formatMessage({id: `navbar.menu.${i}`})}
            </MenuItem>  
              )
            })
          }
      
    </Menu>
    </>

    )
  }

  return (
    
      <Box sx={{ display: 'flex' }}>
      <AppBar position="static" style={{backgroundColor:"white", zIndex:"1200"}} elevation={0}>
        <Toolbar>

        <Grid  justifyContent="space-between"  container >
          <Grid item>
            <span className={classes.logo}>punchline.ai</span>
          </Grid>
         
            <Grid item>
              <TrialDaysButton/>

              <NewChatButton setChatObject={setChatObject} />
          
              <LanguageButton/>
              {/* <Box sx={{ flexGrow: 0 }}> */}
              {/* <MenuButton/> */}

              {/* <MenuView/>    */}
              {/* </Box> */}
              <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
      {/* Open Menu */}
      <AccountCircleOutlinedIcon style={{color:"#555A64"}} />
    </Button>
      

      <Menu
      id="close_menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClick}
      // getContentAnchorEl={null}
      > 
       {
            items.map(i =>{
              return(
                <MenuItem id={i} onClick={handleMenuClick}
                >{intl.formatMessage({id: `navbar.menu.${i}`})}
            </MenuItem>  
              )
            })
          }
      
    </Menu>
            </Grid>  
          </Grid>  
        </Toolbar>
      </AppBar>
      </Box>
      
  );
}
