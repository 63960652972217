import React, { useEffect, useState, useRef } from "react";

import { useHistory, RouteComponentProps, useParams, useLocation } from "react-router-dom";
import {
	Card,
	CardContent,
	Typography,
	Button,
	Grid,
	Container,
	FormControl,
	Select,
	MenuItem,
	TextField,
} from "@material-ui/core";

import firebase from "firebase";
import {
	PlRequestType,
	PlRequestObj,
	PlRequestTypeMain,
	PlNewRequest,
	PlRequestObjField,
	PlResult,
	User,
	PlCategory,
	ChatMessage,
    ChatObject,
	Persona, Chat
} from "../../Components/Interface";
import Menubar from "../../Components/Menubar";

import { useIntl } from "react-intl";
import SimpleBackdrop from "../../Components/BackDrop";
import { AlertBar, AlertBarObject } from "../../Components/AlertBar";

import { useStyles } from "../../Components/useStyles";

import FeedbackButton from "../../Components/feedbackButton";

import shouldShowUserIntro from "../../Helpers/shouldShowUserIntro";
import isUserAccountActive from "../../Helpers/isUserAccountActive";
import isUserAccountOnTrial from "../../Helpers/isUserAccountOnTrial";
import getUser from "../../Helpers/getUser";
import getToolsList from "../../Helpers/getToolsList";
import isOpenaiTestUser from "../../Helpers/isOpenaiTestUser";
import ResultsView from "./ResultsView";

import ComposeView from "./ComposeView";

import UpgradeModal from "./UpgradeModal";
import UserNameModal from "./UserNameModal";
import LanguageModal from "./LanguageModal";

import SendChat from "./SendChat";

import PersonaView from "./Persona";
import MenuDrawer from "./MenuDrawer";
import MenuDrawerNew from "./MenuDrawerNew";

import ResultsViewWithResults from "./ResultsView/ResultsViewWithResults";
import getCustomClaimRole from "../../Helpers/getCustomClaimRole";
import { useMediaQuery } from '@mui/material';

import ResultsViewEmptyState from "./ResultsView/ResultsViewEmptyState";

export default function HomeChat({ match }: RouteComponentProps) {
	/**
	 * - check if user is signed in, else send to landing
	 * - check if user is on trial, paid, expired. If expired show billing
	 *
	 *
	 */

	const [user, setUser] = useState<User>();

	const [fbUser, setFbUser] = useState<firebase.User>();

	const [trialDays, setTrialDays] = useState(0);
	const [activeAccount, setActiveAccount] = useState(true);
	const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
	const [openUserNameModal, setOpenUserNameModal] = useState(false);
	const [openLanguageModal, setOpenLanguageModal] = useState(false);

	// Request
	const [title, setTitle] = useState<string | undefined>();

	// const [plReq, setPlReq] = useState<PlRequest|undefined>()

	const [plReqObj, setPlReqObj] = useState<PlRequestObj[]>();

	const [resultsList, setResultsList] = useState<PlResult[]>();

	const [openBackdrop, setOpenBackdrop] = useState(false);

	const [requestType, setRequestType] = useState<PlRequestType>(
		PlRequestType.blogIdeas
	);

	const [categoryType, setCategoryType] = useState<PlRequestTypeMain>(
		PlRequestTypeMain.blog
	);

	const [categories, setCategories] = useState<PlCategory[]>();
	const [newRequestType, setNewRequestType] = useState<{
		category: PlRequestTypeMain;
		type: PlRequestType;
	}>({ category: PlRequestTypeMain.blog, type: PlRequestType.blogIdeas });
	const [currentTool, setCurrentTool] = useState<PlRequestObj>();
	const [currentCategory, setCurrentCategory] = useState<PlCategory>();

	const plNewRequestSubmit = useRef<PlNewRequest>({ type: requestType });

	const [alertBarObject, setAlertBarObject] = useState<AlertBarObject>();

    const [languageModel, setLanguageModel] = useState<string>("gpt-3.5-turbo")

	const [userStatus, setUserStatus] = useState<string>()

	// === CHAT ===
	const now = firebase.firestore.Timestamp.now()

	// const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
	const assistantPersona : Persona = {
        id      : "1",
        userId  : "1",
        title   : "General Assistant",
        content : "",
		created : now,
		updated : now
    }

    const [chatObject, setChatObject] = useState<ChatObject>()
	const [selectedPersona, setSelectedPersona] = useState<Persona>(assistantPersona)

	const [isTyping, setIsTyping] = useState(false)

	const classes = useStyles();
	const intl = useIntl();
	const history = useHistory();

	const db = firebase.firestore();

	const auth = firebase.auth();

	const analytics = firebase.analytics();

	const params = useParams();

	const isLargeScreen = useMediaQuery(`(min-width:600px)`)

	// const [upgradeReason, setUpgradeReason] = useState<string>()
	const upgradeReason = useRef<string>()

	const location = useLocation()

	useEffect(() => {

		console.log("state is:", location.state)

		if(location.state){
			// Got chat from Chats view
			const chat = location.state as Chat
			const chatObject : ChatObject = {
				id	       : chat.id,
				model       : chat.model,
				messages    : chat.messages,
				// personaId?  : string
			}
			if(chat.personaId) chatObject.personaId = chat.personaId 

			setChatObject(chatObject)

		}

		const r = firebase.auth().onAuthStateChanged((currentUser) => {
			if (!currentUser) {
				console.log("No user!!!");
				history.push("/");
			} else {
				setFbUser(currentUser);

				getCustomClaimRole().then(s=>{
					if(s)setUserStatus(s)
				})

				// db.collection("requestTypes").doc("public").get().then(o=>{

				//     const data = o.data()

				//     const reqObj = data && data["requestTypes"] as PlRequestObj[]

				//     if(reqObj){

				//         setPlReqObj(reqObj)

				//         setInitCategories(currentUser, reqObj)
				//     }
				// })

				// initTools(currentUser);

				initUser(currentUser.uid);

				

				// checkAccount(currentUser.uid)
			}
		});
		// console.log(`Use params: ${JSON.stringify(params)}`)

		return () => {
			// Do unmounting stuff here
			r();
		};
	}, []);

	useEffect(()=>{
		if(languageModel === "gpt-3.5-turbo") return
		

		// console.log("check user")
		// 	console.log("user status", userStatus)

		if(!userStatus){
			setOpenUpgradeModal(true)
			setLanguageModel("gpt-3.5-turbo")
			return
		}

		if(userStatus === "premium") return

		if(userStatus === "basic") return

		if(userStatus === "prepaid"){
			//TODO Check if user has enough credits

			return
		}

		console.log("user is not premium or prepaid")
		upgradeReason.current = "gpt4-upgrade"
		setOpenUpgradeModal(true)
		setLanguageModel("gpt-3.5-turbo")
		
		// if(user?.status === "trial" || user?.status === "free"){
		// 	//show payment				
		// 	upgradeReason.current = "gpt4-upgrade"
		// 	setOpenUpgradeModal(true)
		// 	setLanguageModel("gpt-3.5-turbo")
			
		// }
		
	}, [languageModel])

	useEffect(()=>{
		if(openUpgradeModal === false){
			upgradeReason.current = undefined
			console.log("upgradeReason erased")
		}
	}, [openUpgradeModal])

	useEffect(()=>{
		if(chatObject === undefined){
			setSelectedPersona(assistantPersona)
		}

		console.log("Chat object", chatObject)

	}, [chatObject])

	async function initUser(userId: string) {
		const user = await getUser(userId);

		if (!user) return;

		setUser(user);

		if (shouldShowUserIntro(user) === true) {
			setOpenUserNameModal(true);
		}

		if ((await isUserAccountActive(user)) === true) return;
		setActiveAccount(false);

		const isAccountOnTrial = isUserAccountOnTrial(user);
		if (isAccountOnTrial.active === true) {
			setTrialDays(isAccountOnTrial.trialDays ?? 0);
			return;
		}

		// if(isUpgradeUrl(params) === true){
		//     setOpenUpgradeModal(true)
		//     return
		// }

		
		setOpenUpgradeModal(true);
	}

	async function initTools(currentUser: firebase.User) {
		const tools = await getToolsList(db);
		if (tools) {
			setPlReqObj(tools);

			setInitCategories(currentUser, tools);
		}
	}

	// Request View

	function setInitCategories(
		user: firebase.User,
		plReqObjArr: PlRequestObj[]
	) {
		/**
		 * - filter tools not live if not admin
		 * - combine tools under categories
		 * - sort categories
		 * - sort tools
		 */

		let plReqObj = plReqObjArr;

		if (user.email && isOpenaiTestUser(user.email) === false) {
			// Don't show test tools

			plReqObj = plReqObjArr.filter((t) => {
				return typeof t.live === "undefined" || t.live === true;
			});
		}

		const blogTools = plReqObj
			.filter((t) => {
				return t.typeMain === PlRequestTypeMain.blog;
			})
			.sort((a, b) => {
				return a.index - b.index;
			});

		const salesTools = plReqObj
			.filter((t) => {
				return t.typeMain === PlRequestTypeMain.sales;
			})
			.sort((a, b) => a.index - b.index);

		const productTools = plReqObj
			.filter((t) => {
				return t.typeMain === PlRequestTypeMain.product;
			})
			.sort((a, b) => a.index - b.index);

		const musicTools = plReqObj
			.filter((t) => {
				return t.typeMain === PlRequestTypeMain.music;
			})
			.sort((a, b) => a.index - b.index);

		const writingTools = plReqObj
			.filter((t) => {
				return t.typeMain === PlRequestTypeMain.writing;
			})
			.sort((a, b) => a.index - b.index);

		const emailTools = plReqObj
			.filter((t) => {
				return t.typeMain === PlRequestTypeMain.email;
			})
			.sort((a, b) => a.index - b.index);

		const socialTools = plReqObj
			.filter((t) => {
				return t.typeMain === PlRequestTypeMain.socialMedia;
			})
			.sort((a, b) => a.index - b.index);

		const startupTools = plReqObj
			.filter((t) => {
				return t.typeMain === PlRequestTypeMain.startup;
			})
			.sort((a, b) => a.index - b.index);

		const catArr: PlCategory[] = [
			{
				category: PlRequestTypeMain.blog,
				types: blogTools,
			},
			{
				category: PlRequestTypeMain.socialMedia,
				types: socialTools,
			},
			{
				category: PlRequestTypeMain.sales,
				types: salesTools,
			},
			{
				category: PlRequestTypeMain.product,
				types: productTools,
			},
			{
				category: PlRequestTypeMain.email,
				types: emailTools,
			},
			{
				category: PlRequestTypeMain.music,
				types: musicTools,
			},
			{
				category: PlRequestTypeMain.writing,
				types: writingTools,
			},
			{
				category: PlRequestTypeMain.startup,
				types: startupTools,
			},
		];

		setCategories(catArr);
		setCurrentTool(blogTools[0]);
		// console.table(blogTools)
		// console.log("blog tools", blogTools)
		setCurrentCategory(catArr[0]);
	}

	function RequestViewNew() {
		/**
		 * - check if PlRequestObj arr exist
		 * - show user
		 * - on submit send PlNewRequest
		 * - show results
		 */

		const [textFieldIdError, setTextFieldIdError] = useState<string>();

		if (!categories || !currentTool) return null;

		const fields = currentTool.fields;

		async function handleReqSubmit() {
			if (activeAccount === false) {
				setOpenUpgradeModal(true);
				return;
			}

			const checkFields = checkRequiredFields();

			console.log("checkFields ", checkFields);

			if (checkFields.pass === false) {
				console.error("Error field id", checkFields.fieldId);
				if (checkFields.fieldId) setTextFieldIdError(checkFields.fieldId);
				return;
			}

			analytics.logEvent("new_request", {
				type: plNewRequestSubmit.current.type,
			});
			setOpenBackdrop(true);
			// setIsTyping(true)
			// console.log(`plNewRequestSubmit: ${JSON.stringify(plNewRequestSubmit.current)}`);
			const newReqFunc = firebase.functions().httpsCallable("newRequestV2");
			const d = {
				request: plNewRequestSubmit.current,
				model : languageModel,				
			};

			if(selectedPersona && selectedPersona.id !== "1") d["persona"] = selectedPersona

			try {
				const result = await newReqFunc(d);

				if (result.data.done === true && result.data.completion) {
					// setResultsList(result.data.completion as PlResult[]);
					// saveFirstChat(result.data.completion as PlResult[])
					console.log("Completion", result.data.completion)
					const completion = result.data.completion as ChatObject
					const msg = completion.messages.map(m=>{
						m.content = m.content.replace("###", "")
						return m
					})

					completion.messages = msg
					console.log("Chat completion", chatObject)
					setChatObject(completion)
				}

				if (result.data.done === false && result.data.error) {
					if (result.data.error === "rate_limit") {
						const text = result.data.wait
							? `Too many requests. Please try again in ${result.data.wait} seconds`
							: "Too many requests. Please try again later";

						setAlertBarObject({ open: true, type: "error", alertText: text });
					} else {
						setAlertBarObject({
							open: true,
							type: "error",
							alertText: "Error getting results",
						});
					}
				}

				console.log("Request completion saved!");
			} catch (error) {
				setAlertBarObject({
					open: true,
					type: "error",
					alertText: "Error getting results",
				});
				console.error(`Error getting completions: ${error}`);
			}

			setOpenBackdrop(false);
			// setIsTyping(false)
		}

		function checkRequiredFields() {
			// Check plNewRequestSubmit.current with currentTool

			const currentFields = plNewRequestSubmit.current.fields;
			const selectedToolFields = currentTool?.fields;

			if (!selectedToolFields) return { pass: false };

			const requiredIds = selectedToolFields
				.filter((t) => {
					return t.optional === false;
				})
				.map((t) => {
					return t.id;
				});

			if (!currentFields) {
				return { pass: false, fieldId: requiredIds[0] };
			}

			const currentIds = currentFields
				.filter((f) => {
					return f.value.trim() !== "";
				})
				.map((t) => {
					return t.id;
				});

			const missingIds = requiredIds.filter((f) => {
				return currentIds.indexOf(f) === -1;
			});

			console.log("requiredIds: ", requiredIds);
			console.log("currentIds: ", currentIds);
			console.log("missingIds: ", missingIds);

			if (missingIds && missingIds.length > 0) {
				return { pass: false, fieldId: missingIds[0] };
			} else {
				return { pass: true };
			}
		}

		function ReqFields() {
			// const [plNewRequest, setPlNewRequest] = useState<PlNewRequest>({type : requestType})

			function handleSelectChange(
				event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
			) {
				// console.log("setRequestType", event.target.value)
				// console.log("setRequestType name", event.target.name)

				const value = event.target.value;
				const name = event.target.name;

				if (!categories) return;

				if (value === "header") return;

				if (
					name === "requestType" &&
					(value as PlRequestType) !== plNewRequestSubmit.current.type
				) {
					// setPlNewRequest({type : requestType})

					const blogTopic = plNewRequestSubmit.current.fields?.filter(
						(f) => f.id === "blogTopic"
					);
					const tone = plNewRequestSubmit.current.fields?.filter(
						(f) => f.id === "tone"
					);
					const keywords = plNewRequestSubmit.current.fields?.filter(
						(f) => f.id === "keywords"
					);
					const songDescription = plNewRequestSubmit.current.fields?.filter(
						(f) => f.id === "songDescription"
					);

					const songLyrics = plNewRequestSubmit.current.fields?.filter(
						(f) => f.id === "songLyrics"
					);

					plNewRequestSubmit.current = { type: value as PlRequestType };

					if (blogTopic && blogTopic.length > 0) {
						plNewRequestSubmit.current.fields = plNewRequestSubmit.current
							.fields
							? [...plNewRequestSubmit.current.fields, blogTopic[0]]
							: blogTopic;
					}

					if (tone && tone.length > 0) {
						plNewRequestSubmit.current.fields = plNewRequestSubmit.current
							.fields
							? [...plNewRequestSubmit.current.fields, tone[0]]
							: tone;
					}

					if (keywords && keywords.length > 0) {
						plNewRequestSubmit.current.fields = plNewRequestSubmit.current
							.fields
							? [...plNewRequestSubmit.current.fields, keywords[0]]
							: keywords;
					}

					if (songDescription && songDescription.length > 0) {
						plNewRequestSubmit.current.fields = plNewRequestSubmit.current
							.fields
							? [...plNewRequestSubmit.current.fields, songDescription[0]]
							: songDescription;
					}

					if (songLyrics && songLyrics.length > 0) {
						plNewRequestSubmit.current.fields = plNewRequestSubmit.current
							.fields
							? [...plNewRequestSubmit.current.fields, songLyrics[0]]
							: songLyrics;
					}

					const categoryType = currentCategory?.types.filter((t) => {
						return t.type === value;
					})[0];

					setCurrentTool(categoryType);

					setRequestType(value as PlRequestType);
					return;
				}

				if (event.target.name === "category") {
					const currentCategory = categories.filter((c) => {
						return c.category === value;
					})[0];

					const categoryType = currentCategory.types[0];

					// setNewRequestType({category: value as PlRequestTypeMain , type : categoryType?.type ??  PlRequestType.blogParagraph })

					plNewRequestSubmit.current = { type: categoryType.type };

					// console.table(plNewRequestSubmit.current)
					setCategoryType(value as PlRequestTypeMain);

					setCurrentCategory(currentCategory);
					setCurrentTool(categoryType);

					setRequestType(categoryType.type);
				}
			}

			function ReqField({
				field,
				errorId,
			}: {
				field: PlRequestObjField;
				errorId?: string;
			}) {
				const [plNewRequest, setPlNewRequest] = useState<PlNewRequest>({
					type: requestType,
				});

				const [fieldErrorId, setFieldErrorId] = useState(errorId);

				function handleNewTextField(
					event: React.ChangeEvent<HTMLInputElement>
				) {
					const id = event.currentTarget.id;
					const value = event.currentTarget.value;

					if (fieldErrorId) setFieldErrorId(undefined);

					console.log(
						`old Fields: ${JSON.stringify(plNewRequestSubmit.current.fields)}`
					);

					if (typeof plNewRequestSubmit.current.fields === "undefined") {
						plNewRequestSubmit.current = {
							type: requestType,
							fields: [{ id: id, value: value }],
						};

						setPlNewRequest({
							type: requestType,
							fields: [{ id: id, value: value }],
						});

						console.log(
							`handleNewTextField: ${JSON.stringify(
								plNewRequestSubmit.current
							)}`
						);
						return;
					}

					// const currentField = plNewRequest.fields.filter(f=>f.id === id)[0]

					const currentFields = plNewRequestSubmit.current.fields.filter(
						(f) => f.id !== id
					);

					console.log(`currentFields: ${JSON.stringify(currentFields)}`);

					plNewRequestSubmit.current = {
						type: requestType,
						fields: [...currentFields, { id: id, value: value }],
					};
					setPlNewRequest({
						type: requestType,
						fields: [...currentFields, { id: id, value: value }],
					});

					console.log(
						`handleNewTextField: ${JSON.stringify(plNewRequestSubmit.current)}`
					);
				}

				if (field.type === "text" || field.type === "textfield") {
					return (
						<>
							<Grid item xs={12} style={{ marginTop: "20px" }}>
								<Typography className={classes.reqViewSubTitle}>
									{field.title}

									{field.optional && (
										<span style={{ fontSize: "1rem", fontWeight: "lighter" }}>
											&nbsp; (Optional)
										</span>
									)}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<form noValidate autoComplete="off" key={field.id}>
									<div>
										<TextField
											id={field.id}
											key={field.id}
											error={fieldErrorId === field.id}
											// label="Title"
											// defaultValue="Default Value"
											helperText={
												field.maxChar &&
												`${
													field.maxChar -
													(plNewRequest.fields?.filter((f2) => {
														return f2.id === field.id;
													})[0]?.value.length ?? 0)
												} Characters`
											}
											variant="outlined"
											onChange={handleNewTextField}
											placeholder={field.placeholder}
											fullWidth
											maxRows={10}
											multiline={field.type === "textfield" ? true : false}
											FormHelperTextProps={{
												className: classes.reqViewHelperText,
											}}
											value={
												plNewRequestSubmit.current.fields?.filter(
													(f2) => f2.id === field.id
												)[0]?.value ?? ""
											}
										/>
									</div>
								</form>
							</Grid>
						</>
					);
				}

				if (field.type === "select") {
					//TODO
				}

				return null;
			}

			function Categories() {
				return (
					<>
						{/* <Grid item xs={4} >
                        <Typography className={classes.reqViewTitle} >
                            {intl.formatMessage({id: `home.request.title.categories`})}
                        </Typography>
                    </Grid> */}

						<Grid item xs={5}>
							<FormControl variant="outlined" fullWidth={true}>
								{/* <InputLabel id="demo-simple-select-outlined-label"> </InputLabel> */}
								<Select
									style={{ color: "#28A745" }}
									name="category"
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={categoryType}
									onChange={handleSelectChange}
									// label="Select a tool"
								>
									{/* <MenuItem value="">
                                <em>None</em>
                            </MenuItem> */}

									{categories?.map((t) => {
										return (
											<MenuItem value={t.category}>
												{intl.formatMessage({
													id: `home.request.title.${t.category}`,
												})}
											</MenuItem>
										);
									})}

									{/* {
                                reqTypeList.map(t=>{
                                    return(
                                        <MenuItem value={t} >{intl.formatMessage({id: `home.request.title.${t}`})}</MenuItem>
                                    )
                                })

                            } */}
								</Select>
							</FormControl>
						</Grid>
					</>
				);
			}

			return (
				<Grid container justifyContent="space-between">
					<Categories />

					{/* <Grid item xs={12} >
                        <Typography className={classes.reqViewTitle} >
                            {intl.formatMessage({id: `home.request.title.tools`})}
                        </Typography>
                    </Grid> */}

					<Grid item xs={6}>
						<FormControl variant="outlined" fullWidth={true}>
							{/* <InputLabel id="demo-simple-select-outlined-label"> </InputLabel> */}
							<Select
								style={{ color: "#28A745" }}
								name="requestType"
								labelId="demo-simple-select-outlined-label"
								id="demo-simple-select-outlined"
								value={requestType}
								onChange={handleSelectChange}
								// label="Select a tool"
							>
								{currentCategory?.types.map((t) => {
									return (
										<MenuItem value={t.type}>
											{intl.formatMessage({
												id: `home.request.title.${t.type}`,
											})}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>

					{currentTool?.fields.map((f) => {
						// console.table(f)
						return <ReqField field={f} errorId={textFieldIdError} />;
					})}
				</Grid>
			);
		}

		// function saveFirstChat(result : PlResult[]){

		// 	let personaContent = ""
		// 	if(selectedPersona){
				
		// 		if(selectedPersona.name){
		// 			personaContent = `Your name is ${selectedPersona.name}. ` + selectedPersona.content
		// 		}else{
		// 			personaContent = selectedPersona.content
		// 		}
		// 	}

		// 	const response = result[0].result

		// 	const messages = (selectedPersona && selectedPersona.id !== "1" ) ? [{role : "system", content : personaContent}, msg] : [msg]

		// 	const newChatObject : ChatObject = 
		// 		{
		// 			model : langaugeModel,
		// 			messages : messages
		// 		}
		// 	if(!chatObject && selectedPersona && selectedPersona.id !== "1") newChatObject.personaId = selectedPersona.id

		// 	setChatObject(newChatObject)
			

		// }

		return (

			<Card variant="outlined">
				<CardContent>
					<Grid
						container
						direction="column"
						justifyContent="space-between"
						alignItems="center"
						xs={12}
					>
						<Grid container item alignItems="flex-start">
						<Grid item xs={12} style={{marginBottom : "10px"}}>
							<Typography className={classes.reqViewTitle}>Prompt</Typography>
						</Grid>
						</Grid>
						{/* <ReqViewSelect/>
                {requestType === PlRequestType.productDescription && <ReqViewProductDescription/>}
                {requestType === PlRequestType.songLyrics && <ReqViewSongLyrics/>} */}

						<ReqFields />

						<Grid item>
							<Button
								onClick={handleReqSubmit}
								color="primary"
								className={classes.reqViewButton}
							>
								Generate
							</Button>
						</Grid>

						<Grid item>
							<FeedbackButton />
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	}

    function ChatSetupView(){

        const models = ["gpt-4", "gpt-3.5-turbo"]

        function handleSelectModelChange(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
			) {
				
				const value = event.target.value as string;
				const name = event.target.name;

                setLanguageModel(value)
        }

		function LanguageModel() {
			return (
				<>
					<Grid container item xs={12} md={4}  >
						<Grid item xs={12}>
							<Typography className={classes.reqViewTitle}>Model</Typography>
						</Grid>
					
						<Grid item xs={12}>
							<FormControl variant="outlined" fullWidth={true}>
								{/* <InputLabel id="demo-simple-select-outlined-label"> </InputLabel> */}
								<Select
									style={{ color: "#28A745" }}
									name="category"
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={languageModel}
									onChange={handleSelectModelChange}
									// label="Select a tool"
								>
									<MenuItem value={"gpt-3.5-turbo"}>GPT 3.5</MenuItem>

									<MenuItem value={"gpt-4"}>GPT 4</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</>
			);
		}

		

        return (
			
			<Grid container item xs={12} spacing={1} >
			
				<LanguageModel/>


				<PersonaView
					userId={user?.userId}
					selectedPersona={selectedPersona}
					setSelectedPersona={setSelectedPersona}
					db={db}
				/>
			
			</Grid>
			
		);
    }

	function alertBarDone() {
		setAlertBarObject({ open: false, type: "error", alertText: "" });
	}

	function goTo(location: string) {
		if (location === "upgrade" && user) {
			// launchStripe()
			setOpenUpgradeModal(true);
			return;
		}

		if (location === "editLanguage" && user) {
			setOpenLanguageModal(true);
			console.log("Edit language");
		}
	}

    async function sendNewChat(chat:string, persona? : Persona){

        // console.log("New Chat", chat)

        const msg : ChatMessage = {
            role    :  "user" ,
            content : chat
        }

		let personaContent = ""
		if(persona){
			
			if(persona.name){
				personaContent = `Your name is ${persona.name}. ` + persona.content
			}else{
				personaContent = persona.content
			}

			if(persona.context){
				personaContent = personaContent + "\nHere is the previous context:\n" + persona.context
			}
		}

		const messages = (persona && persona.id !== "1" ) ? [{role : "system", content : personaContent}, msg] : [msg]

        const newChatObject : ChatObject = chatObject ?
			{...chatObject, messages : [...chatObject.messages, msg]} 
			:
			{
				model : languageModel,
				messages : messages
			}
		if(!chatObject && selectedPersona && selectedPersona.id !== "1") newChatObject.personaId = selectedPersona.id

		setChatObject(newChatObject)

        // setOpenBackdrop(true)
		setIsTyping(true)

        // console.log("newChatObject",newChatObject)

        const result = await SendChat(newChatObject)

        // setOpenBackdrop(false)
		setIsTyping(false)
        if(result.chat){
            setChatObject(result.chat)
            return
        }

        if(result.error){
            setAlertBarObject({open : true, type : "error", alertText:result.error})
        }
    }

	function StartView(){


		return (
			<Grid id="start-view" container item xs={12} justifyContent="center" spacing={2}>
				<Grid container item xs={12} justifyContent="center">
					<Grid item xs={12} sm={6}>
						<Card variant="outlined">
							<CardContent>
								<Grid
									container
									item
									xs={12}
									// direction="column"
									justifyContent="space-between"
									alignItems="flex-start"
									spacing={1}
								>
									<ChatSetupView />
									
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

				{/* <Grid container item xs={12} justifyContent="center">
					<Grid item xs={12} sm={6}>

					{plReqObj && <RequestViewNew />}
					</Grid>
				</Grid> */}

				<Grid container item xs={12} justifyContent="center">
					<Grid item xs={12} sm={6}>

					<ResultsViewEmptyState/>
					</Grid>
				</Grid>


			</Grid>
		);
	}

	function TrialDaysButton(){

		function handleClick(){

			goTo("upgrade")

		}
		
		if(isLargeScreen === true || activeAccount === true || !trialDays || trialDays < 0) return null

		
	
		return(
			<Grid container item justifyContent="center">
				<Grid item>
					<Button id="upgrade" className={classes.upgradeButton} onClick={handleClick}>Trial Days: {trialDays}</Button>
				</Grid>
			</Grid>
		)
		  
	  }


	return (
		<Container>
			<SimpleBackdrop openBackDrop={openBackdrop} />
			<Menubar
				currentUser={fbUser}
				trialDays={trialDays}
				activeAccount={activeAccount}
				goTo={goTo}
				location="home"
				language={user?.language?.from ?? "en"}
				setChatObject={setChatObject}
				chatObject={chatObject}
			/>
			
			<UpgradeModal
				db={db}
				user={user}
				openUpgradeModal={openUpgradeModal}
				setOpenUpgradeModal={setOpenUpgradeModal}
				reason={upgradeReason.current}
				setOpenBackdrop={setOpenBackdrop}
			/>
			<UserNameModal
				db={db}
				user={user}
				openUserNameModal={openUserNameModal}
				setOpenUserNameModal={setOpenUserNameModal}
			/>
			<LanguageModal
				user={user}
				openLanguageModal={openLanguageModal}
				setUser={setUser}
				setOpenLanguageModal={setOpenLanguageModal}
				setAlertBarObject={setAlertBarObject}
				setOpenBackdrop={setOpenBackdrop}
			/>

			
			{alertBarObject && (
				<AlertBar alertBarDone={alertBarDone} alertBarObject={alertBarObject} />
			)}

			{/* <Grid container id="main-container" item justifyContent="center"> */}
				{/* <Grid container justifyContent="flex-end" alignItems="center">
				
            <Grid item >
				<NewChatButton setChatObject={setChatObject}/>
			</Grid>
			</Grid> */}

			<TrialDaysButton/>
				

				<Grid
					id="Container-results-start"
					container
					item
					style={{ marginTop: "20px", height: "85vh", alignContent:"space-between" }}
					// xs={8}
					// direction="column"
					// sm={8}
					// direction="column"
					justifyContent="space-between"
					// alignItems="center"
				>

					
                    
					{/* <ResultsView chatMessages={chatObject?.messages} /> */}
					<Grid container item>
					{
						chatObject ?
						<ResultsViewWithResults chatMessages={chatObject.messages.filter(c => c.role !== "system")} isTyping={isTyping} /> : 
						<StartView/>

						
					}
					</Grid>

					<Grid
						container
						item
						xs={12}
						alignItems="flex-end"
						justifyContent="center"
						style={{ maxWidth: "100%", height:"fit-content" }}
					>
						<Grid container item xs={12}  justifyContent="center" alignItems="center">
							<ComposeView sendChat={sendNewChat} persona={selectedPersona} isTyping={isTyping} />
						</Grid>
					</Grid>
				</Grid>
			{/* </Grid> */}
		</Container>
	);
}
