import React, { useEffect, useState } from "react";
import { useHistory, RouteComponentProps } from "react-router-dom";
import {
	Switch,
	makeStyles,
	Typography,
	Button,
	Grid,
	Container,
	Paper, Link
} from "@material-ui/core";
import * as firebase from "firebase";
import { FormattedMessage } from "react-intl";

import automate from "../../assets/automate.png";
import heroImage from "../../assets/hero-image.png";
import ButtonAppBar from "../../Components/Menubar";
// import addCrispChat from "../Helpers/addCrispChat";
import { PRICE_INFO } from '../../Helpers/priceInfo';
import Faq from "./faq";
import AzoTag from "./AzoTag";

const useStyles = makeStyles((theme) => ({
	modal: {
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "center",
		overflow: "scroll",
		fullWidth: "true",
		maxWidth: "sm",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		// fullWidth: "true",
		// maxWidth :'lg',
	},
	heroTitle: {
		color: "#3E4348",
		fontFamily: "Roboto, sans-serif",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "60px",
	},

	heroSubtitle: {
		fontFamily: "Roboto, sans-serif",
		// font-style: normal;
		fontWeight: 500,
		fontSize: "24px",
		// line-height: 28px;
		// text-align: center;

		color: "#7F8893",
	},
	benefitsTitle: {
		color: "#3E4348",
		fontFamily: "Roboto, sans-serif",
		fontWeight: 500,
		fontSize: "40px",
	},

	benefitsSubtitle: {
		fontFamily: "Roboto, sans-serif",
		fontWeight: 500,
		fontSize: "30px",
		color: "#3E4348",
	},
	benefitsText: {
		fontFamily: "Roboto, sans-serif",
		// font-style: normal;
		fontWeight: 500,
		fontSize: "20px",
		// line-height: 28px;
		// text-align: center;

		color: "#7F8893",
	},
	priceTermText: {
		fontFamily: "Roboto, sans-serif",
		// font-style: normal;
		fontWeight: 500,
		fontSize: "18px",
		// line-height: 28px;
		// text-align: center;

		color: "#7F8893",
	},
	priceTermTextBlack: {
		fontFamily: "Roboto, sans-serif",
		// font-style: normal;
		fontWeight: 500,
		fontSize: "18px",
		// line-height: 28px;
		// text-align: center;

		color: "#3E4348",
	},
	faqTitle: {
		color: "#3E4348",
		fontFamily: "Roboto, sans-serif",
		fontWeight: 500,
		fontSize: "24px",
	},
	faqText: {
		fontFamily: "Roboto, sans-serif",
		// font-style: normal;
		fontWeight: 500,
		fontSize: "24px",
		// line-height: 28px;
		// text-align: center;

		color: "#7F8893",
	},
	priceAmount: {
		color: "#3E4348",
		fontFamily: "Roboto, sans-serif",
		fontWeight: 500,
		fontSize: "30px",
	},
	priceText: {
		fontFamily: "Roboto, sans-serif",
		// font-style: normal;
		fontWeight: 500,
		fontSize: "18px",
		// line-height: 28px;
		// text-align: center;

		color: "#7F8893",
	},

	switch: {
		"& .MuiSwitch-switchBase.Mui-checked": {
			color: "#DAE5DD",
			// backgroundColor: "#28A745",
			"&:hover": {
				backgroundColor: "#28A745",
			},
		},
		"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
			backgroundColor: "#28A745",
			color: "#28A745",
		},
	},
}));

export default function Landing({ match }: RouteComponentProps) {
	const [open, setOpen] = React.useState(false);

	const [openSignin, setOpenSignin] = useState(false);
	const [firebaseAuthOpen, setFirebaseAuthOpen] = useState(false);

	const auth = firebase.default.auth();

	const classes = useStyles();

	const history = useHistory();

	const analytics = firebase.default.analytics();

	useEffect(() => {
		// analytics.logEvent("signup", {userId:"100"});

		// addCrispChat();
		// SignupForm()
		// console.log(`New landing page!!!`)
		// alert(`New landing page`)

		//TODO: Test for public data query
		// getUserDataTest()

		// handleSignInLink()
		// const r = firebase.default.auth().onAuthStateChanged(currentUser => {
		//     if (currentUser){
		//         console.log("Got user!!!")
		//         history.push("/home")
		//     }else{
		//         console.log("No user!!!")
		//     }
		// });

		// return () => {
		//     // Do unmounting stuff here
		//     r()

		// };
	}, []);

	async function handleSignInLink() {
		if (auth.isSignInWithEmailLink(window.location.href)) {
			let email = window.localStorage.getItem("emailForSignIn");
			if (!email) {
				// User opened the link on a different device. To prevent session fixation
				// attacks, ask the user to provide the associated email again. For example:
				email = window.prompt("Please provide your email for confirmation");
				return;
			}

			try {
				const authResult = await auth.signInWithEmailLink(
					email,
					window.location.href
				);
				if (authResult.user) {
					console.error(`Email signinin success!!!`);
					history.push("/home");
				} else {
					console.error(`Error signinin`);
				}
			} catch (error) {
				console.error(`Error signinin: ${error}`);
			}
		}
	}

	const handleClose = () => {
		setOpen(false);
	};

	function closeSignIn(open: boolean) {
		if (open == false) {
			setOpenSignin(false);
		}
	}


	function Hero() {
		return (
			<Grid container item justify="space-around" spacing={3}>
				<Grid
					container
					item
					xs={12}
					sm={6}
					justify="center"
					alignContent="center"
				>
					<Typography variant="h1" component="h2" className={classes.heroTitle}>
						{/* Custom automation  for your workflows  	 */}
						Awesome creative team powered by AI
						{/* Generate punchline for your marketing copy by AI							 */}
					</Typography>

					<Grid xs={12} sm={10}>
						<Typography
							variant="body1"
							className={classes.heroSubtitle}
							style={{ marginTop: "30px", marginBottom: "30px" }}
						>
							Build an AI team in seconds to help with your goals and boost your productivity
						
							
						</Typography>
						<StartNowButton />
					</Grid>

					{/* <div id="form"/> */}

					{/* <Typography variant="body1" className={classes.heroSubtitle}>

                        to automate your complex workflows

                        </Typography> */}
				</Grid>

				<Grid
					container
					item
					xs={12}
					sm={4}
					justify="center"
					alignContent="center"
				>
					<img
						src={heroImage}
						style={{ marginTop: "20px", marginBottom: "20px" }}
						// style={{ width: "75%", height: "auto" }}
						// onClick={() => handleClose()}
					/>
				</Grid>
			</Grid>
		);
	}

	function Benefits() {
		return (
			<Grid
				container
				spacing={1}
				style={{
					marginTop: "20px",
					backgroundColor: "#F6F6F6",
					borderRadius: "10px",
				}}
			>
				<Grid xs={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
					<Typography
						variant="h2"
						component="h2"
						className={classes.benefitsTitle}
					>
						Benefits
					</Typography>
				</Grid>

				<Grid
					xs={12}
					sm={4}
					style={{ marginTop: "20px", marginBottom: "20px" }}
				>
					<Typography variant="h3" className={classes.benefitsSubtitle}>
						Custom
					</Typography>

					<Typography variant="body1" className={classes.benefitsText}>
						We build a custom solution to perfectly fit your business case
					</Typography>
				</Grid>

				<Grid
					xs={12}
					sm={4}
					style={{ marginTop: "20px", marginBottom: "20px" }}
				>
					<Typography variant="h3" className={classes.benefitsSubtitle}>
						Flexible
					</Typography>

					<Typography variant="body1" className={classes.benefitsText}>
						We can use the whole API of the apps you use rather than specific
						use cases
					</Typography>
				</Grid>

				<Grid
					xs={12}
					sm={4}
					style={{ marginTop: "20px", marginBottom: "20px" }}
				>
					<Typography variant="h3" className={classes.benefitsSubtitle}>
						Fast
					</Typography>

					<Typography variant="body1" className={classes.benefitsText}>
						Our experienced developers build your custom solution very fast
					</Typography>
				</Grid>

				<Grid
					xs={12}
					sm={4}
					style={{ marginTop: "20px", marginBottom: "20px" }}
				>
					<Typography variant="h3" className={classes.benefitsSubtitle}>
						Scalable
					</Typography>

					<Typography variant="body1" className={classes.benefitsText}>
						We run on Firebase that scales with your business needs
					</Typography>
				</Grid>

				<Grid
					xs={12}
					sm={4}
					style={{ marginTop: "20px", marginBottom: "20px" }}
				>
					<Typography variant="h3" className={classes.benefitsSubtitle}>
						Fixed Cost
					</Typography>

					<Typography variant="body1" className={classes.benefitsText}>
						There are no high developer costs and no suprises, you always know
						what you will be paying.
					</Typography>
				</Grid>

				<Grid
					xs={12}
					sm={4}
					style={{ marginTop: "20px", marginBottom: "20px" }}
				>
					<Typography variant="h3" className={classes.benefitsSubtitle}>
						Secure
					</Typography>

					<Typography variant="body1" className={classes.benefitsText}>
						Every customer has their own project with secure encrypted database
					</Typography>
				</Grid>
			</Grid>
		);
	}

	function Examples() {
		return (
			<Grid container style={{ marginTop: "30px", marginBottom: "30px" }}>
				<Grid xs={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
					<Typography
						variant="h2"
						component="h2"
						className={classes.benefitsTitle}
					>
						Examples
					</Typography>
				</Grid>

				<Grid
					xs={12}
					sm={4}
					style={{ marginTop: "20px", marginBottom: "20px" }}
				>
					{/* <Typography variant="h3"  className={classes.benefitsSubtitle}>
                        Airtable							
                    </Typography> */}

					{/* <img
							src={airtable}
                            style={{ marginTop:"20px", marginBottom:"20px" }}
							// style={{ width: "75%", height: "auto" }}
							// onClick={() => handleClose()}
						/> */}

					<Typography variant="body1" className={classes.benefitsText}>
						Backup my Airtable to Firebase everyday
					</Typography>
				</Grid>

				<Grid
					xs={12}
					sm={4}
					style={{ marginTop: "20px", marginBottom: "20px" }}
				>
					{/* <Typography variant="h3"  className={classes.benefitsSubtitle}>
                        Airtable							
                    </Typography> */}
					{/* <img
							src={shopify}
                            style={{ marginTop:"20px", marginBottom:"20px" }}
							// style={{ width: "75%", height: "auto" }}
							// onClick={() => handleClose()}
						/> */}

					<Typography variant="body1" className={classes.benefitsText}>
						Update inventory from Shopify to Airtable
					</Typography>
				</Grid>

				<Grid
					xs={12}
					sm={4}
					style={{ marginTop: "20px", marginBottom: "20px" }}
				>
					{/* <Typography variant="h3"  className={classes.benefitsSubtitle}>
                        Airtable							
                    </Typography> */}

					{/* <img
							src={zoom}
                            style={{ marginTop:"20px", marginBottom:"20px" }}
							// style={{ width: "75%", height: "auto" }}
							// onClick={() => handleClose()}
						/> */}

					<Typography variant="body1" className={classes.benefitsText}>
						Save Zoom attendees to Airtable
					</Typography>
				</Grid>
			</Grid>
		);
	}

	function HowItWorks() {

		

		return (
			<Grid
				container
				style={{
					marginTop: "10px",
					backgroundColor: "#F6F6F6",
					borderRadius: "10px",
				}}
			>
				<Grid xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
					<Typography
						variant="h2"
						component="h2"
						className={classes.benefitsTitle}
					>
						How it works
					</Typography>
				</Grid>

				<Grid container item direction="column" xs={12} sm={6}>
					<Grid container justifyContent="center">
						<Grid item xs={12} sm={8}>
							<Typography variant="h3" className={classes.benefitsSubtitle}>
								Create a Persona
							</Typography>

							<Typography variant="body1" className={classes.benefitsText}>
								Describe your persona as detailed as you can such as tone of voice, background, expertise and how it will help you.
							</Typography>
						</Grid>
					</Grid>

					<Grid
						container
						justifyContent="center"
						style={{ marginTop: "20px", marginBottom: "20px" }}
					>
						<Grid item xs={12} sm={8}>
							<Typography variant="h3" className={classes.benefitsSubtitle}>
								Chat
							</Typography>

							<Typography variant="body1" className={classes.benefitsText}>
								Chat with your persona to create a new marketing copy, build strategy or help with your personal goals
							</Typography>
						</Grid>
					</Grid>

					<Grid container justifyContent="center" style={{ marginBottom: "20px" }}>
						<Grid item xs={12} sm={8}>
							<Typography variant="h3" className={classes.benefitsSubtitle}>
								Memory
							</Typography>

							<Typography variant="body1" className={classes.benefitsText}>
								Your persona has a memory of it's own details and your previous chats. So you don't need to explain everything every time
							</Typography>
						</Grid>
					</Grid>

					<Grid container justifyContent="center" style={{ marginBottom: "20px" }}>
						<Grid item xs={12} sm={8}>
							<Typography variant="h3" className={classes.benefitsSubtitle}>
								Multi language
							</Typography>

							<Typography variant="body1" className={classes.benefitsText}>
								Select from different languages for input and output. Works in
								French, German, Italian, Spanish and Turkish
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					container
					direction="column"
					xs={12}
					sm={6}
					justify="center"
					alignContent="center"
				>
					<img
						src={automate}
						style={{ width: "fit-content" }}
						// style={{ width: "75%", height: "auto" }}
						// onClick={() => handleClose()}
					/>
				</Grid>
			</Grid>
		);
	}

	function Price() {
		const [yearPrice, setYearPrice] = useState(true);

		const pricePerYear = PRICE_INFO.proYearPrice;
		const pricePerMo = PRICE_INFO.proMonthPrice;

		const basicPricePerYear = PRICE_INFO.basicYearPrice;
		const basicPricePerMo = PRICE_INFO.basicMonthPrice;

		const prepaidPrice = PRICE_INFO.prepaidPrice

		return (
			<Grid container justify="center" spacing={3}>
				<Grid item xs={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
					<Typography
						variant="h2"
						component="h2"
						className={classes.benefitsTitle}
					>
						Price
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="body1" className={classes.benefitsText}>
						7 day risk free trial
					</Typography>
				</Grid>

				<Grid
					container
					item
					xs={12}
					justifyContent="center"
					alignItems="center"
				>
					<Grid item xs={5} style={{ textAlign: "right" }}>
						<Typography
							variant="body1"
							className={
								yearPrice ? classes.priceTermText : classes.priceTermTextBlack
							}
						>
							Billed Monthly
						</Typography>
					</Grid>

					<Grid item xs={2} md={1}>
						<Switch
							className={classes.switch}
							// style={{color:"#28A745", backgroundColor:"#E9F6EC"}}
							checked={yearPrice}
							onChange={() => {
								setYearPrice(!yearPrice);
							}}
							inputProps={{ "aria-label": "controlled" }}
						/>
					</Grid>

					<Grid item xs={5} style={{ textAlign: "left" }}>
						<Typography
							variant="body1"
							className={
								yearPrice ? classes.priceTermTextBlack : classes.priceTermText
							}
						>
							Billed Annualy
						</Typography>
					</Grid>
				</Grid>

				{/* <MusicPrice yearPrice={yearPrice} /> */}

				{/* Pay as you go */}				
				<Grid item xs={12} md={4}>
					<Paper>
						<Grid
							container
							direction="column"
							justify="space-between"
							alignContent="center"
							style={{ height: "350px" }}
						>
							<Grid item>
								<Typography
									variant="h2"
									component="h2"
									className={classes.benefitsSubtitle}
								>
									Pay as you Go
								</Typography>

								<Typography variant="body1" className={classes.priceAmount}>
									{`$${prepaidPrice}`}
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
								1,000,000 Credits
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
								1,000,000 words GPT-3.5 content generation	
								</Typography>
								<Typography variant="body1" className={classes.priceText}>
								or
								</Typography>
								<Typography variant="body1" className={classes.priceText}>
								50,000 words GPT-4 content generation	
								</Typography>
								<Typography variant="body1" className={classes.priceText}>
									5 Personas
								</Typography>
								<Typography variant="body1" className={classes.priceText}>
									One time payment, no subscription
								</Typography>

								

								{/* <Typography variant="body1" className={classes.priceText}>
									Same-day email support
								</Typography> */}
								{/* <Typography variant="body1" className={classes.priceText}>
									Credits can be used for 2 years
								</Typography> */}
							</Grid>

							<Grid item>
								<StartNowButton />
							</Grid>
						</Grid>
					</Paper>
				</Grid>

				{/* Basic */}
				{/* <BasicPrice yearPrice={yearPrice} /> */}
				<Grid item xs={12} md={4}>
					<Paper>
						<Grid
							container
							direction="column"
							justify="space-between"
							alignContent="center"
							style={{ height: "350px" }}
						>
							<Grid item>
								<Typography
									variant="h2"
									component="h2"
									className={classes.benefitsSubtitle}
								>
									Basic
								</Typography>

								<Typography variant="body1" className={classes.priceAmount}>
									{yearPrice ? `$${basicPricePerYear}/mo` : `$${basicPricePerMo}/mo`}
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
								2,000,000 Credits per month
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
								2,000,000 words GPT-3.5 content generation	
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
								or
								</Typography>
								<Typography variant="body1" className={classes.priceText}>
								100,000 words GPT-4 content generation	
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
									10 Personas
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
									Same-day email support
								</Typography>
							</Grid>

							<Grid item>
								<StartNowButton />
							</Grid>
						</Grid>
					</Paper>
				</Grid>

				{/* Pro */}
				<Grid item xs={12} md={4}>
					<Paper>
						<Grid
							container
							direction="column"
							justify="space-between"
							alignContent="center"
							style={{ height: "350px" }}
						>
							<Grid item>
								<Typography
									variant="h2"
									component="h2"
									className={classes.benefitsSubtitle}
								>
									Pro
								</Typography>

								<Typography variant="body1" className={classes.priceAmount}>
									{yearPrice ? `$${pricePerYear}/mo` : `$${pricePerMo}/mo`}
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
								4,000,000 Credits per month
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
								4,000,000 words GPT-3.5 content generation	
								</Typography>
								<Typography variant="body1" className={classes.priceText}>
								or
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
								200,000 words GPT-4 content generation	
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
									Unlimited Personas
								</Typography>

								<Typography variant="body1" className={classes.priceText}>
									Same-day email support
								</Typography>
							</Grid>

							<Grid item>
								<StartNowButton />
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		);
	}

	function StartNowButton() {
		return (
			<Button
				variant="contained"
				color="primary"
				// onClick={startButtonClicked}
				style={{
					margin: "1.5rem",
					width: "17.5rem",
					height: "3rem",
					fontFamily: "Roboto, sans-serif",
					fontStyle: "normal",
					fontWeight: 500,
					fontSize: "1.25rem",
					lineHeight: "1.25rem",
					backgroundColor: "#0062FF",
				}}
				onClick={() => {
					history.push("/signin");
				}}
			>
				Start Now
			</Button>
		);
	}

	function handleFirebaseAuthClose() {
		setFirebaseAuthOpen(false);
		if (closeSignIn) {
			closeSignIn(false);
		}
	}

	function openSignIn() {
		setFirebaseAuthOpen(true);
	}

	function ContactUs() {
		return (
			<Grid container className="headline" style={{ marginBottom: "6.25rem" }}>
				<Grid item justify="center" xs={12}>
					<h2>
						<FormattedMessage id="landing.contact.title" />
					</h2>
				</Grid>
				<Grid item justify="center" xs={12} className="subtitle">
					<p>
						<FormattedMessage
							id="landing.contact.text"
							values={{
								a: (chunks) => <a href="mailto:hello@punchline.ai">{chunks}</a>,
							}}
						/>
					</p>
				</Grid>
			</Grid>
		);
	}

	function Footer() {
		return (

			<Grid container
			item
			className="headline"
			spacing={3}
			direction="row"
			xs={12}
			justifyContent="space-between"
			style={{
				backgroundColor: "#F9F9FF",
				marginTop: "1rem",
				marginBottom: "1rem",
				textAlign: "end",
				padding:"50px"
			}}>

				<Grid item  >
                        <Grid container item justifyContent={"center"} >
                        <Grid item xs={12}><Typography variant="h6" style={{fontFamily: "Roboto, sans-serif",
							color: "#92929D",
							textDecoration: "none",
							fontWeight: 500,}}>Other Projects</Typography></Grid>

                            
                                
                                    
						<Grid item xs={12}>
							<Link href={"https://playlistcover.com"} style={{fontFamily: "Roboto, sans-serif",
							color: "#92929D",
							textDecoration: "none",
							fontWeight: 500,}} >PlaylistCover.com</Link>
						</Grid>
                                       
                                
                            

                        </Grid>
                       
                    </Grid>


				<Grid item	>
					{/* <Grid item xs={12}  justify="flex-end" > */}
					<Grid item xs={12}>
						<a
							target="_blank"
							href="https://azizakgul.notion.site/Terms-and-Conditions-of-use-f00f2fa5255e4450b08f120600bb5afc"
							style={{
								fontFamily: "Roboto, sans-serif",
								color: "#92929D",
								textDecoration: "none",
								fontWeight: 500,
							}}
						>
							<FormattedMessage id="landing.terms.button.title" />
						</a>
					</Grid>

					<Grid item xs={12}>
						<a
							target="_blank"
							href="https://azizakgul.notion.site/Privacy-ed28153658c1456f9698077a2e278a55"
							style={{
								fontFamily: "Roboto, sans-serif",
								color: "#92929D",
								textDecoration: "none",
								fontWeight: 500,
							}}
						>
							<FormattedMessage id="landing.privacy.button.title" />
						</a>
						{/* <Button
								
									onClick={()=>{window.open("https://www.notion.so/azizakgul/Terms-and-Conditions-of-use-5c6e82394cff4e3c9c2247d790764a0f") }}
									style={{
									
										fontFamily: "Roboto, sans-serif",
										color: "#92929D"
										
									}}
								>
								<FormattedMessage id="landing.terms.button.title" />
								</Button> */}

						{/* <Button
									style={{
										
										fontFamily: "Roboto, sans-serif",
										color: "#92929D"
										
									}}
								
									onClick={()=>{window.open("https://www.notion.so/azizakgul/Privacy-1570abcf2c90492cb023e1277d034c89", "_blank") }}
								
								>
								<FormattedMessage id="landing.privacy.button.title" />
								</Button> */}
					</Grid>
				</Grid>


			</Grid>

			
		);
	}

	return (
		<div>
			<ButtonAppBar openSignin={openSignIn} closeSignIn={closeSignIn} />
			{/* <FirebaseUISignin/> */}
			<Container style={{ textAlign: "center", marginTop: "30px" }}>
				<AzoTag/>
				<Grid container>
					<Hero />
					{/* <Benefits/>
                    <Examples/>
                    <HowItWorks/>
                    <Price/>
                    <Faq/> */}
				</Grid>

				<HowItWorks />

				<Price />

				<Faq />

				<ContactUs />
				
			</Container>
			<Footer />
		</div>
	);
}

function addCrispChat() {
	// Add crisp chat
	const scriptTagCrisp = document.createElement("script");
	scriptTagCrisp.type = "text/javascript";
	const t = `window.$crisp=[];window.CRISP_WEBSITE_ID="f6f626a2-7007-475d-a761-e130a22d9dad";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`;
	const scriptText = document.createTextNode(t);
	scriptTagCrisp.appendChild(scriptText);
	// document.getElementsByClassName("crisp")[0].appendChild(scriptTagCrisp);
	document.head.appendChild(scriptTagCrisp);
}
