import React, {useEffect, useState}  from 'react';
import {useHistory, RouteComponentProps} from "react-router-dom";
import { Card, CardContent, CardActions, makeStyles, Typography, Button, Grid, 
	Container,
	Modal,
    Box, TextField
} from '@material-ui/core';

import firebase from "../Components/Firebase";
import {User} from "../Components/Interface"
import Menubar from '../Components/Menubar';
import { useRef } from 'react';
import { useIntl } from "react-intl";
import SimpleBackdrop from "../Components/BackDrop";
import { AlertBar, AlertBarObject } from "../Components/AlertBar";
import CloseIcon from '@material-ui/icons/Close';
import UpgradeModal from './Home/UpgradeModal';

const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },

    accountViewTitle : {
        fontFamily: "SF Pro Display, Roboto, sans-serif",
		color: "#2C3038",
		fontSize: "1.875rem",
		fontWeight: 900,
		fontStyle: "normal",
    },

    accountViewSubTitle : {
        fontFamily: "SF Pro Display, Roboto, sans-serif",
		color: "#2C3038",
		fontSize: "1.25rem",
		fontWeight: 600,
		fontStyle: "normal",
    },

    accountViewText : {
        fontFamily: "SF Pro Display, Roboto, sans-serif",
		color: "#6E6D7A",
		fontSize: "1.25rem",
		fontWeight: 600,
		fontStyle: "normal",
    },

    

    reqViewTitle: {
        fontFamily: "SF Pro Display, Roboto, sans-serif",
		color: "#2C3038",
		fontSize: "1.125rem",
		fontWeight: 600,
		fontStyle: "normal",
    },

    reqViewButton: {
        width: "220px",
        height: "36px",
        // left: 85.5px;
        // top: 0px;

        /* Blue */

        background: "white",
        color: "#0062FF",
        borderRadius: "5px",
        marginTop:"20px",
        borderColor:"#0062FF",
        '&:hover': {
            // backgroundColor: '#fff',
            color: 'white',
            background: "#0062FF",
        }
    },

    reqViewHelperText: {
        textAlign: "end",
        marginRight: "0px"
    },

    resultCard : {
        marginBottom : "20px"
    },

    resultText : {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        // font-style: normal;
        // font-weight: normal;
        fontSize: "20px",
        // line-height: 23px;
        // display: flex;
        // align-items: center;

        /* Dark Gray */

        color: "#6E6D7A",
        whiteSpace : "pre-line"
    },



    resultCopyButton : {

        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#92929D",
        backgroundColor: "#F3F3F4",
        fontWeight: 500,
        // justifyContent: 'center'
        // marginLeft: 'auto',


    },

    upgradeButton :{
        reqViewButton: {
            width: "220px",
            height: "36px",
            // left: 85.5px;
            // top: 0px;
    
            /* Blue */
    
            background: "white",
            color: "#0062FF",
            borderRadius: "5px",
            marginTop:"20px",
            '&:hover': {
                // backgroundColor: '#fff',
                color: '#0062FF',
            }
        },
    },

    emptyStateText : {
        fontFamily: "Roboto, sans-serif",
        fontSize: "30px",
        color: "#6E6D7A",
        alignItems : "center",
        textAlign : "center",
        fontWeight: 300,
    }





});



export default function Account ( { match }: RouteComponentProps) {

    /**
     * - check if user is signed in, else send to landing
     * - check if user is on trial, paid, expired. If expired show billing
     * 
     * 
     */

    //  const [user, setUser] = useState<User>();
     
    const [fbUser, setFbUser] = useState<firebase.default.User>()

    const [user, setUser] = useState<User>()

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [alertBarObject, setAlertBarObject] = useState<AlertBarObject>()
    const [openUserNameModal, setOpenUserNameModal] = useState(false)

    const [userPremium, setUserPremium] = useState(false)

    const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
    


    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory()

    const db = firebase.firestore();
    const auth = firebase.auth()

    useEffect(() => {

        const r = firebase.auth().onAuthStateChanged(currentUser => {
            if (!currentUser){
                console.log("No user!!!")
                history.push("/")
            }else{

                setFbUser(currentUser)

                
                getAccountDetails(currentUser)

                isUserPremium().then(r=>{
                    setUserPremium(r)
                })
            }
        });

        return () => {
            // Do unmounting stuff here
            r()

        };

        // setDemoReqObj()
        // setDemoResults()
		

	}, []);


    async function getAccountDetails(currentUser : firebase.default.User){
        

        console.log("getting account data 2")

        setOpenBackdrop(true)

        try {
            const userDoc = await db.collection("users").doc(currentUser.uid).get()

            if(userDoc.exists){
                setUser(userDoc.data() as User)
            }
        } catch (error) {
            console.error(`Error getting user data: ${error}`)
        }

        setOpenBackdrop(false)
        
    }

    function alertBarDone(){
		setAlertBarObject({open:false, type:"error", alertText:""})	
	}

    function UserNameModal(){

        const [firstName, setFirstName] = useState(user?.firstName ?? "")
        const [lastName, setLastName] = useState(user?.lastName ?? "")
        const [firstNameError, setFirstNameError] = useState(false)
        const [lastNameError, setLastNameError] = useState(false)


        const handleClose = () => setOpenUserNameModal(false);

        const product = useRef("proMo")

        const style = {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: "700px",
            // width: 700,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };

        async function handleSaveButton(event: React.MouseEvent<HTMLElement>){

            /**
             * - Check if names are filled and valid
             */

            if(firstName.trim() === ""){

                setFirstNameError(true)

                return
            }

            if(lastName.trim() === ""){

                setLastNameError(true)

                return
            }

            if(!user) return

            const d = {
                firstName: firstName.trim(),
                lastName : lastName.trim(),
                fullName : firstName.trim() + " " + lastName.trim(),
                intro : true
            }

            

            try {
                setOpenBackdrop(true)
               const r = await db.collection("users").doc(user.userId).update(d)
               setUser({...user, firstName : firstName.trim(), lastName : lastName.trim(), fullName : firstName.trim() + " " + lastName.trim() })
                setOpenUserNameModal(false)

            } catch (error) {
                console.error(`Error saving user name: ${error}`)
            }

            setOpenBackdrop(false)



        }

        function handleNameTextField(event : React.ChangeEvent<HTMLInputElement>){

            const id    = event.currentTarget.id
            const value = event.currentTarget.value

            if(firstNameError === true || lastNameError === true){
                setFirstNameError(false)
                setLastNameError(false)
            }

            if(id === "firstName") setFirstName(value)
            if(id === "lastName") setLastName(value)


        }

        

        return(

            <Modal
                open={openUserNameModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                
                
                <Container style={{textAlign:"center"}}>
                <Box sx={style}>

                <Grid container item style={{textAlign:"left"}}>
                                <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClose}>
                        {/* Open Menu */}
                        <CloseIcon style={{color:"#555A64"}} />
                    </Button>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className={classes.reqViewTitle} >
                                    
                            Edit Profile
                            
                            
                        </Typography>
                            
                           

                        </Grid>

                        <Grid item xs={12} sm={6}>
                        <TextField
                                    id="firstName"
                                    key="firstName"
                                    // label="Title"
                                    // defaultValue="Default Value"
                                   
                                    variant="outlined"
                                    onChange={handleNameTextField}
                                    placeholder="First Name"
                                    fullWidth
                            
                                    value={firstName}
                                    error={firstNameError}
                                    helperText= {firstNameError && "First name can't be empty"}
                                />

                        </Grid>

                        <Grid item xs={12} sm={6}>
                        <TextField
                                    id="lastName"
                                    key="lastName"
                                    // label="Title"
                                    // defaultValue="Default Value"
                                   
                                    variant="outlined"
                                    onChange={handleNameTextField}
                                    placeholder="Last Name"
                                    fullWidth
                            
                                    value={lastName}
                                    error={lastNameError}
                                    helperText= {lastNameError && "Last name can't be empty"}
                                />

                        </Grid>





                    <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    id="upgradePro"
                                    // onClick={startButtonClicked}
                                    style={{
                                        margin: "1.5rem",
                                        width: "17.5rem",
                                        height: "3rem",
                                        fontFamily: "Roboto, sans-serif",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontSize: "1.25rem",
                                        lineHeight: "1.25rem",
                                        backgroundColor: "#0062FF"
                                    }}
                                
                                    onClick={handleSaveButton}
                                
                                >
                                Save
                                </Button>
                    </Grid>    




                    </Grid>



                    </Box>
                </Container>
               
                
                
            </Modal>
        )
    }


    function Account({user}:{user : User}){



        return(
        
            
            <Grid container item xs={12} justifyContent="center">
                <Grid  item xs={12} >
                <Card  variant="outlined" className={classes.resultCard}>
                    <CardContent>
                        

                    <Typography className={classes.accountViewSubTitle}>{`Name`}</Typography>
                        <Typography className={classes.accountViewText}>{user.fullName ?? ""}</Typography>
                    <br/>
                    
                    <Typography className={classes.accountViewSubTitle}>{`Email`}</Typography>
                        <Typography className={classes.accountViewText}>{user.email}</Typography>
        
                    </CardContent>
                    <CardActions>
                        <Button className={classes.resultCopyButton} onClick={() => setOpenUserNameModal(true)} >Edit</Button>
                    </CardActions>
                </Card>
                    {/* <Typography className={classes.resultText}>
                        Email:
                    </Typography> */}
                </Grid>
                {/* <Grid item xs={4}>
                    <Typography  className={classes.resultText}>
                        {user.email}
                    </Typography>
                </Grid> */}
            </Grid>
             
        )
     }

    async function goStripeCustomerPortal(){

        setOpenBackdrop(true)
        

        const functionRef = firebase.functions("europe-west3").httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');

        try {
            const { data } = await functionRef({
                returnUrl: "https://punchline.ai/account",
                // returnUrl: window.location.origin,
                locale: "auto", // Optional, defaults to "auto"
                // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", 
                // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
                });
        
                // console.log(`Acc data: ${JSON.stringify(data)}`)
                window.location.assign(data.url);
        } catch (error) {
            console.error(`goStripeCustomerPortal error: ${error}`)
            setOpenBackdrop(false)
        }
       
    }

    async function launchStripe(){

        if(!user) return

        setOpenBackdrop(true)

        const docRef = await db.collection('users').doc(user.userId).collection('checkout_sessions').add({
            // price: 'price_1JXgaZBUhWPsJD1yW3VqYaFu',
            price: "price_1JY6LnBUhWPsJD1yjJl3pW49",
            success_url: "https://punchline.ai/home",
            cancel_url: window.location.origin,
        });

        // Wait for the CheckoutSession to get attached by the extension
        const unsubscribe = docRef.onSnapshot((snap) => {
            const { error, url } = snap.data() as {error?:Error, url? : string};

            if (error) {
                // Show an error to your customer and 
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
                unsubscribe()
                setOpenBackdrop(false)
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                console.log(`Got stripe url: ${JSON.stringify(url)}`)
                window.location.assign(url);
                unsubscribe()
                setOpenBackdrop(false)
            }

            
        });
    }

    async function isUserPremium():Promise<boolean> {
        console.log(`getting stripe role`)
        if(!auth.currentUser) return false

        await auth.currentUser.getIdToken(true);
        const decodedToken = await auth.currentUser.getIdTokenResult();

        console.log(`stripe role: ${decodedToken.claims.stripeRole}`)
        if(decodedToken.claims.stripeRole === "premium" || decodedToken.claims.stripeRole === "standard") return true

        return false
    }

    function StripeAccount(){

        if(!user) return null

        // if(userPremium === false) return null

        let status = user.status

        if(userPremium === true) status = "active"

        let stripeText = "Please click below to go to your Stripe account portal"

        if(status === "free"){
            stripeText = "Your account is complimentary. Enjoy Punchline"
        }

        if(status === "deactive"){
            stripeText = "Please update your billing to enjoy Punchline"
        }



        return(
            <Grid container  xs={12} justify="center" >
            <Grid item xs={12} sm={8} >

            <Card  variant="outlined" className={classes.resultCard}>
                            <CardContent>

                            
                            <Typography className={classes.accountViewSubTitle}>{`Billing`}</Typography>
                                <Typography className={classes.accountViewText}>{stripeText}</Typography>
                
                            </CardContent>
                            <CardActions>
                         

                                {
                                    status === "active" && 
                                    <Button onClick={goStripeCustomerPortal} color="primary" className={classes.resultCopyButton}>
                                            Manage Billing                        
                                    </Button>
                                }

{
                                    status === "deactive" && 
                                    <Button onClick={launchStripe} color="primary" className={classes.resultCopyButton}>
                                            Update Billing                        
                                    </Button>
                                }
                          
                            </CardActions>
                        </Card>

                

               

            </Grid>
            </Grid>
        )


    }

    function UpdateAccount(){

        function handleClick(){
            setOpenUpgradeModal(true)
        }

        return(
            <Grid container  xs={12} justify="center" >
            <Grid item xs={12} sm={8} >

            <Card  variant="outlined" className={classes.resultCard}>
                            <CardContent>

                            
                            <Typography className={classes.accountViewSubTitle}>{`Upgrade Account`}</Typography>
                                <Typography className={classes.accountViewText}>
                                    Upgrade your account to enjoy all Punchline.ai features.
                                
                                </Typography>
                
                            </CardContent>
                            <CardActions>
                         
                                    <Button onClick={handleClick} color="primary"  variant="contained"
                                
                                id="upgradePro"
                                // onClick={startButtonClicked}
                                style={{
                                    margin: "1.5rem",
                                    // width: "17.5rem",
                                    height: "3rem",
                                    fontFamily: "Roboto, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "1.25rem",
                                    lineHeight: "1.25rem",
                                    backgroundColor: "#0062FF"
                                }}>
                                            Upgrade                        
                                    </Button>
                                
                          
                            </CardActions>
                        </Card>


            </Grid>
            </Grid>
        )

    }

    return(
        <Container>
        <SimpleBackdrop openBackDrop={openBackdrop} />
        <Menubar currentUser={fbUser}/>
        {alertBarObject && <AlertBar alertBarDone={alertBarDone} alertBarObject={alertBarObject}/>}
        <UserNameModal/>
        <UpgradeModal db={db} user={user} openUpgradeModal={openUpgradeModal} setOpenUpgradeModal={setOpenUpgradeModal}  setOpenBackdrop={setOpenBackdrop}/>
        <Grid container xs={12} justifyContent="center">


        <Grid container xs={12} justifyContent="center">
            
            <Grid item xs={12} sm={8}>

            
                <Grid item xs={12}>
                    <Typography className={classes.accountViewTitle} style={{marginBottom:"5%"}}>
                        Account
                    </Typography>
                </Grid>

                {
                    user && <Account user={user} />
                }


            </Grid>
        </Grid>
                {/* {
                    userPremium ? <StripeAccount/>   : <UpdateAccount/>
                } */}

<UpdateAccount/>
            

        
           
        </Grid>
    </Container>

    )


}