import { Card, CardContent, Typography, CardActions, Button } from "@material-ui/core"

import { PlResult } from "../Interface"
import { useStyles } from "../useStyles"

export default function ResultCell({result}:{result: PlResult}){

    const classes = useStyles()
        
    let text = result.result.replace(/^_+|_+$/g, '').replace(/(\r\n|\r|\n){2}/g, '$1').replace(/(\r\n|\r|\n){3,}/g, '$1\n').replace(/\\\\/g, '\\').trim()

    // console.log(`New result: ${JSON.stringify(text)}`)

    return(
        <Card  variant="outlined" className={classes.resultCard}>
            <CardContent>

                <Typography className={classes.resultText}>{text}</Typography>

            </CardContent>
            <CardActions>
                <Button className={classes.resultCopyButton} onClick={() => {navigator.clipboard.writeText(text)}} >Copy</Button>
            </CardActions>
        </Card>

    )
}