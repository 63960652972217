import {Persona } from "../../Components/Interface";

import firebase from "firebase";


export default async function getPersonas(userId:string):Promise<Persona[]|undefined>{

    const db = firebase.firestore();

    try {
        const result = await db.collection("persona").where("userId", "==", userId).get()
        
        if(result.empty) return
        
        const personas = result.docs.map(r=>{

            return  r.data() as Persona

        }) 

        return personas

    } catch (error) {
        console.error("Error getting personas", error)
        return
    }
}