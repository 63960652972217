// export const PRICE_INFO  = {
//     monthPrice      : "39",
//     monthId         : "price_1Ls3eNBUhWPsJD1yDPJuNTLx",
//     yearPrice       : "29",
//     yearId          : "price_1Ls3fSBUhWPsJD1y4Vdzf0eR"
// }

export const PRICE_INFO  = {
    // proMonthPrice      : "49",
    // proMonthId         : "price_1JXgaZBUhWPsJD1yW3VqYaFu",
    // proYearPrice       : "39",
    // proYearId          : "price_1K4iY5BUhWPsJD1yfOQKqLGp",

    proMonthPrice      : "29",
    proMonthId         : "price_1N15baBUhWPsJD1ySc3hpTHm",
    proYearPrice       : "23",
    proYearId          : "price_1N15ZZBUhWPsJD1yp3hmmrCw",

    basicMonthPrice      : "19",
    basicMonthId         : "price_1KnM11BUhWPsJD1yzLPntP7c",
    basicYearPrice       : "15",
    basicYearId          : "price_1KnM3JBUhWPsJD1yapOG5Ije",

    prepaidPrice    : "19",
    prepaidId       : "price_1N14TyBUhWPsJD1yYhy9TUR3"
}