import firebase from "firebase";
import { ChatObject } from "../../Components/Interface";

export default async function SendChat(chatObject:ChatObject):Promise<{chat? :ChatObject, error? :string}>{

    const newReqFunc = firebase
    .functions()
    .httpsCallable("newChatRequest");
    
    try {
        const result = await newReqFunc({request : chatObject})

        if(result.data.done === true && result.data.completion){

            console.log("messages", result.data.completion.messages)

            return {chat : result.data.completion as ChatObject}
        }

        if(result.data.done === false && result.data.error){

            console.error(`Error getting completions: ${result.data.error}`)

            return {error : result.data.error}
        }

        return {error : "Error getting chat"}

    } catch (error) {        
        console.error(`Error getting completions: ${error}`)
        return {error : "Error getting chat"}
    }
}