import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

export default function AzoTag() {
    
    function handleClick(){     
          window.location.href = 'https://twitter.com/azizakgul';
    }

  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }} style={{
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        zIndex: "10"
    }}>
      <Fab variant="extended" size="small"  aria-label="add" onClick={handleClick} style={{textTransform:"lowercase"}}>
        
        by @azizakgul
      </Fab>
      
    </Box>
  );
}