import { useState } from "react";
import firebase from "firebase"
import { Card, CardContent, Typography, Button, Grid, 
	Container,
	Modal,
    Box,
    FormControl, InputLabel, Select, MenuItem, TextField

} from '@material-ui/core';

import flag_us from "../../assets/flags/us.png"
import flag_tr from "../../assets/flags/tr.png"
import flag_it from "../../assets/flags/it.png"
import flag_fr from "../../assets/flags/fr.png"
import flag_es from "../../assets/flags/es.png"
import flag_de from "../../assets/flags/de.png"

import { User } from "../../Components/Interface";
import CloseIcon from '@material-ui/icons/Close';
import {useStyles} from "../../Components/useStyles"
import { stepButtonClasses } from "@mui/material";

import { Persona } from "../../Components/Interface";



export default function NewPersona({userId, db, openPersonaModal, setOpenPersonaModal, newPersona}:{userId? :string, db : firebase.firestore.Firestore, openPersonaModal :boolean, setOpenPersonaModal : Function, newPersona : Function}){

    const [title, setTitle]     = useState("")
    const [name, setName]       = useState("")
    const [content, setContent] = useState("")

    const classes = useStyles();

    const handleClose = () => setOpenPersonaModal(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "700px",
        // width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    async function handleSaveButton(event: React.MouseEvent<HTMLElement>){

        if(title.trim() === "" || content.trim() === "") return

        if(!userId){
            setOpenPersonaModal(false)
            return
        } 
        
        // setOpenBackdrop(true)
    
        const ref = db.collection("persona").doc()

        const now = firebase.firestore.Timestamp.now()

        const newPersonaObj : Persona = {
            id      : ref.id,
            userId : userId,
            title   : title,
            content : content,
            created : now,
            updated : now
        }

        if(name && name.trim() !== ""){
            newPersonaObj.name = name
        }

        try {
            await ref.set(newPersonaObj)
            newPersona(newPersonaObj)
            setTitle("")
            setContent("")
            console.log("persona saved")
        } catch (error) {
            console.log("persona save error", error)
        }

        // setOpenBackdrop(false)
        setOpenPersonaModal(false)
    }

    function handleNewTextField(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const id = event.currentTarget.id;
        const value = event.currentTarget.value;

        if(id === "title") setTitle(value)
        if(id === "content") setContent(value)
        if(id === "name")setName(value)
    }



    return(

        <Modal
            open={openPersonaModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            
            <Container style={{textAlign:"center"}}>
            <Box sx={style}>

                <Grid container item style={{textAlign:"left"}}>
                    <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClose}>
                    {/* Open Menu */}
                    <CloseIcon style={{color:"#555A64"}} />
                </Button>
                </Grid>

                <Grid container justify="center" spacing={3}>

            <Grid item xs={12} justify="center" >
                <Typography className={classes.reqViewTitle} >
                            
                           Create new persona
                       
                   </Typography>
            </Grid>

            <Grid container item xs={12} spacing={4} justifyContent="center" alignItems="center">

            <Grid item xs={12}>
                    <form noValidate autoComplete="off" key={"name"}>
                        <div>
                            <TextField
                                id={"name"}
                                key={"name"}
                                // error={fieldErrorId === field.id}
                                // label="Title"
                                // defaultValue="Default Value"
                                helperText="Enter a name for your persona (optional)"
                                variant="outlined"
                                onChange={handleNewTextField}
                                placeholder="Lucy"
                                fullWidth
                                maxRows={1}
                                multiline={false}
                                // FormHelperTextProps={{
                                // 	className: classes.reqViewHelperText,
                                // }}
                                value={name
                                }
                            />
                        </div>
                    </form>
                </Grid>

                <Grid item xs={12}  >

                    <Grid item xs={12}>
                        <form noValidate autoComplete="off" key={"title"}>
                            <div>
                                <TextField
                                    id={"title"}
                                    key={"title"}
                                    // error={fieldErrorId === field.id}
                                    // label="Title"
                                    // defaultValue="Default Value"
                                    helperText="Enter a title for your persona"
                                    variant="outlined"
                                    onChange={handleNewTextField}
                                    placeholder="Digital marketing manager"
                                    fullWidth
                                    maxRows={10}
                                    multiline={false}
                                    // FormHelperTextProps={{
                                    // 	className: classes.reqViewHelperText,
                                    // }}
                                    value={title
                                    }
                                />
                            </div>
                        </form>
                    </Grid>
                 

                </Grid>

                

                <Grid item xs={12}  >

                <form noValidate autoComplete="off" key={"content"}>
                        <div>
                            <TextField
                                id={"content"}
                                key={"content"}
                                // error={fieldErrorId === field.id}
                                // label="Title"
                                // defaultValue="Default Value"
                                helperText="Details of your persona"
                                variant="outlined"
                                onChange={handleNewTextField}
                                placeholder="You are a digital marketing manager who..."
                                fullWidth
                                maxRows={10}
                                multiline={true}
                                // FormHelperTextProps={{
                                // 	className: classes.reqViewHelperText,
                                // }}
                                value={content
                                }
                            />
                        </div>
                    </form>

               
                 

                </Grid>

                <Grid item>

                <Button onClick={handleSaveButton} color="primary" className={classes.reqViewButton}>
                        Save
                </Button>

                </Grid>


               

            </Grid>

            </Grid>
            </Box>
            </Container>
           
            
            
        </Modal>
    )


}