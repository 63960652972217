import React, {useEffect, useState, useRef}  from 'react';

import {useHistory, RouteComponentProps, useParams} from "react-router-dom";
import { Card, CardContent, CardActions, Switch, Typography, Button, Grid, 
	Container,
    Paper,
	Modal,
	Dialog, DialogContent,
    Box,
    useMediaQuery ,
    FormControl, InputLabel, Select, MenuItem, TextField, ListSubheader

} from '@material-ui/core';

import firebase from "../Components/Firebase";
import {PlRequestType, PlRequestObj, PlRequestTypeMain, PlNewRequest, PlRequestObjField, PlResult, User, PlCategory} from "../Components/Interface"
import Menubar from '../Components/Menubar';

import { useIntl } from "react-intl";
import SimpleBackdrop from "../Components/BackDrop";
import { AlertBar, AlertBarObject } from "../Components/AlertBar";
import CloseIcon from '@material-ui/icons/Close';

import flag_us from "../assets/flags/us.png"
import flag_tr from "../assets/flags/tr.png"
import flag_it from "../assets/flags/it.png"
import flag_fr from "../assets/flags/fr.png"
import flag_es from "../assets/flags/es.png"
import flag_de from "../assets/flags/de.png"

import {useStyles} from "../Components/useStyles"

import FeedbackButton from '../Components/feedbackButton';

import shouldShowUserIntro from '../Helpers/shouldShowUserIntro';
import isUpgradeUrl from '../Helpers/isUpgradeUrl';
import isUserAccountActive from '../Helpers/isUserAccountActive';
import isUserAccountOnTrial from '../Helpers/isUserAccountOnTrial';
import getUser from '../Helpers/getUser';
import getToolsList from '../Helpers/getToolsList';
import isOpenaiTestUser from '../Helpers/isOpenaiTestUser';
import ResultsViewEmptyState from '../Components/ResultsView/ResultsViewEmptyState';
import ResultsView from '../Components/ResultsView';
import isLargeScreen from '../Helpers/isLargeScreen';
import { PRICE_INFO } from '../Helpers/priceInfo';


export default function Home ( { match }: RouteComponentProps) {

    /**
     * - check if user is signed in, else send to landing
     * - check if user is on trial, paid, expired. If expired show billing
     * 
     * 
     */

    const [user, setUser] = useState<User>();
     
    const [fbUser, setFbUser] = useState<firebase.default.User>();

    const [showPayment, setShowPayment] = useState(false)

    const [trialDays, setTrialDays] = useState(0)
    const [activeAccount, setActiveAccount] = useState(true)
    const [openUpgradeModal, setOpenUpgradeModal] = useState(false)
    const [openUserNameModal, setOpenUserNameModal] = useState(false)
    const [openLanguageModal, setOpenLanguageModal] = useState(false)

    const largeScreen = isLargeScreen();

    // Request
    const [title, setTitle] = useState<string|undefined>();

    // const [plReq, setPlReq] = useState<PlRequest|undefined>()

    const [plReqObj, setPlReqObj] = useState<PlRequestObj[]>()

    const [resultsList, setResultsList] = useState<PlResult[]>()
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const [requestType, setRequestType] = useState<PlRequestType>(PlRequestType.blogIdeas)

    const [categoryType, setCategoryType] = useState<PlRequestTypeMain>(PlRequestTypeMain.blog)






    const [categories, setCategories] = useState<PlCategory[]>()
    const [newRequestType, setNewRequestType] = useState<{category : PlRequestTypeMain, type : PlRequestType }>({category : PlRequestTypeMain.blog, type : PlRequestType.blogIdeas})
    const [currentTool, setCurrentTool] = useState<PlRequestObj>()
    const [currentCategory, setCurrentCategory] = useState<PlCategory>()


    



    

    const plNewRequestSubmit = useRef<PlNewRequest>({type:requestType})

    const [alertBarObject, setAlertBarObject] = useState<AlertBarObject>()

    // const prodObj = useRef<ProductDescriptionObject>()
    

    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory()

    const db = firebase.firestore();

    const auth = firebase.auth()

    const analytics = firebase.analytics()

    const params = useParams()

    useEffect(() => {
        
        const r = firebase.auth().onAuthStateChanged(currentUser => {
            if (!currentUser){
                console.log("No user!!!")
                history.push("/")
            }else{
                
                setFbUser(currentUser)

                // db.collection("requestTypes").doc("public").get().then(o=>{

                //     const data = o.data()
                    
                //     const reqObj = data && data["requestTypes"] as PlRequestObj[]

                //     if(reqObj){
                        
                //         setPlReqObj(reqObj)

                //         setInitCategories(currentUser, reqObj)
                //     }
                // })

                initTools(currentUser)

                initUser(currentUser.uid)

                // checkAccount(currentUser.uid)

               

            }
        });

       
        // console.log(`Use params: ${JSON.stringify(params)}`)
       

        return () => {
            // Do unmounting stuff here
            r()

        };		

	}, []);


    async function initUser(userId:string){

        const user = await getUser(userId)

        if(!user) return

        setUser(user)

        if(shouldShowUserIntro(user) === true){
            setOpenUserNameModal(true)            
        } 

        if(await isUserAccountActive(user) === true) return
        
        const isAccountOnTrial = isUserAccountOnTrial(user)
        if(isAccountOnTrial.active === true){

            setTrialDays(isAccountOnTrial.trialDays ?? 0)
            return 

        } 

        // if(isUpgradeUrl(params) === true){
        //     setOpenUpgradeModal(true)
        //     return
        // } 

        setActiveAccount(false)
        setOpenUpgradeModal(true)
    }

    async function initTools(currentUser: firebase.default.User){
        const tools = await getToolsList(db)
        if(tools){

            setPlReqObj(tools)

            setInitCategories(currentUser, tools)

        }
    }

   

    // Request View

    function setInitCategories(user : firebase.default.User, plReqObjArr : PlRequestObj[]){

        /**
         * - filter tools not live if not admin
         * - combine tools under categories
         * - sort categories
         * - sort tools
         */

        let plReqObj = plReqObjArr


        if(user.email && isOpenaiTestUser(user.email) === false){
            // Don't show test tools

            plReqObj = plReqObjArr.filter(t =>{
                return typeof t.live === "undefined"  || t.live === true
            })
        }

        const blogTools = plReqObj.filter(t =>{
            return t.typeMain === PlRequestTypeMain.blog
        }).sort((a, b)=>{
            return a.index - b.index
        }) 

        const salesTools = plReqObj.filter(t =>{
            return t.typeMain === PlRequestTypeMain.sales
        }).sort((a, b)=> a.index - b.index)

        const productTools = plReqObj.filter(t =>{
            return t.typeMain === PlRequestTypeMain.product
        }).sort((a, b)=> a.index - b.index)

        const musicTools = plReqObj.filter(t =>{
            return t.typeMain === PlRequestTypeMain.music
        }).sort((a, b)=> a.index - b.index)

        const writingTools = plReqObj.filter(t =>{
            return t.typeMain === PlRequestTypeMain.writing
        }).sort((a, b)=> a.index - b.index)

        const emailTools = plReqObj.filter(t =>{
            return t.typeMain === PlRequestTypeMain.email
        }).sort((a, b)=> a.index - b.index)

        const socialTools = plReqObj.filter(t =>{
            return t.typeMain === PlRequestTypeMain.socialMedia
        }).sort((a, b)=> a.index - b.index)

        const startupTools = plReqObj.filter(t =>{
            return t.typeMain === PlRequestTypeMain.startup
        }).sort((a, b)=> a.index - b.index)


        const catArr : PlCategory[] = [
            {
                category : PlRequestTypeMain.blog,
                types : blogTools

            },
            {
                category : PlRequestTypeMain.socialMedia,
                types : socialTools

            },
            {
                category : PlRequestTypeMain.sales,
                types : salesTools

            },
            {
                category : PlRequestTypeMain.product,
                types : productTools

            },
            {
                category : PlRequestTypeMain.email,
                types : emailTools

            },
            {
                category : PlRequestTypeMain.music,
                types : musicTools

            },
            {
                category : PlRequestTypeMain.writing,
                types : writingTools

            },
            {
                category : PlRequestTypeMain.startup,
                types : startupTools

            }

        ]

        setCategories(catArr)
        setCurrentTool(blogTools[0])
        // console.table(blogTools)
        // console.log("blog tools", blogTools)
        setCurrentCategory(catArr[0])

    }

    function RequestViewNew(){

        /**
         * - check if PlRequestObj arr exist
         * - show user
         * - on submit send PlNewRequest
         * - show results
        */

        const [textFieldIdError, setTextFieldIdError] = useState<string>()
        
        
        if(!categories || !currentTool) return null

    
        const fields = currentTool.fields

        async function handleReqSubmit(){

            if(activeAccount === false){
                setOpenUpgradeModal(true)
                return
            }

            const checkFields = checkRequiredFields()

            console.log('checkFields ', checkFields)

            if(checkFields.pass === false){
                console.error("Error field id", checkFields.fieldId)
                if(checkFields.fieldId) setTextFieldIdError(checkFields.fieldId)
                return

            } 



            analytics.logEvent("new_request", {type : plNewRequestSubmit.current.type})
            setOpenBackdrop(true)
            console.log(`plNewRequestSubmit: ${JSON.stringify(plNewRequestSubmit.current)}`)
            const newReqFunc = firebase
			.functions()
			.httpsCallable("newRequest");
            const d = {
                request: plNewRequestSubmit.current,                
            };

            try {
                const result = await newReqFunc(d)

                if(result.data.done === true && result.data.completion){

                    setResultsList(result.data.completion as PlResult[])
                }

                if(result.data.done === false && result.data.error){

                    if(result.data.error === "rate_limit"){

                        const text = result.data.wait ? `Too many requests. Please try again in ${result.data.wait} seconds` : "Too many requests. Please try again later"

                        setAlertBarObject({open:true, type:"error", alertText: text})
                    }else{
                        setAlertBarObject({open:true, type:"error", alertText:"Error getting results"})
                    }


                }

                console.log("Request completion saved!")

            } catch (error) {
                setAlertBarObject({open:true, type:"error", alertText:"Error getting results"})
                console.error(`Error getting completions: ${error}`)
                
            }

            setOpenBackdrop(false)
        }

        function checkRequiredFields(){

            // Check plNewRequestSubmit.current with currentTool

            const currentFields = plNewRequestSubmit.current.fields
            const selectedToolFields = currentTool?.fields

            if(!selectedToolFields) return {pass : false}

            const requiredIds = selectedToolFields.filter(t =>{
                return t.optional === false
            }).map(t =>{
                return t.id
            })

            if(!currentFields){
                return {pass : false, fieldId : requiredIds[0] }
            }

            const currentIds = currentFields.filter(f=>{
                return f.value.trim() !== ""
            }).map(t =>{
                return t.id
            })

            const missingIds = requiredIds.filter(f =>{
                return currentIds.indexOf(f) === -1
            })

            console.log("requiredIds: ", requiredIds)
            console.log("currentIds: ", currentIds)
            console.log("missingIds: ", missingIds)

            if(missingIds && missingIds.length > 0){
                return {pass : false, fieldId : missingIds[0]}
            }else{
                return {pass : true}
            }

            



        }

        function ReqFields(){

            // const [plNewRequest, setPlNewRequest] = useState<PlNewRequest>({type : requestType})
        

            

            function handleSelectChange(event :  React.ChangeEvent<{ name?: string | undefined; value: unknown; }>){
                // console.log("setRequestType", event.target.value)
                // console.log("setRequestType name", event.target.name)

                const value = event.target.value
                const name = event.target.name

                if(!categories) return

                if(value === "header") return

                if(name === "requestType" && (value as PlRequestType) !== plNewRequestSubmit.current.type){
                    // setPlNewRequest({type : requestType})
                    
                    const blogTopic = plNewRequestSubmit.current.fields?.filter(f=>f.id === "blogTopic")
                    const tone = plNewRequestSubmit.current.fields?.filter(f=>f.id === "tone")
                    const keywords = plNewRequestSubmit.current.fields?.filter(f=>f.id === "keywords")
                    const songDescription = plNewRequestSubmit.current.fields?.filter(f=>f.id === "songDescription")

                    const songLyrics = plNewRequestSubmit.current.fields?.filter(f=>f.id === "songLyrics")

                    plNewRequestSubmit.current = {type : value as PlRequestType}
                    
                    if(blogTopic && blogTopic.length > 0){
                        plNewRequestSubmit.current.fields = plNewRequestSubmit.current.fields ? [...plNewRequestSubmit.current.fields, blogTopic[0]] :   blogTopic 
                    }                      

                    if(tone && tone.length > 0){
                        plNewRequestSubmit.current.fields = plNewRequestSubmit.current.fields ? [...plNewRequestSubmit.current.fields, tone[0]] : tone 
                    } 

                    if(keywords && keywords.length > 0) {
                        plNewRequestSubmit.current.fields = plNewRequestSubmit.current.fields ? [...plNewRequestSubmit.current.fields, keywords[0]] :   keywords 
                    }                     
                    
                    if(songDescription && songDescription.length > 0) {
                        plNewRequestSubmit.current.fields = plNewRequestSubmit.current.fields ? [...plNewRequestSubmit.current.fields, songDescription[0]] :   songDescription 
                                         
                    }   

                    if(songLyrics && songLyrics.length > 0) {
                        plNewRequestSubmit.current.fields = plNewRequestSubmit.current.fields ? [...plNewRequestSubmit.current.fields, songLyrics[0]] :   songLyrics 
                                         
                    }   

                    const categoryType = currentCategory?.types.filter(t=>{
                        return t.type === value
                    })[0]

                    setCurrentTool(categoryType)

                    setRequestType(value as PlRequestType)
                    return
                }

                if(event.target.name === "category"){

                    const currentCategory = categories.filter(c=>{
                        return c.category === value 
                    })[0]

                    const categoryType = currentCategory.types[0]

                    // setNewRequestType({category: value as PlRequestTypeMain , type : categoryType?.type ??  PlRequestType.blogParagraph })

                    plNewRequestSubmit.current = {type : categoryType.type}

                    // console.table(plNewRequestSubmit.current)
                    setCategoryType(value as PlRequestTypeMain)

                    setCurrentCategory(currentCategory)
                    setCurrentTool(categoryType)

                           
                    
                    setRequestType(categoryType.type)
                  

                }
                
            };

            function ReqField({field, errorId}:{field : PlRequestObjField, errorId? : string}){

                const [plNewRequest, setPlNewRequest] = useState<PlNewRequest>({type : requestType})

                const [fieldErrorId, setFieldErrorId] = useState(errorId)


                function handleNewTextField(event : React.ChangeEvent<HTMLInputElement>){

                    const id    = event.currentTarget.id
                    const value = event.currentTarget.value

                    if(fieldErrorId) setFieldErrorId(undefined)

                    console.log(`old Fields: ${JSON.stringify(plNewRequestSubmit.current.fields)}`)
    
                    if(typeof plNewRequestSubmit.current.fields === "undefined"){
                        plNewRequestSubmit.current = {type : requestType, fields : [{id:id, value : value}]}

                        setPlNewRequest({type : requestType, fields : [{id:id, value : value}]})

                        console.log(`handleNewTextField: ${JSON.stringify(plNewRequestSubmit.current)}`)
                        return
                    }
    
                    // const currentField = plNewRequest.fields.filter(f=>f.id === id)[0]

                    

                    const currentFields = plNewRequestSubmit.current.fields.filter(f=>f.id !== id)

                    console.log(`currentFields: ${JSON.stringify(currentFields)}`)

                    plNewRequestSubmit.current = {type : requestType, fields : [...currentFields, {id:id, value : value}]}
                    setPlNewRequest({type : requestType, fields : [...currentFields, {id:id, value : value}]})


                    console.log(`handleNewTextField: ${JSON.stringify(plNewRequestSubmit.current)}`)
    
                   
                }

                if(field.type === "text" || field.type === "textfield"){
                    return(
                        <>
                        <Grid item xs={12} style={{marginTop:"20px"}}>
                            <Typography className={classes.reqViewTitle} >
                                
                                {field.title}
                                
                                {
                                    field.optional &&
                                        <span style={{fontSize:"1rem", fontWeight:"lighter"}}>&nbsp; (Optional)</span>
                                }
                            </Typography>
                        </Grid>
        
                        <Grid item xs={12}>
                            <form  noValidate autoComplete="off" key={field.id}>
                                <div>
        
                                <TextField
                                    id={field.id}
                                    key={field.id}
                                    error={fieldErrorId === field.id}
                                    // label="Title"
                                    // defaultValue="Default Value"
                                    helperText={field.maxChar && `${field.maxChar - (plNewRequest.fields?.filter(f2=>{return (f2.id === field.id)})[0]?.value.length ?? 0)} Characters`}
                                    variant="outlined"
                                    onChange={handleNewTextField}
                                    placeholder={field.placeholder}
                                    fullWidth
                                    maxRows={10}
                                    multiline={field.type === "textfield" ? true : false}
                                    FormHelperTextProps={{
                                        className: classes.reqViewHelperText
                                    }}
        
                                    value={plNewRequestSubmit.current.fields?.filter(f2=>f2.id === field.id)[0]?.value ?? ""}
                                />
        
                                </div>
                            </form>
        
                        </Grid>

                    </>

                    )
                }

                if(field.type === "select"){
                    //TODO
                }



                return null

            }

            function Categories(){


                return(
                    <>
                     {/* <Grid item xs={4} >
                        <Typography className={classes.reqViewTitle} >
                            {intl.formatMessage({id: `home.request.title.categories`})}
                        </Typography>
                    </Grid> */}

                    <Grid item xs={5}>
                        <FormControl variant="outlined" fullWidth={true} >
                            {/* <InputLabel id="demo-simple-select-outlined-label"> </InputLabel> */}
                            <Select
                            
                            style={{color:"#28A745"}}
                            name="category"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={categoryType}
                            onChange={handleSelectChange}
                            // label="Select a tool"
                            >
                            {/* <MenuItem value="">
                                <em>None</em>
                            </MenuItem> */}

                            {
                                categories?.map(t => {

                                    return(
                                        <MenuItem value={t.category} >{intl.formatMessage({id: `home.request.title.${t.category}`})}</MenuItem>
                                    )

                                })

                            }

                            {/* {
                                reqTypeList.map(t=>{
                                    return(
                                        <MenuItem value={t} >{intl.formatMessage({id: `home.request.title.${t}`})}</MenuItem>
                                    )
                                })

                            } */}

                          
                            
                            </Select>
                        </FormControl>
    
                    </Grid>


                    
                    
                    </>
                )
            }

            return(

                <Grid container justifyContent='space-between'>

                    <Categories/>

                    {/* <Grid item xs={12} >
                        <Typography className={classes.reqViewTitle} >
                            {intl.formatMessage({id: `home.request.title.tools`})}
                        </Typography>
                    </Grid> */}

                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth={true} >
                            {/* <InputLabel id="demo-simple-select-outlined-label"> </InputLabel> */}
                            <Select
                            
                            style={{color:"#28A745"}}
                            name="requestType"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={requestType}
                            onChange={handleSelectChange}
                            // label="Select a tool"
                            >
                           

                            {
                                currentCategory?.types.map( t => {

                                    return(
                                        <MenuItem value={t.type} >{intl.formatMessage({id: `home.request.title.${t.type }`})}</MenuItem>
                                    )

                                })

                            }
                          
                            
                            </Select>
                        </FormControl>
    
                    </Grid>

                    {
                        currentTool?.fields.map(f=>{
                            // console.table(f)
                            return(<ReqField field={f} errorId={textFieldIdError} />)
                        })

                    }

                    

                </Grid>
               
            )
        }




        return(
            <Card  variant="outlined" >
            <CardContent>

            <Grid container direction="column"  justify="space-between" alignItems="center">
                
                {/* <ReqViewSelect/>
                {requestType === PlRequestType.productDescription && <ReqViewProductDescription/>}
                {requestType === PlRequestType.songLyrics && <ReqViewSongLyrics/>} */}


                <ReqFields/>

                <Grid item>

                    <Button onClick={handleReqSubmit} color="primary" className={classes.reqViewButton}>
							Generate
                    </Button>

                </Grid>

                <Grid item>

                    <FeedbackButton/>

                </Grid>


            </Grid>

            </CardContent>
            </Card>
        )


    }

    
    function alertBarDone(){
		setAlertBarObject({open:false, type:"error", alertText:""})	
	}

    async function launchStripe(product: string){

        const priceIdPerMo = PRICE_INFO.proMonthId  //$39 per mo
        const priceIdPerYr = PRICE_INFO.proYearId  // $29 per mo => $348 per yr


        if(!user) return
        setOpenUpgradeModal(false);

        setOpenBackdrop(true)

        let priceId = priceIdPerMo
        // if(product === "basicMo") priceId = "price_1KURSYBUhWPsJD1yWU7FliVP"
        // if(product === "basicYr") priceId = "price_1KURTHBUhWPsJD1yeNgKpVKN"
        if(product === "proMo") priceId = priceIdPerMo
        if(product === "proYr") priceId = priceIdPerYr
        // if(product === "musicYr") priceId = "price_1KURZMBUhWPsJD1y2U3DGLp1"
        // if(product === "musicMo") priceId = "price_1KURZMBUhWPsJD1yuoHLWvjy"

        const docRef = await db.collection('users').doc(user.userId).collection('checkout_sessions').add({
            price: priceId,
            // price: "price_1JY6LnBUhWPsJD1yjJl3pW49",
            success_url: "https://punchline.ai/home",
            cancel_url: window.location.origin,
            allow_promotion_codes: true,
            customer_email : user.email,
            client_reference_id : user.userId
        })

        

        // Wait for the CheckoutSession to get attached by the extension
        const unsubscribe = docRef.onSnapshot((snap) => {
            const { error, url } = snap.data() as {error?:Error, url? : string};

            if (error) {
                // Show an error to your customer and 
                // inspect your Cloud Function logs in the Firebase console.
                
                // alert(`An error occured: ${error.message}`);

                setAlertBarObject({open:true, type:"error", alertText:error.message})

                unsubscribe()
                setOpenBackdrop(false)
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                console.log(`Got stripe url: ${JSON.stringify(url)}`)
                window.location.assign(url);
                unsubscribe()
            }

            
        });
    }

    function goTo(location : string){
        if(location === "upgrade" && user){
            

            // launchStripe()
            setOpenUpgradeModal(true)
            return
        }

        if(location === "editLanguage" && user){
            setOpenLanguageModal(true)
            console.log("Edit language")
        }
    }

    function UserNameModal(){

        const [firstName, setFirstName] = useState(user?.firstName ?? "")
        const [lastName, setLastName] = useState(user?.lastName ?? "")
        const [firstNameError, setFirstNameError] = useState(false)
        const [lastNameError, setLastNameError] = useState(false)


        const handleClose = () => setOpenUserNameModal(false);

        const product = useRef("proMo")

        const style = {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: "700px",
            // width: 700,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };

        function capitalizeFirstLetter(str) {
            return str.replace(/^\p{CWU}/u, char => char.toLocaleUpperCase(navigator.language));
        }

        async function handleSaveButton(event: React.MouseEvent<HTMLElement>){

            /**
             * - Check if names are filled and valid
             */

            if(firstName.trim() === ""){

                setFirstNameError(true)

                return
            }

            if(lastName.trim() === ""){

                setLastNameError(true)

                return
            }

            if(!user) return

            const firstNameEdited = capitalizeFirstLetter(firstName.trim().toLowerCase())
            const lastNameEdited = capitalizeFirstLetter(lastName.trim().toLowerCase())

            const d = {
                firstName: firstNameEdited,
                lastName : lastNameEdited,
                fullName : firstNameEdited + " " + lastNameEdited,
                intro : true
            }

            

            try {
                setOpenBackdrop(true)
                await db.collection("users").doc(user.userId).update(d)
                setOpenUserNameModal(false)

            } catch (error) {
                console.error(`Error saving user name: ${error}`)
            }

            setOpenBackdrop(false)



        }

        function handleNameTextField(event : React.ChangeEvent<HTMLInputElement>){

            const id    = event.currentTarget.id
            const value = event.currentTarget.value

            if(firstNameError === true || lastNameError === true){
                setFirstNameError(false)
                setLastNameError(false)
            }

            if(id === "firstName") setFirstName(value)
            if(id === "lastName") setLastName(value)


        }

        

        return(

            <Modal
                open={openUserNameModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                
                
                <Container style={{textAlign:"center"}}>
                <Box sx={style}>

                <Grid container item style={{textAlign:"left"}}>
                                <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClose}>
                        {/* Open Menu */}
                        <CloseIcon style={{color:"#555A64"}} />
                    </Button>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            
                            {
                                    largeScreen ? 

                                    <Typography className={classes.reqViewTitle} >
                                    {
                                         `Welcome to Punchline 😃  \u00A0  Please add your name` 
                                    }
                                    
                                </Typography>

                                : 
                                <>
                                <Typography className={classes.reqViewTitle} >
                                
                                     Welcome to Punchline 😃 
                                
                                
                            </Typography>

                            <Typography className={classes.reqViewTitle} >
                                
                                   Please add your name
                                
                                
                            </Typography>
                            </>
                                    
                                    
                                }

                        </Grid>

                        <Grid item xs={12} sm={6}>
                        <TextField
                                    id="firstName"
                                    key="firstName"
                                    // label="Title"
                                    // defaultValue="Default Value"
                                   
                                    variant="outlined"
                                    onChange={handleNameTextField}
                                    placeholder="First Name"
                                    fullWidth
                            
                                    value={firstName}
                                    error={firstNameError}
                                    helperText= {firstNameError && "First name can't be empty"}
                                />

                        </Grid>

                        <Grid item xs={12} sm={6}>
                        <TextField
                                    id="lastName"
                                    key="lastName"
                                    // label="Title"
                                    // defaultValue="Default Value"
                                   
                                    variant="outlined"
                                    onChange={handleNameTextField}
                                    placeholder="Last Name"
                                    fullWidth
                            
                                    value={lastName}
                                    error={lastNameError}
                                    helperText= {lastNameError && "Last name can't be empty"}
                                />

                        </Grid>





                    <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    id="upgradePro"
                                    // onClick={startButtonClicked}
                                    style={{
                                        margin: "1.5rem",
                                        width: "17.5rem",
                                        height: "3rem",
                                        fontFamily: "Roboto, sans-serif",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontSize: "1.25rem",
                                        lineHeight: "1.25rem",
                                        backgroundColor: "#0062FF"
                                    }}
                                
                                    onClick={handleSaveButton}
                                
                                >
                                Save
                                </Button>
                    </Grid>    




                    </Grid>



                    </Box>
                </Container>
               
                
                
            </Modal>
        )
    }

    function UpgradeModal(){

        const [yearPrice, setYearPrice] = useState(true)

        const pricePerYear = PRICE_INFO.proYearPrice
        const pricePerMo   = PRICE_INFO.proMonthPrice

        const handleClose = () => setOpenUpgradeModal(false);

        const product = useRef("proMo")


        function handleUpgradeButton(event: React.MouseEvent<HTMLElement>){

            let p = yearPrice ? "proYr" : "proMo"

            launchStripe(p)
        }

        

        return(

            <Dialog
                open={openUpgradeModal}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"lg"}              
            >
                <DialogContent>
                
                
                <Container style={{textAlign:"center"}}>
                {/* <Box sx={style} width={largeScreen ? "1100px" : "auto"}> */}

                  

                

                    <Grid container item style={{textAlign:"left"}}>
                                <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClose}>
                        {/* Open Menu */}
                        <CloseIcon style={{color:"#555A64"}} />
                    </Button>
                    </Grid>

                    <Grid container justify="center" spacing={3}>

                <Grid item xs={12} justify="center" >
                        <Typography id="modal-modal-title" variant="h5" component="h2" style={{color:"#2C3038"}}>
                            Upgrade to continue enjoying Punchline
                        </Typography>
                </Grid>

                <Grid container item xs={12} justifyContent="center" alignItems="center">

                    <Grid item xs={5}  style={largeScreen ? {textAlign:"right"} : {textAlign:"center"}}>
                        <Typography variant="body1"  className={yearPrice ? classes.priceTermText : classes.priceTermTextBlack}>
                        Billed Monthly					
                        </Typography>

                    </Grid>

                    <Grid item xs={2} md={1} >

                        <Switch
                        className={classes.switch}
                        // style={{color:"#28A745", backgroundColor:"#E9F6EC"}}
                        checked={yearPrice}
                        onChange={()=>{setYearPrice(!yearPrice)}}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </Grid>

                    <Grid item xs={5}  style={largeScreen ? {textAlign:"left"} : {textAlign:"center"}}>
                        <Typography variant="body1"  className={yearPrice ? classes.priceTermTextBlack : classes.priceTermText}>
                            Billed Annualy					
                            </Typography>

                    </Grid>
                    </Grid>


                <Grid item xs={12} md={4}>
                    <Paper>
                        <Grid container direction="column" justify="space-between" alignContent="center" style={{height:"350px"}}>

                            <Grid item>
                                <Typography variant="h2" component="h2" className={classes.benefitsSubtitle}>
                                        Pro						
                                </Typography>

                                <Typography variant="body1"  className={classes.priceAmount}>
                                    {yearPrice ? `$${pricePerYear}/mo` : `$${pricePerMo}/mo`}
                                </Typography>

                                <Typography variant="body1"  className={classes.priceText}>
                                Unlimited content generation	
                                </Typography>

                                <Typography variant="body1"  className={classes.priceText}>
                                Access to all tools
                                </Typography>

                                <Typography variant="body1"  className={classes.priceText}>
                                Same-day email support	
                                </Typography>

                            

                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    id="upgradePro"
                                    // onClick={startButtonClicked}
                                    style={{
                                        margin: "1.5rem",
                                        // width: "17.5rem",
                                        height: "3rem",
                                        fontFamily: "Roboto, sans-serif",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontSize: "1.25rem",
                                        lineHeight: "1.25rem",
                                        backgroundColor: "#0062FF"
                                    }}
                                
                                    onClick={handleUpgradeButton}
                                
                                >
                                Upgrade Now
                                </Button>
                            </Grid>


                        </Grid>
                    
                    
                            
                    </Paper>
                </Grid>




                </Grid>
                {/* </Box> */}
                </Container>
               
                
                </DialogContent>
            </Dialog>
        )


    }

    function LanguageModal(){

        const [fromLanguage, setFromLanguage]   = useState(user?.language?.from ?? "en")
        const [toLanguage, setToLanguage]       = useState(user?.language?.to ?? "en")

        const handleClose = () => setOpenLanguageModal(false);

        const style = {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: "700px",
            // width: 700,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };

        async function handleSaveButton(event: React.MouseEvent<HTMLElement>){

            if(!user){
                setOpenUpgradeModal(false)
                return
            } 
            
            setOpenBackdrop(true)
            setOpenLanguageModal(false)

            const languageFunc = firebase
			.functions()
			.httpsCallable("editLanguage");
            const d = {
                language: {
                    from : fromLanguage,
                    to : toLanguage
                }            
            };

            try {
                const result = await languageFunc(d)

                if(result.data.done === true){

                    setUser({...user,  language: {
                        from : fromLanguage,
                        to : toLanguage
                    }   })
                }

                if(result.data.done === false && result.data.error){

                    setAlertBarObject({open:true, type:"error", alertText: "Can't update the language"})
                    console.error("Error saving language",result.data.error )
                }

                

            } catch (error) {
                setAlertBarObject({open:true, type:"error", alertText: "Can't update the language"})
                console.error("Error saving language", error )
                
            }

            
            setOpenBackdrop(false)
        }

        function FlagImg({country}:{country:string}){
            switch (country) {
                case "en": return(<img style={{width:"25px"}} src={flag_us}  alt="English"/>)
                case "tr": return(<img style={{width:"25px"}} src={flag_tr}  alt="Turkish"/>)
                case "es": return(<img style={{width:"25px"}} src={flag_es}  alt="Spanish"/>)
                case "fr": return(<img style={{width:"25px"}} src={flag_fr}  alt="French"/>)
                case "it": return(<img style={{width:"25px"}} src={flag_it}  alt="Italian"/>)
                case "de": return(<img style={{width:"25px"}} src={flag_de}  alt="German"/>)
                    
                    
            
                default:return(<img style={{width:"25px"}} src={flag_us}  alt="English"/>)
                    
            }

        }

        function handleLanguageChange(event :  React.ChangeEvent<{ name?: string | undefined; value: unknown; }>){

            const name = event.target.name
            const value = event.target.value as string

            if(name === "input-language"){
                setFromLanguage(value)
                console.log("from language", value)

            }

            if(name === "output-language"){
                setToLanguage(value)
                console.log("to language", value)
                
            }
        }


        return(

            <Modal
                open={openLanguageModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                
                <Container style={{textAlign:"center"}}>
                <Box sx={style}>

                    <Grid container item style={{textAlign:"left"}}>
                        <Button id="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClose}>
                        {/* Open Menu */}
                        <CloseIcon style={{color:"#555A64"}} />
                    </Button>
                    </Grid>

                    <Grid container justify="center" spacing={3}>

                <Grid item xs={12} justify="center" >
                    <Typography className={classes.reqViewTitle} >
                                
                               Edit Language Settings
                           
                       </Typography>
                </Grid>

                <Grid container item xs={12} spacing={4} justifyContent="center" alignItems="center">

                    <Grid item xs={12} sm={6} >

                    <FormControl fullWidth>
                    <InputLabel id="input-language">Input Language</InputLabel>
                    <Select
                    labelId="input-language"
                    id="input-language"
                    name="input-language"
                    value={fromLanguage}
                    label="Input Language"
                    onChange={handleLanguageChange}
                    >
                     <MenuItem value={"en"}>
                        <FlagImg country="en"/>
                    &nbsp; English
                    </MenuItem>
                    <MenuItem value={"fr"}> <img style={{width:"25px"}} src={flag_fr}  alt="French"/>
                    &nbsp; French</MenuItem>
                    <MenuItem value={"de"}> <img style={{width:"25px"}} src={flag_de}  alt="German"/>
            &nbsp; German</MenuItem>
                    <MenuItem value={"it"}> <img style={{width:"25px"}} src={flag_it}  alt="Italian"/>
                    &nbsp; Italian</MenuItem>
                    <MenuItem value={"es"}> <img style={{width:"25px"}} src={flag_es}  alt="Spanish"/>
                    &nbsp; Spanish</MenuItem>
                    <MenuItem value={"tr"}> <img style={{width:"25px"}} src={flag_tr}  alt="Turkish"/>
                    &nbsp; Turkish</MenuItem>
                    </Select>
                </FormControl>
                     

                    </Grid>

                    <Grid item xs={12} sm={6} >

                    <FormControl fullWidth>
                    <InputLabel id="output-language">Output Language</InputLabel>
                    <Select
                    labelId="output-language"
                    id="output-language"
                    name="output-language"
                    value={toLanguage}
                    label="Output Language"
                    onChange={handleLanguageChange}
                    >
                        <MenuItem value={"en"}>
                        <img style={{width:"25px"}} src={flag_us}  alt="English"/>
                    &nbsp; English
                    </MenuItem>
                    <MenuItem value={"fr"}> <img style={{width:"25px"}} src={flag_fr}  alt="French"/>
                    &nbsp; French</MenuItem>
                    <MenuItem value={"de"}> <img style={{width:"25px"}} src={flag_de}  alt="German"/>
            &nbsp; German</MenuItem>
                    <MenuItem value={"it"}> <img style={{width:"25px"}} src={flag_it}  alt="Italian"/>
                    &nbsp; Italian</MenuItem>
                    <MenuItem value={"es"}> <img style={{width:"25px"}} src={flag_es}  alt="Spanish"/>
                    &nbsp; Spanish</MenuItem>
                    <MenuItem value={"tr"}> <img style={{width:"25px"}} src={flag_tr}  alt="Turkish"/>
                    &nbsp; Turkish</MenuItem>
                    </Select>
                </FormControl>
                     

                    </Grid>

                    <Grid item>

                    <Button onClick={handleSaveButton} color="primary" className={classes.reqViewButton}>
                            Save
                    </Button>

                    </Grid>


                   

                </Grid>

                </Grid>
                </Box>
                </Container>
               
                
                
            </Modal>
        )


    }

    return(
        <Container>
            <SimpleBackdrop openBackDrop={openBackdrop} />
            <Menubar currentUser={fbUser} trialDays={trialDays} activeAccount={activeAccount} goTo={goTo} location="home" language={user?.language?.from ?? "en"}/>
            <UpgradeModal />
            <UserNameModal/>
            <LanguageModal/>
            {alertBarObject && <AlertBar alertBarDone={alertBarDone} alertBarObject={alertBarObject}/>}

            <Grid container justify="space-between">

            
                {/* <Grid style={{marginTop:"20px"}} xs={12} sm={4}>
                    {plReqObj && <RequestViewNew/>}
                </Grid> */}

                <ResultsView resultsList={resultsList}/>
               
            </Grid>
        </Container>
    )



}